import * as R from 'ramda';

import { EMPTY_OBJ } from './constants';

export const getCustomPhotosBySize = (size) => R.pipe(
    R.propOr(EMPTY_OBJ, size),
    R.values
);

export const getPhotosBySize = (photos, size) => R.call(
    R.pipe(
        R.propOr(EMPTY_OBJ, size),
        R.values
    ),
    photos
);
