// Core
import React, { memo, useEffect } from 'react';
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { object, string, boolean } from 'yup';
import T from 'prop-types';

import { bookingActions } from 'bus/booking';

import OrderForm from 'components/hotel/OrderForm';

const formikConfig = {
    mapPropsToValues: () => ({
        phone:   '',
        email:   '',
        name:    '',
        comment: '',
        captcha: false,
    }),
    handleSubmit: (values, { props, ...formik }) => {
        const { order, offerID } = props;
        const { phone, email, name, comment } = values;
        const { setStatus } = formik;

        setStatus(null);

        const client = { name, email, phone, wish: comment };

        order(client, offerID, formik);
    },
    validationSchema: ({ t }) => {
        const requiredError = t('REQUIRED_ERROR');

        return object({
            phone:   string().required(requiredError).length(17, requiredError),
            name:    string().required(requiredError),
            captcha: boolean().oneOf([true]),
        });
    },
};

const OrderFormContainer = ({ onCloseOrderModal, ...props }) => {
    const { status, isSubmitting, handleSubmit, setStatus } = props;

    useEffect(() => () => setStatus(null), []);

    return (
        <OrderForm
            isSubmitting = { isSubmitting }
            status = { status }
            onCloseOrderModal = { onCloseOrderModal }
            onSubmit = { handleSubmit }
        />
    );
};

OrderFormContainer.propTypes = {
    offerID: T.string.isRequired,
};

const enhancer = R.compose(
    withTranslation('CONSULT_ORDER_FORM'),
    connect(null, { order: bookingActions.order }),
    withFormik(formikConfig),
    memo
);

export default enhancer(OrderFormContainer);
