import React, { memo } from 'react';
import T from 'prop-types';

import styles from './styles.scss';
import Copy from '../Copy';

const completePreventClick = (e) => (
    e.stopPropagation(),
    e.preventDefault()
);

const Name = ({ name, stars, showStars, children }) => (
    <span className = { styles.nameRow }>
        <span className = { styles.name }>
            <span className = { styles.nameInner } title = { name }>
                { (stars === 5 || showStars) && `${stars}*` }
                { name }&nbsp;
            </span>
            <Copy text = { name } onClick = { completePreventClick } />
            { children }
        </span>
    </span>
);

Name.propTypes = {
    name:      T.string.isRequired,
    showStars: T.bool,
    stars:     T.number,
};

export default memo(Name);
