import React, { useMemo } from 'react';
import moment from 'moment';
import { getFlightPort } from '@otpusk/apisearch-toolbox/dist/geo/selectors';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import T from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { createFlightsDates } from '../helpers';
import styles from './styles.scss';

import Item from './Item';

const printFlightCity = (city) => city ? R.concat(R.prop('name', city), ` <i>(${R.prop('iata', city)})</i>`) : '';

const FlightOffer = ({ offer, resultVersion }) => {
    const { flights: { inbound: [inbound], outbound: [outbound] }, transport } = offer;

    const { t } = useTranslation('PRICES_LIST');

    const getOutboundCity = useMemo(
        () => R.partialRight(
            getFlightPort(), [{ iata: R.prop('portFr', outbound) }]
        ),
        [outbound]
    );
    const getInboundCity = useMemo(
        () => R.partialRight(
            getFlightPort(), [{ iata: R.prop('portFr', inbound) }]
        ),
        [inbound]
    );

    const outBoundCity = useSelector(getOutboundCity);
    const inBoundCity = useSelector(getInboundCity);

    const [dateOutbound, dateInbound] = useMemo(() => createFlightsDates(offer), [offer]);
    const [dateOutboundMobile, dateInboundMobile] = useMemo(() => createFlightsDates(offer, true), [offer]);
    const [dateOfferFrom, dateOfferTo] = useMemo(() => {
        const format = 'DD MMMM';
        const dateFrom = moment(offer.date, 'YYYY-MM-DD').startOf('day').startOf('hour').startOf('minute');
        const dateTo = dateFrom.clone().add(offer.nights, 'days');

        return [
            dateFrom,
            dateTo
        ].map((d) => d.format(format));
    }, [offer.date, offer.days]);

    return (
        <>
            <Item
                city = { printFlightCity(outBoundCity) }
                className = { classnames(styles.out, styles[transport], resultVersion ? styles.resultVersion : styles.tourVersion) }
                date = { dateOutbound || dateOfferFrom }
                dateMobile = { dateOutboundMobile || dateOfferFrom }
                resultVersion = { resultVersion }
                title = {
                    resultVersion
                        ? t('FLIGHT_OUTBOUND_LABEL')
                        : transport === 'bus' ? t('FLIGHT_OUTBOUND_BUS_LABEL') : t('FLIGHT_OUTBOUND_AIR_LABEL')
                }
            />
            <Item
                city = { printFlightCity(inBoundCity) }
                className = { classnames(styles.in, styles[transport], resultVersion ? styles.resultVersion : styles.tourVersion) }
                date = { dateInbound || dateOfferTo }
                dateMobile = { dateInboundMobile || dateOfferTo }
                resultVersion = { resultVersion }
                title = {
                    resultVersion
                        ? t('FLIGHT_INBOUND_LABEL')
                        : transport === 'bus' ? t('FLIGHT_INBOUND_BUS_LABEL') : t('FLIGHT_INBOUND_AIR_LABEL')
                }
            />
        </>
    );
};

const FlightHotOffer = ({ inbound, outbound, transport, resultVersion }) => {
    const [dateOfferFrom, dateOfferTo] = useMemo(
        () => R.map(
            (date) => moment(date, 'YYYY-MM-DD').format('DD MMMM'),
            [outbound.date, inbound.date]
        ),
        [inbound, outbound]
    );

    const { t } = useTranslation('PRICES_LIST');

    return (
        <>
            <Item
                city = { outbound.city }
                className = { classnames(styles.out, styles[transport], resultVersion ? styles.resultVersion : undefined) }
                date = { dateOfferFrom }
                dateMobile = { dateOfferFrom }
                title = {
                    resultVersion
                        ? t('FLIGHT_OUTBOUND_LABEL')
                        : transport === 'bus' ? t('FLIGHT_OUTBOUND_BUS_LABEL') : t('FLIGHT_OUTBOUND_AIR_LABEL')
                }
            />
            <Item
                city = { inbound.city }
                className = { classnames(styles.in, styles[transport], resultVersion ? styles.resultVersion : undefined) }
                date = { dateOfferTo }
                dateMobile = { dateOfferTo }
                title = {
                    resultVersion
                        ? t('FLIGHT_INBOUND_LABEL')
                        : transport === 'bus' ? t('FLIGHT_INBOUND_BUS_LABEL') : t('FLIGHT_INBOUND_AIR_LABEL')
                }
            />
        </>
    );
};

FlightHotOffer.propTypes = {
    inbound: T.shape({
        date: T.string.isRequired,
        city: T.string.isRequired,
    }).isRequired,
    outbound: T.shape({
        date: T.string.isRequired,
        city: T.string.isRequired,
    }).isRequired,
    resultVersion: T.bool,
    transport:     T.string,
};

export { FlightOffer, FlightHotOffer };
