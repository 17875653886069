import { put } from 'redux-saga/effects';

import { MOBILE_BREAK_POINT, SMALL_TABLET_BREAK_POINT } from 'containers/media/constants';

import { setDeviceType } from '../actions';
import { DESKTOP, MOBILE, TABLET } from '../constants';


export default function* () {
    const type = matchMedia(MOBILE_BREAK_POINT).matches && MOBILE || matchMedia(SMALL_TABLET_BREAK_POINT).matches && TABLET || DESKTOP;

    yield put(setDeviceType(type));
}
