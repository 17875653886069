// Core
import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import { pageLoaded, completedInitializeApp } from './actions';

const initialStore = {
    isInitializedApp: false,
    pageLoaded:       false,
};

export default handleActions({
    [pageLoaded]: (state) => R.set(
        R.lensProp('pageLoaded'),
        true,
        state
    ),
    [completedInitializeApp]: (state) => R.set(
        R.lensProp('isInitializedApp'),
        true,
        state
    ),
}, initialStore);
