import { call } from 'redux-saga/effects';
import i18n from 'i18next';

export function* orderWorker ({ payload }) {
    const { client, offerID, formik } = payload;

    try {
        yield call(fetch, '/wp-json/rest_api/v1/tour_form', {
            method:  'POST',
            headers: { 'Content-Type': 'application/json' },
            body:    JSON.stringify({
                type:  'consultation',
                offer: {
                    id:  offerID,
                    url: window.location.href,
                },
                client,
            }),
        });

        formik.setStatus({ success: true, message: i18n.t('CONSULT_ORDER_FORM:SUCCESS_MESSAGE') });
    } catch (error) {
        console.error(error.message);
        formik.setStatus({ error: true, message: i18n.t('CONSULT_ORDER_FORM:FAIL_MESSAGE') });
    } finally {
        formik.setSubmitting(false);
    }
}
