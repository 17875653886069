import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import { searchByKey } from '@otpusk/apisearch-toolbox/dist/search/selectors';

import { getActiveCurrency } from 'bus/currency/selectors';
import { DEFAULT_CURRENCY } from 'bus/currency/constants';
import { currencyActions } from 'bus/currency/actions';
import { MAIN_SEARCH } from 'helpers/queryID';

const getSearch = R.partialRight(searchByKey(), [{ queryID: MAIN_SEARCH }]);

const withData = (Component) => {
    const WithCurrencyDataComponent = (props) => {
        const activeCurrency = useSelector(getActiveCurrency);
        const search = useSelector(getSearch);
        const dispatch = useDispatch();

        const currencyBySearchCountry = useMemo(
            () => R.path(['country', 'currency'], search),
            [search]
        );
        const isShow = useMemo(
            () => !R.prop('total', search),
            [search]
        );

        const handleChange = useCallback(
            (checked) => R.call(
                R.compose(dispatch, currencyActions.setActiveCurrency),
                checked ? DEFAULT_CURRENCY : currencyBySearchCountry
            ),
            [dispatch, currencyBySearchCountry]
        );

        return isShow && (
            <Component
                { ...props }
                checked = { activeCurrency === DEFAULT_CURRENCY }
                onChange = { handleChange }
            />
        );
    };

    return WithCurrencyDataComponent;
};

export default withData;
