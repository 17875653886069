export const THUMB_STEP = 9;
export const THUMB_STEP_MODAL = matchMedia('(max-width: 1120px)').matches ? 8 : 16;

export const GEO_MAP_ELEMENT_INDEX_POS = THUMB_STEP_MODAL - 1;
export const GEO_MAP_ELEMENT_INDEX_POS_WITH_CUSTOM_PHOTOS = (matchMedia('(max-width: 1120px)').matches ? 7 : 15) - 1;
export const CUSTOM_PHOTOS_INDEX_POS = (matchMedia('(max-width: 1120px)').matches ? 5 : 13) - 1;
export const CUSTOM_VIDEO_ELEMENT_INDEX_POS = (matchMedia('(max-width: 1120px)').matches ? 7 : 15) - 1;
export const CUSTOM_TOURIST_VIDEO_ELEMENT_INDEX_POS = 31;

export const ANIMATION = {
    'SLIDE': 'slide',
    'FADE':  'fade',
};

export const MODAL_SCROLL_2_REVIEWS_QUERY_PARAM = 'modal-gallery-scroll-to-reviews';
export const MODAL_REVIEWS_BLOCK_POINTER = 'MODAL_REVIEWS_BLOCK_POINTER';
export const MODAL_REVIEWS_SCROLL_CONTAINER_ID = 'MODAL_REVIEWS_SCROLL_CONTAINER_ID';

export const GEO_MAP_ELEMENT = 'GEO_MAP_ELEMENT';
export const CUSTOM_HOTEL_VIDEO_ELEMENT = 'CUSTOM_HOTEL_VIDEO_ELEMENT';
export const CUSTOM_TOURIST_HOTEL_VIDEO_ELEMENT = 'CUSTOM_TOURIST_HOTEL_VIDEO_ELEMENT';

export const SMALL_PHOTO = 'small';
export const MEDIUM_PHOTO = 'medium';
export const LARGE_PHOTO = 'large';
export const PHOTO_SIZE_MAP = {
    [SMALL_PHOTO]:  '400x300',
    [MEDIUM_PHOTO]: '800x600',
    [LARGE_PHOTO]:  '1200x900',
};
