import { createSelector } from 'reselect';
import * as R from 'ramda';
import { Map } from 'immutable';

const domain = (_) => _.ui;

const defaultUI = {};

const defaultUISector = Map();

export const getWeatherUI = () => createSelector(
    domain,
    (_, key) => key,
    (ui, key) => ui.getIn(['weather.fetch', key], defaultUI)
);

const getResultsVisible = createSelector(
    domain, (ui) => ui.get('resultsVisible')
);

const getActiveSearchPages = createSelector(
    domain, (ui) => ui.get('activeSearchPage')
);

export const getResultVisible = createSelector(
    getResultsVisible,
    (_, { key }) => key,
    (map, key) => Boolean(map[key])
);

export const getActiveSearchPage = createSelector(
    getActiveSearchPages,
    (_, { key }) => key,
    (map, key) => R.propOr(1, key, map)
);

export const getVisibleOfferPricesTable = createSelector(
    domain,
    (ui) => ui.get('visibleOfferPricesTable')
);

export const getUI = createSelector(
    domain,
    (_, { key }) => key,
    (ui, key) => ui.get(key, defaultUISector)
);
