import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getLoaderByLocale } from './helpers';

export const bootstrap = (locale) => {
    const loader = getLoaderByLocale(locale);

    return loader()
        .then(({ default: json }) => i18n
            .use(initReactI18next)
            .init({
                resources: {
                    [locale]: json,
                },
                debug:        __DEV__,
                keySeparator: false,
                lng:          locale,
                react:        {
                    nsMode: 'fallback',
                },
                interpolation: {
                    escapeValue: false,
                },
            })
        )
        .catch(() => {
            throw new Error(`can't download ${locale}.json`);
        })
    ;
};
