import React, { memo, useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classnames from 'classnames';

import Rating from './Rating';

import styles from '../styles/review.scss';

const ReviewItem = ({ author, datePublished, rate, title, description, dateStay }) => {
    const [showFullDesc, setShowFullDesc] = useState(false);

    const { t } = useTranslation('TRIPADVISOR_REVIEWS');

    const toggleShowFullDesc = () => setShowFullDesc((state) => !state);

    return (
        <div className = { styles.review } >
            <div className = { styles.reviewHeader }>
                <div className = { styles.userAvatarWrapper }>
                    <div
                        className = { styles.userAvatar }
                        style = { { backgroundImage: `url("${author.avatar}")` } }
                    />
                </div>
                <div className = { styles.userInfo }>
                    <div className = { styles.userName }>
                        { author.name }
                    </div>
                    <div className = { styles.userTime }>
                        { moment(datePublished).format('DD MMMM YYYY') }
                    </div>
                    { author.geo && (
                        <div className = { styles.userGeo }>
                            { author.geo }
                        </div>
                    ) }
                </div>
            </div>
            <Rating value = { rate.value } />
            <div className = { styles.reviewTitle }>
                { title }
            </div>
            <div className = { styles.reviewText }>
                &quot;{showFullDesc ? description.long : description.short }&quot;
            </div>
            { description.long && (
                <div
                    className = { classnames(styles.reviewMore, showFullDesc && styles.expanded) }
                    onClick = { toggleShowFullDesc }>
                    {showFullDesc ? t('LESS_BTN') : t('MORE_BTN')}
                </div>
            ) }
        </div>
    );
};

ReviewItem.propTypes = {
    author: T.shape({
        name:   T.string.isRequired,
        avatar: T.string.isRequired,
        geo:    T.string,
    }).isRequired,
    datePublished: T.string.isRequired,
    dateStay:      T.string.isRequired,
    description:   T.shape({
        short: T.string.isRequired,
        long:  T.string,
    }).isRequired,
    rate: T.shape({
        value: T.number.isRequired,
    }).isRequired,
    title: T.string.isRequired,
};

export default memo(ReviewItem);
