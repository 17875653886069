import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import { setCustomHotel } from './actions';

const initialState = {
    customHotels: {},
};

const hotelCarouselReducer = handleActions({
    [setCustomHotel]: (state, { payload: { hotelID, hotel }}) => R.set(
        R.lensPath(['customHotels', hotelID]),
        hotel,
        state
    ),
}, initialState);

export default hotelCarouselReducer;
