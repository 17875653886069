import { createSelector } from 'reselect';
import { getHotel } from '@otpusk/apisearch-toolbox/dist/hotels/selectors';
import * as R from 'ramda';

import { getUI } from 'bus/ui/selectors';
import { isMobile as isMobileSelector } from 'components/DeviceDetector/store/selectors';

import {
    injectGeoMapToPhotos,
    injectGeoMapToCustomPhotos,
    injectCustomVideoToPhotos,
    injectCustomTouristVideoToPhotos
} from '../helpers';

import { getCustomPhotosBySize, getPhotosBySize } from './helpers';
import { UI_FLAG_CAROUSEL_MODAL, EMPTY_OBJ, UI_FLAG_CAROUSEL } from './constants';

const DEFAULT_UI = {};
const EMPTY_ARRAY = [];

const domain = (_) => _.hotelCarousel;
const getHotelID = (_, { hotelID }) => hotelID;
const getPhotoSize = (_, { photoSize }) => photoSize;

export const getUIModal = createSelector(
    (state) => getUI(state, { key: UI_FLAG_CAROUSEL_MODAL }),
    getHotelID,
    (ui, hotelID) => ui.get(hotelID, DEFAULT_UI)
);

export const getUICarousel = createSelector(
    (state) => getUI(state, { key: UI_FLAG_CAROUSEL }),
    getHotelID,
    (ui, hotelID) => ui.get(hotelID, DEFAULT_UI)
);

export const isFetchedCarouselData = createSelector(
    getUICarousel,
    R.prop('completed')
);

const getCustomHotel = createSelector(
    domain,
    getHotelID,
    ({ customHotels }, hotelID) => R.prop(hotelID, customHotels)
);

export const isSetCustomHotel = createSelector(
    getCustomHotel,
    Boolean
);

export const getCustomHotelYouTubeVideo = createSelector(
    getCustomHotel,
    R.prop('youtube')
);

export const getCustomHotelTouristYouTubeVideo = createSelector(
    getCustomHotel,
    R.prop('youtube_from_tourists')
);

const getCustomHotelGallery = createSelector(
    getCustomHotel,
    R.propOr(EMPTY_OBJ, 'gallery')
);

export const isSetCustomHotelGallery = createSelector(
    getCustomHotelGallery,
    (gallery) => !R.isEmpty(gallery)
);

export const getCustomHotelGalleryBySize = createSelector(
    getCustomHotelGallery,
    getPhotoSize,
    getPhotosBySize
);

export const getCustomHotelGallerySmall = createSelector(
    getCustomHotelGallery,
    getCustomPhotosBySize('small')
);

const getCustomHotelGalleryMedium = createSelector(
    getCustomHotelGallery,
    getCustomPhotosBySize('medium')
);

const getCustomHotelGalleryOriginal = createSelector(
    getCustomHotelGallery,
    getCustomPhotosBySize('original')
);

export const getCustomGalleryPhotos = createSelector(
    isMobileSelector,
    getCustomHotelGalleryOriginal,
    getCustomHotelGalleryMedium,
    (isMobile, original, medium) => isMobile ? medium : original
);

const getCustomHotelReplacement = createSelector(
    getCustomHotel,
    R.prop('replacement')
);

export const isSetCustomReplacementPhotos = createSelector(
    getCustomHotelReplacement,
    Boolean
);

export const getCustomReplacementPhotosBySize = createSelector(
    getCustomHotelReplacement,
    getPhotoSize,
    getPhotosBySize
);

export const getCustomReplacementPhotosSmall = createSelector(
    getCustomHotelReplacement,
    getCustomPhotosBySize('small')
);

const getCustomReplacementPhotosMedium = createSelector(
    getCustomHotelReplacement,
    getCustomPhotosBySize('medium')
);

const getCustomReplacementPhotosOriginal = createSelector(
    getCustomHotelReplacement,
    getCustomPhotosBySize('original')
);

export const getCustomReplacementPhotos = createSelector(
    isMobileSelector,
    getCustomReplacementPhotosOriginal,
    getCustomReplacementPhotosMedium,
    (isMobile, original, medium) => isMobile ? medium : original
);

export const hasHotelLocation = createSelector(
    getHotel(),
    R.pipe(
        R.prop('location'),
        Boolean
    )
);

export const getSlidesCount = createSelector(
    getCustomHotelGalleryOriginal,
    getCustomReplacementPhotosOriginal,
    getCustomHotelYouTubeVideo,
    getCustomHotelTouristYouTubeVideo,
    getHotel(),
    hasHotelLocation,
    (customGallery, replacementPhotos, customYouTube, touristYouTube, hotel, hasLocation) => R.call(
        R.pipe(
            R.concat,
            hasLocation
                ? replacementPhotos.length
                    ? injectGeoMapToCustomPhotos
                    : injectGeoMapToPhotos
                : R.identity,
            customYouTube ? injectCustomVideoToPhotos : R.identity,
            touristYouTube ? injectCustomTouristVideoToPhotos : R.identity,
            R.length
        ),
        replacementPhotos.length ? replacementPhotos : R.propOr(EMPTY_ARRAY, 'photos', hotel),
        customGallery
    )
);
