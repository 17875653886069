import { useState } from 'react';
import T from 'prop-types';

const LocalState = ({ initialState, children }) => {
    const [state, setState] = useState(initialState);

    return children(state, setState);
};

LocalState.propTypes = {
    initialState: T.any,
};

export default LocalState;
