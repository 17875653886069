import React, { memo, useState } from 'react';
import T from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { useRouteMatch } from 'react-router-dom';

import book from 'routes/book';

import MapModal from 'components/hotel/MapModal';
import 'components/controls/GeoControl/styles.scss'; /* injecting flags styles */

import styles from './styles.scss';
import { RENAME_CITIES_MAP } from './constants';

const preventClick = (e) => e.preventDefault();

const Geo = ({ country, countryCode, city, hotelID, duration, hotelName, hotelLocation, resultVersion }) => {
    const [showMap, setShowMap] = useState(false);

    const { t } = useTranslation(['TIME', 'PRICES_LIST', 'RESULT_PRICE_RENAME_CITY']);
    const isExoticsPage = useRouteMatch({
        path:  book.marketingPages.exotics,
        exact: true,
    });

    const cityName = R.has(city.id, RENAME_CITIES_MAP)
        ? t(R.prop(city.id, RENAME_CITIES_MAP))
        : city.name;

    return (
        <>
            <span
                className = { classnames(
                    styles.root,
                    {
                        [styles.resultVersion]: resultVersion,
                        [styles.hasFlag]:       countryCode && isExoticsPage,
                    }
                ) }
                onClick = { (e) => (setShowMap((next) => !next), preventClick(e)) }>
                { countryCode && isExoticsPage && <div className = { styles.exoticFlag } data-flag = { countryCode } /> }
                <span className = { styles.title }>
                    <u>{ country }, { cityName }</u>,
                </span>
                <span className = { styles.mobile }>
                    <u>{ country }</u>,
                    {' '}
                    { duration } { t('NIGHT_SHORT') } { cityName }
                </span>
                <span className = { styles.desktop }>
                    { t('DURATION_INFORMATION', { duration }) }
                </span>
            </span>
            { showMap && hotelLocation && (
                <MapModal
                    hotelID = { hotelID }
                    title = { t('MAP_MODAL_TITLE', { hotelName }) }
                    onClose = { () => setShowMap(false) }
                />
            ) }
        </>
    );
};

Geo.propTypes = {
    city: T.shape({
        name: T.string.isRequired,
        id:   T.any.isRequired,
    }).isRequired,
    country:       T.string.isRequired,
    duration:      T.number.isRequired,
    hotelID:       T.string.isRequired,
    countryCode:   T.string,
    hotelLocation: T.shape({
        lng: T.number,
        lat: T.number,
    }),
    hotelName:     T.string,
    resultVersion: T.bool,
};

export default memo(Geo);
