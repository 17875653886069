import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { GoogleMap, InfoBox, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';

import { getGoogleMapToken, getLocale } from 'bus/auth/selectors';

import styles from './styles.scss';

const STATIC_OPTIONS_FOR_INFO_BOX = {
    closeBoxURL: 'https://www.google.com/intl/en_us/mapfiles/close.gif',
};

const MapComponent = ({ hotelName, zoom, position }) => {
    const { t } = useTranslation('GEO_MAP');
    const googleToken = useSelector(getGoogleMapToken);
    const currentLocale = useSelector(getLocale);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: googleToken,
        language:         currentLocale,
    });

    return (
        <>
            { loadError && <div className = { styles.loadError }>{ t('LOAD_ERROR') }</div> }
            {
                !loadError && (
                    isLoaded
                        ? (
                            <GoogleMap center = { position } mapContainerStyle = { { height: '100%' } } mapTypeId = 'hybrid' zoom = { zoom || 18 }>
                                <Marker position = { position }>
                                    <InfoBox options = { STATIC_OPTIONS_FOR_INFO_BOX } position = { position }>
                                        <div className = { styles.infoBox }>
                                            <div className = { styles.infoBoxSign }>
                                                { hotelName }
                                            </div>
                                        </div>
                                    </InfoBox>
                                </Marker>
                            </GoogleMap>
                        )
                        : <div className = { styles.loading }>{`${t('LOADING')}...`}</div>
                )
            }
        </>
    );
};

MapComponent.propTypes = {
    hotelName: T.string.isRequired,
    position:  T.shape({
        lat: T.number.isRequired,
        lng: T.number.isRequired,
    }).isRequired,
    zoom: T.number,
};

export default memo(MapComponent);
