import React, { memo } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import styles from './styles.scss';

const Room = ({ name, resultVersion }) => {
    const { t } = useTranslation('PRICES_LIST');

    return (
        <span className = { cx(styles.roomWrapper, resultVersion && styles.resultVersion) }>
            <span className = { styles.room }>
                <span className = { styles.desktop }>
                    <span className = { styles.title }>
                        { t('ROOM_LABEL') }
                    </span>
                </span>
                { name }
            </span>
        </span>
    );
};

Room.propTypes = {
    name:          T.string.isRequired,
    resultVersion: T.bool,
};

export default memo(Room);
