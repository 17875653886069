// Core
import { memo, useMemo } from 'react';
import t from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { List, Map, Set, getIn } from 'immutable';
import moment from 'moment';
import { generatePath, useLocation } from 'react-router-dom';
import { QUERY_PARAMS, compileQuery } from '@otpusk/apisearch-toolbox/dist/queries/fn';
import * as R from 'ramda';

import history from 'init/history';
import book from 'routes/book';

import { createQQPayload } from 'components/results/Hotel/helpers';
import useDiscountState from 'components/controls/DiscountControl/useDiscountState';
import { getDiscountPrice, getPrice, getFallbackPrice, getDiscountPriceForJoinUp, normalizePrice } from 'providers/Price/helpers';

import { extractOffersByHotelID, getPriceFromOffer } from './helpers';

const HotelToursContainer = ({ children, hotelID, queryID }) => {
    const query = useSelector(({ queries }) => queries.get(queryID), shallowEqual);
    const hotel = useSelector(({ hotels }) => hotels.getIn(['store', String(hotelID)], {}), shallowEqual);
    const hotelsResultsByPages = useSelector(({ search }) => search.getIn(['results', queryID, 'hotels']));
    const countryID = useSelector(({ search }) => search.getIn(['results', queryID, 'country', 'id']), shallowEqual);
    const activeCurrency = useSelector(({ currency }) => currency.get('activeCurrency'));
    const offersHub = useSelector(({ offers }) => offers.get('store', Map()), shallowEqual);
    const operators = useSelector(({ geo }) => geo.getIn(['operators', countryID], geo.getIn(['operators', Number(countryID)], List())), shallowEqual);
    const departureCities = useSelector(({ geo }) => geo.getIn(['departures', '0'], null), shallowEqual);
    const isAllowDiscount = useDiscountState();
    const { search } = useLocation();

    // computed
    const departureCity = useMemo(() => {
        if (departureCities) {
            return departureCities.find(({ id }) => Number(id) === Number(query.get(QUERY_PARAMS.DEPARTURE)));
        }

        return null;
    }, [departureCities, query]);
    const offersIDs = useMemo(
        () => extractOffersByHotelID(hotelsResultsByPages.toList().toArray(), hotelID),
        [hotelsResultsByPages, hotelID]
    );
    const hashParams = useMemo(() => {
        const queryByHotel = query.merge({
            [QUERY_PARAMS.CITIES]: Set([getIn(hotel, ['city', 'id'])]),
            [QUERY_PARAMS.HOTELS]: Set([getIn(hotel, ['id'])]),
        });

        return `#${compileQuery(queryByHotel)}`;
    }, [query, hotel]);
    const operatorsMap = useMemo(() => {
        return operators
            .toMap()
            .mapKeys((_, { id }) => id)
            .toObject();
    }, [operators]);
    const offers = useMemo(() => {
        const operatorsNamesMap = {
            '717': 'Anex',
        };

        const offerSortableFunc = R.compose(R.prop('uah'), getPriceFromOffer);

        return R.call(
            R.pipe(
                R.take(100),
                R.map((id) => offersHub.get(id)),
                R.sort(R.ascend(offerSortableFunc)),
                R.map((offer) => {
                    const { date, id, operator, food, flights, nights, room, currencyRate, currency } = offer;
                    const allowCurrency = activeCurrency || currency;

                    const discountPrice = R.call(
                        R.ifElse(
                            (price) => isAllowDiscount && price,
                            R.pipe(
                                R.prop(allowCurrency),
                                normalizePrice
                            ),
                            R.F
                        ),
                        getDiscountPrice(offer) || getDiscountPriceForJoinUp(offer)
                    );
                    const price = R.call(
                        R.pipe(
                            R.prop(allowCurrency),
                            normalizePrice
                        ),
                        getPrice(offer) || getFallbackPrice(offer)
                    );
                    const tourLink = history.createHref({
                        pathname: generatePath(book.tour, {
                            countryName: hotel.country.code.toLowerCase(),
                            hotelName:   hotel.code.toLowerCase(),
                            hotelID:     hotel.id,
                            offerID:     id,
                        }),
                        search,
                        hash: hashParams,
                    });
                    const qqPayload = createQQPayload(
                        offer,
                        hotel,
                        allowCurrency,
                        tourLink,
                        departureCity?.name,
                        discountPrice || price
                    );

                    return {
                        date:     moment(date, 'YYYY-MM-DD').format('DD.MM'),
                        id,
                        operator: operatorsNamesMap[operator] || getIn(operatorsMap, [operator, 'name'], null),
                        food:     food.toUpperCase(),
                        flights:  {
                            inbound:  getIn(flights, ['inbound', 0], null),
                            outbound: getIn(flights, ['outbound', 0], null),
                        },
                        nights,
                        room: room.name,
                        discountPrice,
                        price,
                        currencyRate,
                        tourLink,
                        qqPayload,
                    };
                })
            ),
            offersIDs
        );
    }, [isAllowDiscount, departureCity, activeCurrency, offersIDs, offersHub, operatorsMap, hashParams, hotel, search]);

    return children(offers);
};

HotelToursContainer.propTypes = {
    children: t.func.isRequired,
    hotelID:  t.string.isRequired,
    queryID:  t.string.isRequired,
};

export default memo(HotelToursContainer);
