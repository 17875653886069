import { createActions } from 'redux-actions';

export const {
    bootstrapDataForModal,
    bootstrapDataForCarousel,
    setCustomHotel,
} = createActions({
    bootstrapDataForModal:    (hotelID) => hotelID,
    bootstrapDataForCarousel: (hotelID, onSuccess) => ({ hotelID, onSuccess }),

    setCustomHotel: (hotelID, hotel) => ({ hotelID, hotel }),
}, { prefix: 'CAROUSEL_MODAL' });
