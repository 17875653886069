import { handleActions } from 'redux-actions';
import { Map } from 'immutable';

// actions
import { uiActions as actions } from 'bus/ui';

const initialState = Map({
    'contracts.fetch':         {},
    'weather.fetch':           {},
    'visibleOfferPricesTable': null,
    'resultsVisible':          {},
    'activeSearchPage':        {},
});

export const uiReducer = handleActions({
    [actions.setUiState]: (state, { payload }) => {
        const { path, value } = payload;

        if (Array.isArray(path)) {
            return state.setIn(path, value);
        }

        return state.set(path, value);
    },
    [actions.updateUiState]: (state, { payload }) => {
        const { path, overrides } = payload;

        if (Array.isArray(path)) {
            return state.mergeIn(path, overrides);
        }

        return state.mergeIn([path], overrides);
    },
    [actions.removeUiState]: (state, { payload: path }) => state.removeIn(path)
    ,
}, initialState);
