import { handleActions } from 'redux-actions';

import { setUserLocation } from './actions';

const initial = {
    city:    '',
    country: '',
    ip:      '',
};

export default handleActions({
    [setUserLocation]: (state, { payload: locationData }) => ({ ...state, ...locationData }),
}, initial);
