import * as R from 'ramda';
import moment from 'moment';

const date2Moment = (date) => moment(date, 'YYYY-MM-DD');

export const generateDiscountKey = (hotel, offer) => R.join(
    '::',
    [
        hotel.id,
        offer.date,
        offer.nights,
        offer.departure
    ]
);

export const modifyOffer = (offer) => R.over(
    R.lensProp('date'),
    (date) => date2Moment(date).add(1, 'days').format('YYYY-MM-DD'),
    offer
);

export const getGraphDuration = () => R.call(
    R.pipe((days) => days / 2, Math.ceil),
    moment.duration(1, 'years').asDays()
);
