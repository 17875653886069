import React, { memo } from 'react';
import classnames from 'classnames';
import T from 'prop-types';

import styles from './styles.scss';

const Item = ({
    className,
    title,
    date,
    dateMobile,
    city,
}) => (
    <span className = { classnames(styles.dateItem, className) }>
        <span className = { styles.title }>
            { title }
        </span>
        {' '}
        <span>
            <span className = { styles.desktop }>{ date }</span>
            <span className = { styles.mobile }>{ dateMobile }</span>
            {' '}
            <span dangerouslySetInnerHTML = { { __html: city } } />
        </span>
    </span>
);

Item.propTypes = {
    date:       T.string.isRequired,
    dateMobile: T.string.isRequired,
    title:      T.string.isRequired,
    city:       T.string,
    className:  T.string,
};

export default memo(Item);
