import { takeEvery } from 'redux-saga/effects';

// actions
import { bookingActions as actions } from 'bus/booking';

// workers
import { getOperatorContractWorker } from './workers/getOperatorContract';
import { payWorker } from './workers/pay';
import { orderWorker } from './workers/order';

export const bookingWatchers = Object.freeze({
    * getOperatorContractWatcher () {
        yield takeEvery(actions.getOperatorContract, getOperatorContractWorker);
    },
    * payWatcher () {
        yield takeEvery(actions.pay, payWorker);
    },
    * orderWatcher () {
        yield takeEvery(actions.order, orderWorker);
    },
});
