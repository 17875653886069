// Core
import React, { memo, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import OrderFormContainer from 'containers/hotel/OrderForm';

// instruments
import Styles from './styles.scss';

const ConsultButton = () => {
    const { t } = useTranslation('CONSULT_ORDER');

    const [show, setShow] = useState(false);

    return (
        <>
            <div className = { Styles.root }>
                <div className = { Styles.title }>{ t('DESRIPTION') }</div>
                <button className = { Styles.button } type = 'button' onClick = { () => setShow(true) }>
                    { t('BUTTON_TITLE') }
                </button>
            </div>
            { show && (
                <OrderFormContainer offerID = '' onCloseOrderModal = { () => setShow(false) } />
            ) }
        </>
    );
};

const Bootstrap = () => {
    const $node = useMemo(
        () => global.document.getElementById('antoniv-consult-button'),
        []
    );

    return $node && createPortal(<ConsultButton />, $node);
};

export default memo(Bootstrap);
