import * as R from 'ramda';

export const singleCarouselSliderMiddleware = R.cond([
    [
        (next, counts) => next + 1 > counts,
        (_, __, setActive) => setActive(0)
    ],
    [
        (next) => next < 0,
        (_, counts, setActive) => setActive(counts - 1)
    ]
]);
