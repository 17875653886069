import { useCallback, useMemo, useEffect, useRef } from 'react';
import * as R from 'ramda';

const RIGHT_CODES = ['ArrowRight', 'Numpad6'];
const LEFT_CODES = ['ArrowLeft', 'Numpad4'];
const LEFT = 'left';
const RIGHT = 'right';

const protectedCallFunction = (codes, callback) => (event) => (
    codes.includes(event.code) && callback()
);

const getInitialCallbacks = () => ({ [LEFT]: [], [RIGHT]: []});

const createRegistratorCallback = (direction, callbacks) => (callback) => (
    callbacks.current[direction].push(callback)
);

const getCallbacksByDirection = (direction, callbacks) => callbacks.current[direction];

export const useKeyboardArrows = ({ activated, onLeft, onRight }) => {
    const callbacks = useRef(getInitialCallbacks());

    const leftCallbackRegistrator = useMemo(
        () => createRegistratorCallback(LEFT, callbacks),
        []
    );
    const rightCallbackRegistartor = useMemo(
        () => createRegistratorCallback(RIGHT, callbacks),
        []
    );
    const resetCallbacks = useCallback(
        () => callbacks.current = getInitialCallbacks(),
        []
    );

    useEffect(() => {
        if (activated) {
            const handlers = [
                protectedCallFunction(
                    RIGHT_CODES,
                    () => (
                        onRight(),
                        R.forEach(
                            (callback) => callback(),
                            getCallbacksByDirection(RIGHT, callbacks)
                        )
                    )
                ),
                protectedCallFunction(
                    LEFT_CODES,
                    () => (
                        onLeft(),
                        R.forEach(
                            (callback) => callback(),
                            getCallbacksByDirection(LEFT, callbacks)
                        )
                    )
                )
            ];

            R.forEach(
                (handler) => window.addEventListener('keyup', handler),
                handlers
            );

            return () => {
                R.forEach(
                    (handler) => window.removeEventListener('keyup', handler),
                    handlers
                );
            };
        }
    }, [activated, onLeft, onRight]);

    return {
        leftCallbackRegistrator,
        rightCallbackRegistartor,
        resetCallbacks,
    };
};
