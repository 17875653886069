import { call, put } from 'redux-saga/effects';

// actions
import { uiActions } from 'bus/ui';
import { bookingActions } from 'bus/booking';

const displayMachine = 'contracts.fetch';
const processedBodyMethodsByType = {
    'operators': 'json',
};

export function* getOperatorContractWorker ({ payload }) {
    const { id, type } = payload;

    yield put(uiActions.setUiState([displayMachine, type, id], {
        loading:   true,
        completed: false,
        error:     false,
        message:   null,
    }));

    try {
        const response = yield call(fetch, `/wp-json/rest_api/v1/${type}/${id}`);
        const body = yield call([response, processedBodyMethodsByType[type] || 'text']);
        const { content = '', link = null } = typeof body === 'object' ? body || {} : { content: body };

        yield put(bookingActions.getOperatorContractSuccess(type, id, content, link));
    } catch (error) {
        yield put(bookingActions.getOperatorContractFail(error));
        yield put(uiActions.updateUiState([displayMachine, type, id], {
            error:   true,
            message: true,
        }));
    } finally {
        yield put(uiActions.updateUiState([displayMachine, type, id], {
            loading:   false,
            completed: true,
        }));
    }
}
