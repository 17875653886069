import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import { setCollections } from './actions';

const initialState = {};

export default handleActions({
    [setCollections]: (state, { payload }) => {
        const { countryID, collections } = payload;

        return R.set(
            R.lensProp(countryID),
            collections,
            state
        );
    },
}, initialState);
