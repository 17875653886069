// Core
import React, { memo } from 'react';
import cx from 'classnames';

// instruments
import Styles from './styles.scss';

export const Badge = memo(({ service, label, large }) => {

    return (
        <div className = { cx(Styles.root, large && Styles.large) } data-service = { service || 'default' }>
            { label }
        </div>
    );
});
