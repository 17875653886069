import { handleActions } from 'redux-actions';
import { Map } from 'immutable';

// actions
import { weatherActions as actions } from './actions';

const initialState = Map();

export const weatherReducer = handleActions({
    [actions.getWeatherSuccess]: (state, { payload }) => {
        const { key, weather } = payload;

        return state.set(key, weather);
    },
}, initialState);
