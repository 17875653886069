import { createActions } from 'redux-actions';

export default createActions({
    'GET_HOLIDAY':         () => void 0,
    'GET_HOLIDAY_SUCCESS': (holiday) => holiday,
    'GET_HILIDAY_FAIL':    (error) => error,

    'GET_DICTIONARY':        () => void 0,
    'SET_DICTIONARY':        (data) => data,
    'FAIL_FETCH_DICTIONARY': (error) => error,
});
