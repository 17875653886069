import { put, take, race, select, call, takeEvery } from 'redux-saga/effects';
import * as R from 'ramda';

import { uiActions } from 'bus/ui';
import { hotActions } from 'bus/hot';
import { getDefaultCountry, getHotToursUIByBlock } from 'bus/hot/selectors';

import { bootstrapData } from './actions';
import { UI_FLAG_HOT_TOURS } from './constants';

const getGlobalShape = () => global.document.querySelector('[data-loader-shape="hot-list-tours"]');

const removeGlobalShape = () => R.call(
    R.when(Boolean, (node) => node.classList.add('local-hidden')),
    getGlobalShape()
);

function* getHotToursSaga (blockID) {
    const { completed } = yield select((state) => getHotToursUIByBlock()(state, { blockID }));

    if (completed) {
        return [true, false];
    }

    yield put(hotActions.getHotTours(blockID));

    const results = yield race([
        take(hotActions.getHotToursSuccess),
        take(hotActions.getHotToursFail)
    ]);

    return results;
}

function* bootstrapDataSaga ({ payload: blockID }) {
    const { hotBlockID: defaultBlockID } = yield select(getDefaultCountry);

    yield put(uiActions.setUiState([UI_FLAG_HOT_TOURS, blockID], {
        loading:   true,
        completed: false,
    }));

    const [, fail] = yield call(getHotToursSaga, blockID);

    if (blockID !== defaultBlockID && fail) {
        yield put(bootstrapData(defaultBlockID));
    }

    yield put(uiActions.setUiState([UI_FLAG_HOT_TOURS, blockID], {
        loading:   false,
        completed: true,
    }));

    removeGlobalShape();
}

export default function* () {
    yield takeEvery(bootstrapData, bootstrapDataSaga);
}
