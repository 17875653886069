import { Component } from 'react';
import * as Sentry from '@sentry/react';
import * as R from 'ramda';

import { version as release } from '../../../package.json';

class ErrorBoundary extends Component {
    static getDerivedStateFromError (error) {
        return {
            error:   true,
            message: error.message,
        };
    }

    constructor (props) {
        super(props);

        Sentry.init({
            dsn:          'https://8856c2d736cd404998024679e0dfc3ab@o184447.ingest.sentry.io/5412068',
            environment:  __ENV__,
            release,
            integrations: [
                new Sentry.Integrations.GlobalHandlers({
                    onunhandledrejection: false,
                    onerror:              false,
                })
            ],
        });

        this.state = { error: false, message: null };
    }

    componentDidCatch (error, info) {
        Sentry.withScope((scope) => {
            R.call(
                R.pipe(
                    R.toPairs,
                    R.forEach(([key, value]) => scope.setExtra(key, value))
                ),
                info
            );

            Sentry.captureException(error);
        });
    }

    render () {
        const { children } = this.props;
        const { error, message } = this.state;

        return error ? message : children;
    }
}

export default ErrorBoundary;
