import * as R from 'ramda';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoaderShapes, showAllHiddenElements } from './helpers';

import { initializeApp } from './store/actions';

import { isInitializedApp, isPageReady } from "./store/selectors";

const onSuccessBootstrap = R.once(
    () => {
        showAllHiddenElements();
        hideLoaderShapes();
    }
);

const AppBootstrap = ({ children }) => {
    const isInitialized = useSelector(isInitializedApp);
    const isReady = useSelector(isPageReady);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isReady) {
            onSuccessBootstrap();
        }
    }, [isReady]);

    useEffect(() => {
        dispatch(initializeApp());
    }, [dispatch]);

    return isInitialized && children;
};

export default AppBootstrap;
