import { takeEvery } from 'redux-saga/effects';

// workers
import { getGraphWorker } from './workers/getGraph';

// actions
import { graphActions as actions } from './../actions';

export const graphWatchers = Object.freeze({
    * getGraphWatcher () {
        yield takeEvery(actions.getGraph, getGraphWorker);
    },
});
