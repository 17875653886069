// Core
import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import { getCurrencyByCode } from '@otpusk/json-api/dist/dictionary';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

import { getRooms } from 'bus/common/selectors';
import { isMobile as isMobileSelector } from 'components/DeviceDetector/store/selectors';

import { getAirName, getOperatorName } from 'helpers/staticData';

import UahPrice from "components/ui/UahPrice";

import { OfferProvider } from 'providers/Offer';

import BookingLink from './BookingLink';
import Flight from './components/Flight';
import Duration from './components/Duration';

import styles from './styles.scss';

const extractIATA = (iata) => R.slice(0, 3, iata);

const getOtherInboundCity = (flight) => R.call(
    R.ifElse(
        ({ inbound, outbound }) => outbound && inbound && extractIATA(outbound.portFr) !== extractIATA(inbound.portTo),
        ({ inbound }) => R.concat('-', inbound.portFr),
        R.F
    ),
    flight
);

const hasOtherInboundCity = R.compose(Boolean, getOtherInboundCity);

const Price = ({ currency, price, className }) => (
    <div className = { className } >
        { currency === 'uah'
            ? (
                <UahPrice className = { styles.uahPrice }>
                    { price }
                </UahPrice>
            )
            : price }
    </div>
);

const HotelTours = ({ items, searchCurrency }) => {
    const rooms = useSelector(getRooms);
    const currency = useMemo(() => getCurrencyByCode(searchCurrency), [searchCurrency]);

    const isMobile = useSelector(isMobileSelector);

    const { t } = useTranslation('OTHER_PRICES_TABLE');

    return (
        <div className = { styles.root }>
            <div className = { styles.tableWrapper }>
                <table>
                    <thead>
                        <tr>
                            <th width = '35'>
                                <div
                                    className = { classnames(styles.icon, styles.calendar) }
                                    title = { t('DATE_OUTBOUND_LABEL') }
                                />
                            </th>
                            <th width = '22'>
                                <div
                                    className = { classnames(styles.icon, styles.moon) }
                                    title = { t('DURATION_LABEL') }
                                />
                            </th>
                            <th>
                                <div className = { styles.thTitle }>
                                    <div
                                        className = { classnames(styles.icon, styles.room) }
                                        title = { t('ROOM_LABEL') }
                                    />
                                    { t('ROOM_TYPE_LABEL') }
                                </div>
                            </th>
                            <th width = { isMobile ?'24' :'30' }>
                                <div
                                    className = { classnames(styles.icon, styles.food) }
                                    title = { t('FOOD_LABEL') }
                                />
                            </th>
                            <th width = { isMobile ? '82' :'92' }>
                                <div className = { styles.thTitle }>
                                    <div className = { styles.seatsWrapper } title = { t('OUTBOND_FLIGHT_LABEL') }>
                                        <div className = { classnames(styles.icon, styles.plane) } />
                                    </div>
                                    { t('OUTBOND_FLIGHT_LABEL') }
                                </div>
                            </th>
                            <th width = { isMobile ? '40' : '72' }>
                                <div className = { styles.durationTitle }>{ t('TIME_ON_THE_WAY') }</div>
                            </th>
                            <th width = { isMobile ? '52' : '72' }>{ t('PARTNER_LABEL') }</th>
                            { isMobile && (
                                <>
                                    <th
                                        className = { styles.priceCur }
                                        data-cur = { currency && currency.label }
                                        title = { t('PRICE_CURRENCY_LABEL', { currency: currency && currency.label }) }
                                        width = { isMobile ? '38': '50' }>
                                        { currency && currency.label }
                                    </th>
                                    <th className = { styles.curTd } width = { isMobile ? '32' : '42' }>
                                        { t('RATE_LABEL') }
                                    </th>
                                </>
                            ) }
                            { !isMobile && (
                                <>
                                    <th width = '42'>{ t('RATE_LABEL') }</th>
                                    <th
                                        className = { styles.priceCur }
                                        data-cur = { currency && currency.label }
                                        title = { t('PRICE_CURRENCY_LABEL', { currency: currency && currency.label }) }
                                        width = '50' >
                                        { currency && currency.label }
                                    </th>
                                </>
                            ) }
                        </tr>
                    </thead>
                    <tbody>
                        { items.map(({ date, operator, food, flights, id, nights, room, discountPrice, price, tourLink, currencyRate, qqPayload }) => {
                            const { inbound, outbound } = flights;

                            return (
                                <tr className = { classnames({ [styles['color--yellow-light']]: hasOtherInboundCity(flights) }) } key = { id }>
                                    <td>
                                        { date }
                                    </td>
                                    <td>{ nights }</td>
                                    <td>
                                        <div className = { styles.rooms }>
                                            <div className = { classnames(styles.roomItem, room !== R.propOr(room, room, rooms) && styles.hasAltName) }>{ R.propOr(room, room, rooms) }</div>
                                            { room !== R.propOr(room, room, rooms) && (
                                                <div className = { styles.roomItem }>
                                                    { room }
                                                </div>
                                            ) }
                                            <div className = { styles.roomTooltip }>
                                                { R.propOr(room, room, rooms) }
                                                { room !== R.propOr(room, room, rooms) && (
                                                    <>
                                                        <br />
                                                        { room }
                                                    </>
                                                ) }
                                            </div>
                                        </div>
                                    </td>
                                    <td>{ food }</td>
                                    <td colSpan = { hasOtherInboundCity(flights) ? '2' : '1' } style = { { textAlign: 'left', paddingLeft: '10px' } }>
                                        { outbound && <Flight { ...outbound } /> }
                                        <br />
                                        { inbound && (
                                            <Flight { ...inbound }>
                                                { getOtherInboundCity(flights) }
                                            </Flight>
                                        ) }
                                    </td>
                                    { !hasOtherInboundCity(flights) && <td>
                                        { outbound && <Duration { ...outbound } /> }
                                        <br />
                                        { inbound && (
                                            <Duration { ...inbound } />
                                        ) }
                                    </td> }
                                    <td style = { { textAlign: 'left', paddingLeft: '10px' } }>
                                        { getOperatorName(operator)
                                            ? (
                                                <OfferProvider offerID = { id }>
                                                    { ({ ui, getOffer, offer }) => (
                                                        <BookingLink
                                                            href = { R.propOr('', 'bookingUrl', offer) }
                                                            onClick = { !R.prop('bookingUrl', offer)
                                                                ? (e) => {
                                                                    e.preventDefault();
                                                                    getOffer();
                                                                }
                                                                : void 0 }>
                                                            { ui.loading && '...' }
                                                            { !ui.loading && !ui.error && getOperatorName(operator) }
                                                            { ui.error && 'Expired price' }
                                                        </BookingLink>
                                                    ) }
                                                </OfferProvider>
                                            )
                                            : '-'
                                        }
                                        <br />
                                        <nobr>{ inbound && getAirName(inbound.line) }</nobr>
                                    </td>
                                    { isMobile
                                        ? (
                                            <>
                                                <td className = { styles.priceTd }>
                                                    <a
                                                        href = { tourLink }
                                                        rel = 'noopener noreferrer'
                                                        target = '_blank'
                                                        title = { t('BOOKING_LINK') }>
                                                        <Price
                                                            className = { styles.originalPrice }
                                                            currency = { searchCurrency }
                                                            price = { price }
                                                        />
                                                        { discountPrice && (
                                                            <Price
                                                                className = { styles.discountPrice }
                                                                currency = { searchCurrency }
                                                                price = { discountPrice }
                                                            />
                                                        ) }
                                                    </a>
                                                </td>
                                                <td className = { styles.curTd }>
                                                    { currencyRate.toFixed(2) }
                                                    <div style = { { position: 'relative' } }>
                                                        <div className = 'qq-btn-place' data-value = { qqPayload } />
                                                    </div>
                                                </td>
                                            </>
                                        )
                                        : (
                                            <>
                                                <td>
                                                    { currencyRate.toFixed(2) }
                                                    <div style = { { position: 'relative' } }>
                                                        <div className = 'qq-btn-place' data-value = { qqPayload } />
                                                    </div>
                                                </td>
                                                <td className = { styles.priceTd }>
                                                    <a
                                                        href = { tourLink }
                                                        rel = 'noopener noreferrer'
                                                        target = '_blank'
                                                        title = { t('BOOKING_LINK') }>
                                                        <Price
                                                            className = { styles.originalPrice }
                                                            currency = { searchCurrency }
                                                            price = { price }
                                                        />
                                                        { discountPrice && (
                                                            <Price
                                                                className = { styles.discountPrice }
                                                                currency = { searchCurrency }
                                                                price = { discountPrice }
                                                            />
                                                        ) }
                                                    </a>
                                                </td>
                                            </>
                                        ) }
                                </tr>
                            );
                        }) }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

HotelTours.displayName = 'HotelTours';

export default memo(HotelTours);
