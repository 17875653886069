import { handleActions } from 'redux-actions';
import { Map } from 'immutable';

// instruments
import { currencyActions } from './actions';
import { DEFAULT_CURRENCY } from './constants';

const initialState = Map({
    activeCurrency: undefined,
});

export default handleActions({
    [currencyActions.setActiveCurrency]:  (state, { payload: currencyCode }) => state.set('activeCurrency', currencyCode),
    [currencyActions.setDefaultCurrency]: (state) => state.set('activeCurrency', DEFAULT_CURRENCY),
}, initialState);
