// Core
import React, { memo, useCallback, useMemo } from 'react';
import cx from 'classnames';
import classnames from 'classnames/bind';
import * as R from 'ramda';
import T from 'prop-types';

// Styles
import Styles from './thumbs.scss';

// Instruments
import {
    createCssThumbsTransformRules
} from './helpers';
import {
    ANIMATION,
    GEO_MAP_ELEMENT,
    CUSTOM_HOTEL_VIDEO_ELEMENT,
    CUSTOM_TOURIST_HOTEL_VIDEO_ELEMENT
} from './constants';
import GoogleMapThumb from './img/googleMapThumb.png';
import YouTubeThumb from './img/youtube.png';
import TouristVideoThumb from './img/touristVideoThumb.png';

const isThumbsVisibleClass = classnames.bind({
    'visible': Styles['root--visible'],
});

const createElementByPhoto = (photo) => R.call(
    R.cond([
        [R.equals(GEO_MAP_ELEMENT), R.always(GoogleMapThumb)],
        [R.equals(CUSTOM_HOTEL_VIDEO_ELEMENT), R.always(YouTubeThumb)],
        [R.equals(CUSTOM_TOURIST_HOTEL_VIDEO_ELEMENT), R.always(TouristVideoThumb)],
        [R.T, R.always(photo)]
    ]),
    photo
);

const Thumbs = ({ activeSlide, photos, thumbStep, visible, onChange }) => {
    const transform = useMemo(
        () => createCssThumbsTransformRules(
            activeSlide,
            photos.length,
            ANIMATION.SLIDE,
            thumbStep
        ),
        [activeSlide, photos.length, thumbStep]
    );

    const denormalizedThumbStep = useMemo(
        () => R.subtract(thumbStep, 1),
        [thumbStep]
    );

    // methods
    const onPrev = useCallback(
        R.compose(
            onChange,
            R.when(
                (next) => next < 0,
                () => 0
            ),
            () => Math.floor((activeSlide - denormalizedThumbStep) / thumbStep) * thumbStep
        ),
        [activeSlide, thumbStep, denormalizedThumbStep, onChange]
    );
    const onNext = useCallback(
        R.compose(
            onChange,
            R.when(
                (next) => next === activeSlide && next === photos.length - 1,
                R.always(0)
            ),
            R.when(
                (next) => next > photos.length - thumbStep,
                () => photos.length - 1
            ),
            () => R.pipe(
                (value) => value || 1,
                (value) => Math.ceil((value + denormalizedThumbStep) / thumbStep) * thumbStep
            )(activeSlide)
        ),
        [activeSlide, thumbStep, denormalizedThumbStep, photos, onChange]
    );

    return (
        <div className = { cx(Styles.root, isThumbsVisibleClass({ visible })) }>
            <div className = { Styles.thumbsContainer }>
                <div className = { Styles.thumbsWrapper } style = { transform }>
                    { photos.map((photo, index) => (
                        <div
                            className = { cx(Styles.thumb, { [Styles.active]: index === activeSlide }) }
                            key = { index }
                            style = { { backgroundImage: `url("${createElementByPhoto(photo)}")` } }
                            onClick = { () => onChange(index) }
                        />
                    )) }
                </div>
            </div>
            <div className = { Styles.thumbsControls }>
                <div className = { cx(Styles.thumbsControl, Styles.prev) } onClick = { onPrev } />
                <div className = { cx(Styles.thumbsControl, Styles.next) } onClick = { onNext } />
            </div>
        </div>
    );
};


Thumbs.propTypes = {
    activeSlide: T.number.isRequired,
    photos:      T.arrayOf(T.string).isRequired,
    thumbStep:   T.number.isRequired,
    visible:     T.bool,
    onChange:    T.func,
};
Thumbs.defaultProps = {
    onNext:      () => void 0,
    onPrev:      () => void 0,
    onItemClick: () => void 0,
};


export default memo(Thumbs);
