import React, { memo } from 'react';
import T from 'prop-types';

import styles from './modal.scss';

const YouTubeSlide = ({ src }) => (
    <iframe
        allowFullScreen
        className = { styles.youTube }
        frameBorder = '0'
        src = { `https://www.youtube.com/embed/${src}` }
    />
);

YouTubeSlide.propTypes = {
    src: T.string.isRequired,
};

export default memo(YouTubeSlide);
