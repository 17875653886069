import * as R from 'ramda';
import moment from 'moment';

import { JOIN_UP_ID, JOIN_UP_DISCOUNT_KOEF } from './constants';

export const normalizePrice = (price) => Math.ceil(price);

export const getDiscountPrice = (offer) => R.prop('@discountPriceNBU', offer);

export const getPrice = (offer) => R.prop('@priceNBU', offer);

export const getFallbackPrice= (offer) => R.prop('price', offer);

const isJoinUpDiscountTime = () => R.call(
    (now) => R.or(
        moment().startOf('day').hour(22).isSameOrBefore(now),
        moment().startOf('day').hour(8).isSameOrAfter(now)
    ),
    moment()
);

const isJoinUp = (offer) => R.call(
    R.pipe(R.prop('operator'), Number, R.equals(JOIN_UP_ID)),
    offer
);

const computeDiscountPriceForJoinUp = (offer) => R.call(
    R.pipe(
        getFallbackPrice, R.toPairs,
        R.map(([currency, price]) => [currency, price * JOIN_UP_DISCOUNT_KOEF]),
        R.fromPairs
    ),
    offer
);

export const getDiscountPriceForJoinUp = (offer) => R.and(
    R.and(
        isJoinUpDiscountTime(),
        isJoinUp(offer)
    ),
    computeDiscountPriceForJoinUp(offer)
);
