import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Styles from "./styles.scss";

const Ensurance = ({ resultVersion }) => {
    const { t } = useTranslation('PRICES_LIST');

    return (
        <span className = { classnames(Styles.root, { [Styles.resultVersion]: resultVersion }) }>
            <span className = { Styles.title }>
                { t('ENSURANCE_LABEL') }
            </span>
            <span className = { Styles.desktop }>{ t('ENSURANCE_TEXT') }</span>
            <span className = { Styles.mobile }>{ t('ENSURANCE_TEXT_MOBILE') }</span>
        </span>
    );
};

export default memo(Ensurance);
