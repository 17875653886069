// Core
import { select, call, spawn } from 'redux-saga/effects';
import md5 from 'blueimp-md5';
import * as Sentry from '@sentry/react';

class CloseError extends Error {}

const getPaymentInformation = (operatorID) => {
    switch (Number(operatorID)) {
        case 717: return { merchantAccount: 'antonivtour_com2', merchantSecretKey: '60570ad87f287356eb4aac8a83be51b039091725' }; /* Anex Tour */
        case 2835:
        case 2700:
            return { merchantAccount: 'antonivtour_com', merchantSecretKey: '26dee50710c40334688adc790c63c3d5ae84ac6e' }; /* JoinUP, TPG */
        default: return { merchantAccount: 'antonivtour_com1', merchantSecretKey: '211a1735546134f00db28b5b061eea18c790ad46' };
    }
};

const paingBywayforpay = (client, offer, paymentAmount) => {
    const { id, price, operator } = offer;
    const { name: clientFirstName, email: clientEmail, phone: clientPhone } = client;

    return new Promise((resolve, rejecte) => {

        if (!window.Wayforpay) {
            return rejecte(new Error('Платіжна система недоступна'));
        }

        const wayforpay = new window.Wayforpay();

        const { merchantAccount, merchantSecretKey } = getPaymentInformation(operator);
        const merchantDomainName = 'antonivtour.com';
        const orderReference = Date.now();
        const orderDate = String(Math.round(Date.now() / 1000));
        const amount = paymentAmount;
        const currency = 'UAH';
        const productName = id;
        const productCount = '1';
        const { uah: productPrice } = price;
        const prepareMerchantSignature = [
            merchantAccount,
            merchantDomainName,
            orderReference,
            orderDate,
            amount,
            currency,
            productName,
            productCount,
            productPrice
        ].join(';');

        const handleCloseWayForPayWidget = ({ data }) => {
            if (data === 'WfpWidgetEventClose') {
                rejecte(new CloseError(void 0));
                window.removeEventListener('messsage', handleCloseWayForPayWidget);
            }
        };

        window.addEventListener('message', handleCloseWayForPayWidget);
        wayforpay.run(
            {
                merchantAccount,
                orderReference,
                merchantDomainName,
                authorizationType:             'SimpleSignature',
                merchantSignature:             md5(prepareMerchantSignature, merchantSecretKey),
                orderDate,
                amount,
                currency,
                productName,
                productPrice,
                productCount,
                clientFirstName,
                clientLastName:                ' - ',
                clientEmail,
                clientPhone,
                language:                      'UA',
                merchantTransactionSecureType: 'AUTO',
            },
            (payment) => (window.removeEventListener('message', handleCloseWayForPayWidget), resolve(payment)),
            (error) => (window.removeEventListener('message', handleCloseWayForPayWidget), rejecte(error)));
    });
};

export function* payWorker ({ payload }) {
    const { base, client, payment: basePayment, offerID, formik } = payload;
    const offer = yield select(({ offers }) => offers.getIn(['store', offerID]));

    try {
        const wayforpayPayment = basePayment.amount && (yield call(paingBywayforpay, client, offer, basePayment.amount));

        try {
            yield spawn(fetch, 'https://antonivtours.getmansky.im/endpoint.php', {
                method:  'POST',
                headers: { 'Content-Type': 'application/json' },
                body:    JSON.stringify({
                    id:   offer.id,
                    date: new Date().toString(),
                    wayforpayPayment,
                }),
            });
        } catch (error) {
            console.log(error);
        }

        yield call(fetch, '/wp-json/rest_api/v1/tour_form', {
            method:  'POST',
            headers: { 'Content-Type': 'application/json' },
            body:    JSON.stringify({
                ...base,
                client,
                payment: basePayment.amount ? {
                    type:              basePayment.type,
                    amount:            wayforpayPayment.amount,
                    currency:          wayforpayPayment.currency,
                    createdDate:       wayforpayPayment.createdDate,
                    transactionStatus: wayforpayPayment.transactionStatus,
                    reason:            wayforpayPayment.reason,
                } : {},
                offer: {
                    id:  offer.id,
                    url: window.location.href, /* so bad */
                },
            }),
        });

        yield call(formik.resetForm);
        yield call(formik.setStatus, { success: true, skipPay: !basePayment.amount });
    } catch (error) {
        if (error instanceof CloseError) {
            return;
        }

        yield call(formik.setStatus, { error: true, message: error.message });

        return Sentry.captureException(error);
    } finally {
        yield call(formik.setSubmitting, false);
    }
}
