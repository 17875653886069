import * as R from 'ramda';

import { PARAM_NAME } from './constants';

const parseSearch =  (search) => new URLSearchParams(search);
const compileSearch = (query) => query.toString();

export const isSetParam = (search) => R.pipe(
    parseSearch,
    (query) => query.has(PARAM_NAME),
    R.complement(Boolean)
)(search);

export const setParam = R.compose(
    compileSearch,
    (query) => (query.set(PARAM_NAME, ''), query),
    parseSearch
);

export const deleteParam = R.compose(
    compileSearch,
    (query) => (query.delete(PARAM_NAME), query),
    parseSearch
);
