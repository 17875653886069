import React, { memo } from 'react';
import MaskedInput from 'react-text-mask';
import { FastField, getIn } from 'formik';
import * as R from 'ramda';
import T from 'prop-types';

// components
import Modal from 'components/ui/Modal';
import { Captcha } from 'components/hotel/BookingInfoForm/Captcha';

// styles
import Styles from './styles.scss';
import { useTranslation } from 'react-i18next';

const OrderForm = ({ status, isSubmitting, onCloseOrderModal, onSubmit }) => {
    const { t } = useTranslation('CONSULT_ORDER_FORM');

    const successMsg = R.prop('success', status) && R.prop('message', status);
    const errorMsg = R.prop('error', status) && R.prop('message', status);
    const submitComplete = Boolean(successMsg) || Boolean(errorMsg);

    return (
        <Modal header = { t('TITLE') } onClose = { onCloseOrderModal }>
            <div className = { Styles.modalOrder }>
                { !submitComplete && (
                    <form onSubmit = { onSubmit }>
                        <div className = { Styles.formRow }>
                            <FastField
                                name = 'name'
                                render = { ({ field, form }) => {
                                    const error = getIn(form.touched, field.name) && getIn(form.errors, field.name);

                                    return (
                                        <>
                                            <input placeholder = { t('NAME_PLACEHOLDER') } type = 'text' { ...field } />
                                            { Boolean(error) && <span className = { Styles.error }>{ error }</span> }
                                        </>
                                    );
                                } }
                            />
                        </div>
                        <div className = { Styles.formRow }>
                            <FastField
                                name = 'phone'
                                render = { ({ field, form }) => {
                                    const error = getIn(form.touched, field.name) && getIn(form.errors, field.name);

                                    return (
                                        <>
                                            <MaskedInput
                                                className = { field.value ? '' : 'empty' }
                                                guide = { false }
                                                mask = { ['+', '3', '8', '(', '0', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] }
                                                placeholder = '+38(0'
                                                { ...field }
                                            />
                                            <div data-empty-content = { t('PHONE_PLACEHOLDER') } />
                                            { Boolean(error) && <span className = { Styles.error }>{ error }</span> }
                                        </>
                                    );
                                } }
                            />

                        </div>
                        <div className = { Styles.formRow }>
                            <FastField
                                name = 'comment'
                                render = { ({ field }) => <textarea placeholder = { t('COMMENT_PLACEHOLDER') } { ...field } /> }
                            />
                        </div>
                        <div className = { Styles.formRow }>
                            <FastField
                                name = 'captcha'
                                render = { ({ form, field }) => (
                                    <Captcha value = { field.value } onChange = { (v) => form.setFieldValue(field.name, Boolean(v)) } />
                                ) }
                            />
                        </div>
                        <button disabled = { isSubmitting } type = 'submit'>
                            { isSubmitting ? t('SUBMIT_BUTTON_TITLE_SUBMITTING') : t('SUBMIT_BUTTON_TITLE') }
                        </button>
                    </form>
                ) }
                { successMsg && <span>{ successMsg }</span> }
                { errorMsg && <span>{ errorMsg }</span> }
            </div>
        </Modal>
    );
};

OrderForm.propTypes = {
    isSubmitting:      T.bool,
    status:            T.object,
    onCloseOrderModal: T.func,
    onSubmit:          T.func,
};

OrderForm.defaultProps = {
    onCloseOrderModal: () => {},
    onSubmit:          () => {},
};

export default memo(OrderForm);
