import * as R from 'ramda';

export const UK = 'uk';
export const RU = 'ru';

export const DEFAULT_LOCALE = UK;

export const LOCALE_TO_LANG_MAP = {
    [RU]: 'rus',
    [UK]: 'ukr',
};

export const LOCALE_LOADERS_MAP = {
    [RU]: () => import(/* ru-locale-json */ './locales/ru'),
    [UK]: () => import(/* ru-locale-json */'./locales/uk.json'),
};

export const LOCALE_TO_BASE_PATH_MAP = {
    [RU]:           '/ru/',
    DEFAULT_LOCALE: '/',
};

export const LANG_TO_LOCALE_MAP = R.call(
    R.pipe(
        R.toPairs,
        R.map(R.reverse),
        R.fromPairs
    ),
    LOCALE_TO_LANG_MAP
);
