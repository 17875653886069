import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import withData from './withData';

import styles from './styles';

const Switch = ({ checked, onChange }) => {
    const { t } = useTranslation('CURRENCIES');

    const handleChange = useCallback(
        (e) => onChange(e.target.checked),
        [onChange]
    );

    return (
        <div className = { styles.root }>
            <label className = { classnames({ [styles.yellow]: checked }) }>
                <input
                    checked = { checked }
                    type = 'checkbox'
                    onChange = { handleChange }
                />
                <span>{ t('UAH') }</span>
            </label>
        </div>
    );
};

Switch.propTypes = {
    checked:  PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default memo(Switch);
export const SwitcherComplexity = withData(Switch);
