import React, { memo, useEffect, useLayoutEffect, useMemo } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useSelectServices } from 'containers/molecules/Services/SelectServices';
import {
    getReviews,
    getUIReviews,
    getCommonReviews,
    getUICommonReviews,
    isSetTripadvisorEntity
} from 'bus/reviews/selectors';

import ViewCarousel from 'components/reviews/Carousel/View';
import Map from 'components/ui/Map';
import Spinner from 'components/ui/Spinner';
import { isMobile as isMobileSelector } from 'components/DeviceDetector/store/selectors';

import createUploader from 'helpers/uploader';

import TripadvisorReviews from './TripadvisorReviews';
import {
    MODAL_SCROLL_2_REVIEWS_QUERY_PARAM,
    MODAL_REVIEWS_BLOCK_POINTER,
    MODAL_REVIEWS_SCROLL_CONTAINER_ID,
    GEO_MAP_ELEMENT,
    CUSTOM_HOTEL_VIDEO_ELEMENT,
    CUSTOM_TOURIST_HOTEL_VIDEO_ELEMENT
} from './constants';
import { bootstrapDataForModal } from './store/actions';
import {
    getUIModal,
    getCustomHotelYouTubeVideo,
    getCustomHotelTouristYouTubeVideo
} from './store/selectors';
import PhotoSlide from './PhotoSlide';
import YouTubeSlide from './YouTubeSlide';
import GeoLocation from './GeoLocation';

import ratingStyles from '../Rating/styles.scss';
import Rating from '../Rating';
import Copy from '../Copy';

import { useSliderCtx } from './context';

import styles from './modal.scss';

const getServiceDescription = (info, key) => R.path([key, 'description'], info);
const isSetServicesList = (info, key) => R.hasPath([key, 'services'], info);
const getServicesList = (info, key) => R.call(
    R.pipe(R.toPairs, R.map(([label, allow]) => ({ isFree: allow === 'free', label }))),
    R.path([key, 'services'], info)
);

const INFO_SERVICES_LABELS_BY_ORDER = [
    'location', 'beach', 'hotel', 'child', 'sport', 'room'
];
const PRELOAD_PHOTO_STEP = 3;

const uploader = createUploader();

const saveUpload = (photo) => photo && uploader.upload(photo);

const Modal = ({
    touchHandlers,
    activePhotoIndex,
    hotel,
    linkToTour,
    linkToTourWithRate,
    photos,
    onClose,
    onNext,
    onPrev,
}) => {
    const { description, id: hotelID, name: hotelName, info, location, country, city, services } = hotel;
    const withGeoMap = Boolean(location);

    const { search } = useLocation();
    const { t } = useTranslation('PRICE_MODAL_GALLERY');
    const { labels } = useSelectServices();
    const {
        leftKeyboardButtonCallbackRegistrator,
        rightKeyboardButtonCallbackRegistartor,
        resetKeyboardButtonsCallbacks,
    } = useSliderCtx();

    const isMobile = useSelector(isMobileSelector);

    const customHotelYouTube = useSelector((state) => getCustomHotelYouTubeVideo(state, { hotelID }));
    const touristHotelYouTube = useSelector((state) => getCustomHotelTouristYouTubeVideo(state, { hotelID }));

    const [uiReviews, reviews] = useSelector((state) => [
        getUIReviews(state, { hotelID }),
        getReviews(state, { hotelID })
    ]);

    const [uiCommonReviews, commonReviews] = useSelector((state) => [
        getUICommonReviews(state),
        getCommonReviews(state)
    ]);

    const isSetTripadvisor = useSelector((state) => isSetTripadvisorEntity(state, { hotelID }));

    const uiModal = useSelector((state) => getUIModal(state, { hotelID }));

    const dispatch = useDispatch();

    const activeSlide = photos[activePhotoIndex];
    const arrowStyle = isMobile && activeSlide === CUSTOM_HOTEL_VIDEO_ELEMENT ? { display: 'block' } : undefined;

    const slideStyle = R.includes(
        activeSlide,
        [CUSTOM_HOTEL_VIDEO_ELEMENT, CUSTOM_TOURIST_HOTEL_VIDEO_ELEMENT, GEO_MAP_ELEMENT]
    )
        ? undefined
        : { backgroundImage: `url("${activeSlide}")` };

    const slideElement = useMemo(
        () => R.call(
            R.cond([
                [
                    R.equals(GEO_MAP_ELEMENT),
                    () => (
                        <Map
                            hotelName = { hotelName }
                            position = { { lat: location.lat, lng: location.lng } }
                            zoom = { location.zoom }
                        />
                    )],
                [R.equals(CUSTOM_HOTEL_VIDEO_ELEMENT), () => <YouTubeSlide src = { customHotelYouTube } />],
                [R.equals(CUSTOM_TOURIST_HOTEL_VIDEO_ELEMENT), () => <YouTubeSlide src = { touristHotelYouTube } />],
                [R.T, () => <PhotoSlide services = { services } />]
            ]),
            activeSlide
        ),
        [activeSlide, activePhotoIndex, hotel, photos, customHotelYouTube, touristHotelYouTube]
    );

    useEffect(() => {
        const handler = R.when(
            ({ key }) => R.equals(key, 'Escape'),
            onClose
        );

        document.addEventListener('keyup', handler);

        return () => document.removeEventListener('keyup', handler);
    }, []);

    useEffect(() => {
        const html = document.querySelector('html');

        html.classList.add('disableScroll');

        return () => html.classList.remove('disableScroll');
    }, []);

    useEffect(() => {
        const query = new URLSearchParams(search);

        if (Number(query.get(MODAL_SCROLL_2_REVIEWS_QUERY_PARAM) && uiModal.completed)) {
            Promise.resolve().then(() => scroller.scrollTo(MODAL_REVIEWS_BLOCK_POINTER, {
                duration:    500,
                delay:       0,
                smooth:      true,
                isDynamic:   true,
                containerId: MODAL_REVIEWS_SCROLL_CONTAINER_ID,
            }));
        }
    }, [uiModal]);


    useEffect(() => {
        !uiModal.completed && dispatch(bootstrapDataForModal(hotelID));
    }, [hotelID, dispatch]);

    useLayoutEffect(() => {
        R.forEach(
            uploader.upload,
            R.concat(
                R.take(PRELOAD_PHOTO_STEP, photos),
                R.takeLast(PRELOAD_PHOTO_STEP, photos)
            )
        );
    }, [photos]);

    useEffect(() => {
        const leftHandler = () => saveUpload(photos[activePhotoIndex - PRELOAD_PHOTO_STEP]);
        const rightHandler = () => saveUpload(photos[activePhotoIndex + PRELOAD_PHOTO_STEP]);

        leftKeyboardButtonCallbackRegistrator(leftHandler);
        rightKeyboardButtonCallbackRegistartor(rightHandler);

        return resetKeyboardButtonsCallbacks;
    }, [activePhotoIndex]);

    return (
        <div className = { styles.lgImageOverlay }>
            { uiModal.loading
                ? <Spinner />
                : uiModal.completed && (
                    <div className = { styles.imageContainer }>
                        <div className = { styles.imageContent }>
                            <div className = { styles.header }>
                                <div className = { styles.name }>
                                    <div className = { ratingStyles.servicesRating }>
                                        { Boolean(hotel.rating) && linkToTourWithRate && (
                                            <Rating
                                                rating = { hotel.rating }
                                                onClick = { () => window.open(linkToTourWithRate, '_blank') }
                                            />
                                        ) }
                                    </div>
                                    <strong>{ hotelName }</strong>
                                    <Copy text = { hotelName } />
                                </div>
                                { linkToTour && (
                                    <a className = { styles.button } href = { linkToTour } rel = 'noreferrer' target = '_blank'>
                                        { t('BOOKING_BUTTON') }
                                    </a>
                                ) }
                                <div className = { styles.lgImageClose } onClick = { onClose }>
                                    <span>{ t('CLOSE_BUTTON') }</span> &times;
                                </div>
                            </div>
                            <div className = { styles.imageWrapper }>
                                <div
                                    className = { classnames(styles.control, styles.prev) }
                                    style = { arrowStyle }
                                    onClick = { () => (
                                        saveUpload(photos[activePhotoIndex - PRELOAD_PHOTO_STEP]),
                                        onPrev()
                                    ) }
                                />
                                <div
                                    className = { styles.imgWrap }
                                    style = { slideStyle }
                                    { ...touchHandlers }>
                                    { slideElement }
                                    <div className = { styles.count }>
                                        { activePhotoIndex + 1 }
                                        <span>/</span>
                                        { R.length(photos) }
                                    </div>
                                    { withGeoMap && (
                                        <GeoLocation
                                            cityName = { city.name }
                                            countryName = { country.name }
                                            hotelID = { hotelID }
                                            hotelName = { hotelName }
                                        />
                                    ) }
                                </div>
                                <div
                                    className = { classnames(styles.control, styles.next) }
                                    style = { arrowStyle }
                                    onClick = { () => (
                                        saveUpload(photos[activePhotoIndex + PRELOAD_PHOTO_STEP]),
                                        onNext()
                                    ) }
                                />
                            </div>
                        </div>
                        <div className = { styles.imageSidebar } id = { MODAL_REVIEWS_SCROLL_CONTAINER_ID }>
                            <div className = { styles.imageSidebarInner }>
                                <div className = { styles.imageSidebarText }>
                                    { description && (
                                        <>
                                            <span className = { styles.aboutHotel }>
                                                { t('HOTEL_DESCRIPTION_TITLE') }
                                            </span>
                                            <p dangerouslySetInnerHTML = { { __html: description } } />
                                        </>
                                    ) }
                                    { R.map((key) => [
                                        <div className = { styles.serviceTitle } key = { `${key}-title` }>
                                            { labels.get(key) }
                                        </div>,
                                        <p dangerouslySetInnerHTML = { { __html: getServiceDescription(info, key) } } key = { `${key}-desc` } />,
                                        isSetServicesList(info, key) && (
                                            <ul key = { `${key}-services` }>
                                                { getServicesList(info, key).map(({ isFree, label }) => (
                                                    <li key = { label }>
                                                        { labels.get(label) }
                                                        { isFree && (
                                                            <>
                                                                {' '}
                                                                <span className = { styles.free }>{ t('FREE_SERVICE') }</span>
                                                            </>
                                                        ) }
                                                    </li>
                                                )) }
                                            </ul>
                                        )
                                    ], INFO_SERVICES_LABELS_BY_ORDER) }
                                </div>
                                { isSetTripadvisor && <TripadvisorReviews hotelID = { hotelID } /> }
                                { uiReviews.completed && (
                                    <Element className = { styles.revWrap } name = { MODAL_REVIEWS_BLOCK_POINTER }>
                                        <div>
                                            {uiReviews.error && (
                                                <div>{uiReviews.message}</div>
                                            )}
                                            {!reviews && (
                                                <div>{ t('REVIEWS_EMPTY_STATE') }</div>
                                            )}
                                            {reviews && (
                                                <div dangerouslySetInnerHTML = { { __html: reviews } } />
                                            )}
                                        </div>
                                    </Element>
                                )}
                                { uiCommonReviews.completed && commonReviews && (
                                    <ViewCarousel
                                        compact
                                        reviews = { commonReviews }
                                    />
                                ) }
                                { uiCommonReviews.error && uiCommonReviews.message }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

Modal.propTypes = {
    activePhotoIndex: T.number.isRequired,
    hotel:            T.shape({
        description: T.string,
        id:          T.string.isRequired,
        name:        T.string.isRequired,
        info:        T.object,
        services:    T.arrayOf(T.string),
        location:    T.object,
    }).isRequired,
    photos:             T.arrayOf(T.string).isRequired,
    onClose:            T.func.isRequired,
    linkToTour:         T.string,
    linkToTourWithRate: T.string,
    touchHandlers:      T.object,
};

export default memo(Modal);
