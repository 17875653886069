import { put, select, call, all } from 'redux-saga/effects';
import { getIn } from 'immutable';
import * as R from 'ramda';

// instruments
import { hotActions as actions } from 'bus/hot';
import { getAPIToken, getLang } from 'bus/auth/selectors';

import { normalizeHotOffer } from './helpers';

function* getHotTourWorker (token, lang, blockId, tourId) {
    const response = yield call(
        fetch,
        R.concat(
            'https://control.antonivtours.com/api/hotTour?',
            R.call(
                (query) => (
                    query.append('blockId', tourId),
                    query.append('access_token', token.access_token),
                    query.append('lang', lang),
                    query.toString()
                ),
                new URLSearchParams()
            )
        )
    );

    const { searchedTour: { data_view: denormalizeHotOffer } = {}} = yield call([response, 'json']);

    const hotOffer = denormalizeHotOffer && normalizeHotOffer(denormalizeHotOffer);

    if (hotOffer) {
        yield put(actions.addHotTours(blockId, { [hotOffer.hotelId]: hotOffer }));
    }
}

export function* getHotToursWorker ({ payload: blockId }) {
    const [token, lang] = yield all([
        select(getAPIToken),
        select(getLang)
    ]);

    yield put(actions.setUi(
        ['hotTours', blockId],
        { loading: true, error: false, completed: false, message: null }
    ));

    try {
        const hotBlockResponse = yield call(
            fetch,
            R.concat(
                'https://control.antonivtours.com/api/hotBlock?',
                R.call(
                    (query) => (
                        query.append('access_token', token.access_token),
                        query.append('blockId', blockId),
                        query.toString()
                    ),
                    new URLSearchParams()
                )
            )
        );
        const hotBlock = yield call([hotBlockResponse, 'json']);

        if (!getIn(hotBlock, ['tours', 'length'], false)) {
            throw new Error('Гарячі тури відсутні');
        }

        const { tours } = hotBlock;

        yield all(tours.map((tourId) => call(getHotTourWorker, token, lang, blockId, tourId)));
        yield put(actions.getHotToursSuccess());
    } catch (error) {
        yield put(actions.setUi(
            ['hotTours', blockId, 'error'],
            true
        ));
        yield put(actions.setUi(
            ['hotTours', blockId, 'message'],
            error.message
        ));
        yield put(actions.getHotToursFail(error));
    } finally {
        yield put(actions.setUi(
            ['hotTours', blockId, 'loading'],
            false
        ));
        yield put(actions.setUi(
            ['hotTours', blockId, 'completed'],
            true
        ));
    }
}
