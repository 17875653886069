import { call, put } from 'redux-saga/effects';

import actions from '../../actions';

export default function* () {
    try {
        const response = yield call(fetch, '/wp-json/rest_api/v1/dictionary');
        const dictionary = yield call([response, 'json']);

        yield put(actions.setDictionary(dictionary));
    } catch (error) {
        yield put(actions.failFetchDictionary(error));
        console.log(error.message);
    }
}
