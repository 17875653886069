import React, { memo, useMemo } from 'react';
import T from 'prop-types';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { getOperators } from 'providers/Operators/selectors';
import { getOperatorName } from 'helpers/staticData';

import styles from './styles.scss';

const Operator = ({ countryID, id, linkToOperatorSite, resultVersion }) => {
    const { t } = useTranslation('PRICES_LIST');

    const selector = useMemo(
        () => R.partialRight(getOperators(), [{ countryID }]),
        [countryID]
    );

    const operators = useSelector(selector);

    const operator = useMemo(
        () => R.find(R.pipe(R.prop('id'), Number, R.equals(id)), operators),
        [operators, id]
    );

    const OperatorTag = linkToOperatorSite ? 'a' : 'span';
    const operatorTagProps = linkToOperatorSite
        ? { href: linkToOperatorSite, target: '_blank' }
        : {};

    return (
        <span className = { classnames(styles.root, { [styles.resultVersion]: resultVersion }) }>
            <span className = { styles.title }>
                { t('OPERATOR_LABEL') }
            </span>
            <OperatorTag
                { ...operatorTagProps }
                className = { classnames(styles.operator, { [styles['operator--underline']]: !resultVersion }) }>
                { getOperatorName(R.prop('name', operator)) }
            </OperatorTag>
        </span>
    );
};

Operator.propTypes = {
    countryID:          T.number.isRequired,
    id:                 T.number.isRequired,
    linkToOperatorSite: T.string,
    resultVersion:      T.bool,
};

export default memo(Operator);
