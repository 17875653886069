// Core
import React, { forwardRef, memo, useMemo } from 'react';
import T from 'prop-types';
import classnames from 'classnames';
import classnamesBind from 'classnames/bind';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import {
    getCustomHotelYouTubeVideo,
    getCustomHotelTouristYouTubeVideo
} from './store/selectors';
import YouTubeSlide from './YouTubeSlide';
import { createCssSingleTransformRules } from './helpers';
import {
    ANIMATION,
    CUSTOM_HOTEL_VIDEO_ELEMENT,
    CUSTOM_TOURIST_HOTEL_VIDEO_ELEMENT
} from './constants';

import styles from '../styles.scss';
import coreStyles from './carousel.scss';

const animationSliderClass = classnamesBind.bind({
    [ANIMATION.FADE]:  coreStyles['animation--fade'],
    [ANIMATION.SLIDE]: coreStyles['animation--slide'],
});

const activeSlideClass = classnamesBind.bind({
    'active': coreStyles['slide--active'],
});

const stopBubbleEven = (e) => (e.stopPropagation(), e.preventDefault());

const isShowPrevBtn = (counts, active, isHide) => R.ifElse(
    Boolean,
    () => R.complement(R.equals)(active, 0),
    () => Boolean(counts)
)(isHide);

const isEnd = (counts, active) => R.equals(
    R.subtract(counts, 1),
    active
);

const createSlideElement = (slide, customHotelYouTube, touristHotelYouTube) => R.call(
    R.cond([
        [R.equals(CUSTOM_HOTEL_VIDEO_ELEMENT), () => <YouTubeSlide src = { customHotelYouTube } />],
        [R.equals(CUSTOM_TOURIST_HOTEL_VIDEO_ELEMENT), () => <YouTubeSlide src = { touristHotelYouTube } />],
        [R.T, () => null]
    ]),
    slide
);

const slidesMapper = R.addIndex(R.map);

const SingleCarousel = forwardRef(({
    activeSlide,
    animation,
    items,
    isHideControl,
    onActiveItemClick,
    onPrev,
    onNext,
    hotelID,
}, ref) => {
    const customHotelYouTube = useSelector((state) => getCustomHotelYouTubeVideo(state, { hotelID }));
    const touristHotelYouTube = useSelector((state) => getCustomHotelTouristYouTubeVideo(state, { hotelID }));

    const sliderControlCssRules = useMemo(
        () => createCssSingleTransformRules(activeSlide, animation),
        [activeSlide, animation]
    );

    return (
        <div
            className = { coreStyles.slider }
            ref = { ref }>
            <div
                className = { classnames(
                    coreStyles['slider-wrapp'],
                    { [coreStyles.animation]: isEnd(items.length, activeSlide) },
                    animationSliderClass({ [animation]: true })
                ) }
                style = { sliderControlCssRules }>
                { slidesMapper(
                    (slide, idx) => (
                        <span
                            className = { classnames(
                                styles.img,
                                coreStyles.slide,
                                activeSlideClass({ active: idx === activeSlide })
                            ) }
                            key = { idx }
                            style = {
                                R.includes(slide, [CUSTOM_HOTEL_VIDEO_ELEMENT, CUSTOM_TOURIST_HOTEL_VIDEO_ELEMENT])
                                    ? undefined
                                    : { backgroundImage: `url("${slide}")` }
                            }
                            onClick = { idx === activeSlide ? onActiveItemClick : void 0 }>
                            { createSlideElement(slide, customHotelYouTube, touristHotelYouTube) }
                        </span>
                    ),
                    items
                ) }
            </div>
            { isShowPrevBtn(items.length, activeSlide, isHideControl) && (
                <div className = { classnames(coreStyles['slider-button'], coreStyles['slider-button--prev']) } onClick = { stopBubbleEven }>
                    <button
                        type = 'button'
                        onClick = { onPrev }
                    />
                </div>
            ) }
            <div className = { classnames(coreStyles['slider-button'], coreStyles['slider-button--next']) } onClick = { stopBubbleEven }>
                <button
                    type = 'button'
                    onClick = { onNext }
                />
            </div>
        </div>
    );
});

SingleCarousel.propTypes = {
    activeSlide:       T.number.isRequired,
    animation:         T.oneOf(Object.values(ANIMATION)).isRequired,
    hotelID:           T.string.isRequired,
    items:             T.arrayOf(T.string).isRequired,
    onActiveItemClick: T.func,
    onNext:            T.func,
    onPrev:            T.func,

};
SingleCarousel.defaultProps = {
    onNext:        () => void 0,
    onPrev:        () => void 0,
    isHideControl: false,
};

export default memo(SingleCarousel);
