import { all, put, race, select, take, call } from 'redux-saga/effects';
import { searchActions } from '@otpusk/apisearch-toolbox/dist/search';
import { queriesActions } from '@otpusk/apisearch-toolbox/dist/queries';
import { QUERY_PARAMS } from '@otpusk/apisearch-toolbox/dist/queries/fn';
import * as R from 'ramda';

import { uiActions } from 'bus/ui/actions';

import { initializePrefetch, prefetchNextPage, setShouldPrefetchRepeat } from './actions';
import { getActivePage, shouldPrefetchRepeat } from './selectors';
import { getUIKey } from './helpers';

const { changeQueryParam } = queriesActions;
const { cancelledSearch, failSearch, finishSearch, runSearch } = searchActions;

const createSeachActionHandler = (actionCreator, getQueryID) => (queryID) => (action) => R.and(
    R.equals(
        action.type,
        actionCreator.toString()
    ),
    R.equals(
        getQueryID(action),
        queryID
    )
);

const getDeepQueryKey = (action) => R.path(['payload', 'queryId'], action);
const getQueryKey = ({ payload: queryID }) => queryID;

const handleFinishSearch = createSeachActionHandler(finishSearch, getDeepQueryKey);
const handleFailSearch = createSeachActionHandler(failSearch, getQueryKey);
const handleCancelledSearch = createSeachActionHandler(cancelledSearch, getQueryKey);
const handleManualPrefetchNextPage = createSeachActionHandler(prefetchNextPage, getQueryKey);

function* prefetchNextPageWorker (queryID) {
    const activePage = yield select((store) => getActivePage()(store, { queryID }));

    yield put(changeQueryParam(queryID, QUERY_PARAMS.PAGE, R.inc(activePage)));
    yield put(runSearch(queryID));
}

export default function* () {
    const { payload: queryID } = yield take(initializePrefetch);

    while (true) {
        const shouldRepeat = yield select(shouldPrefetchRepeat);

        if (!shouldRepeat) {
            yield take([handleFinishSearch(queryID), handleManualPrefetchNextPage(queryID)]);
        } else {
            yield put(setShouldPrefetchRepeat(false));
        }

        const uiKey = getUIKey(queryID);

        yield put(uiActions.setUiState(uiKey, { loading: true }));

        yield all([
            call(prefetchNextPageWorker, queryID),
            race([
                take(handleFinishSearch(queryID)),
                take(handleFailSearch(queryID)),
                take(handleCancelledSearch(queryID))
            ])
        ]);

        yield put(uiActions.updateUiState(uiKey, { loading: false }));
    }
}
