import { takeEvery } from 'redux-saga/effects';

// workers
import { getReviewsWorker } from './workers/getReviews';
import getCommonReviewsWorker from './workers/getCommonReviews';
import getTripadvisorReviewsWorker from './workers/getTripadvisorReviews';

// actions
import { reviewsActions as actions } from 'bus/reviews/actions';

export const reviewsWathcers = Object.freeze({
    * getReviews () {
        yield takeEvery(actions.getReviews, getReviewsWorker);
    },
    * getTripadvisorReviewsWatcher () {
        yield takeEvery(actions.getTripadvisorReviews, getTripadvisorReviewsWorker);
    },
    * getCommonReviewsWatcher () {
        yield takeEvery(actions.getCommonReviews, getCommonReviewsWorker);
    },
});
