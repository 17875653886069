import { useSelector } from 'react-redux';

import { getActiveCurrency } from 'bus/currency/selectors';

const useActiveCurrency = () => {
    const activeCurrency = useSelector(getActiveCurrency);

    return activeCurrency;
};

export default useActiveCurrency;
