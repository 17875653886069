import { takeEvery } from 'redux-saga/effects';

// actions
import { hotelPricesActions as actions } from 'bus/hotelPrices';

// workers
import { getHotelPricesWithGraphWorker } from './workers/getHotelPricesWithGraph';

export const hotelPricesWatchers = Object.freeze({
    * getHotelPricesWithGraphWatcher () {
        yield takeEvery(actions.getHotelPricesWithGraph, getHotelPricesWithGraphWorker);
    },
});
