import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import { reviewsActions as actions } from './actions';

const initialState = {
    common:              [],
    byHotelsTurpravda:   {},
    byHotelsTripadvisor: {},
};

export const reviewsReducer = handleActions({
    [actions.getReviewsSuccess]: (state, { payload: { hotelID, reviews }}) => R.set(
        R.lensPath(['byHotelsTurpravda', hotelID]),
        reviews,
        state
    ),
    [actions.setTripadvisorReviews]: (state, { payload: { hotelID, reviews, description }}) => R.call(
        R.pipe(
            R.over(
                R.lensPath(['byHotelsTripadvisor', hotelID, 'reviews']),
                (prevReviews) => prevReviews ? R.concat(prevReviews, reviews) : reviews
            ),
            R.set(
                R.lensPath(['byHotelsTripadvisor', hotelID, 'description']),
                description
            )
        ),
        state
    ),
    [actions.getCommonReviewsSuccess]: (state, { payload: reviews }) => R.set(R.lensProp('common'), reviews, state),
}, initialState);
