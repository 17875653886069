import { takeEvery } from 'redux-saga/effects';

import { getCollections } from '../actions';

import getCollectionsWorker from './workers/getCollections';

export default Object.freeze({
    * getCollections () {
        yield takeEvery(getCollections, getCollectionsWorker);
    },
});
