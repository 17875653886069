import * as R from 'ramda';

import { RU, DEFAULT_LOCALE, LOCALE_LOADERS_MAP, LOCALE_TO_LANG_MAP, LANG_TO_LOCALE_MAP } from './constants';

export const computeLocale = () => R.call(
    R.cond([
        [R.includes('/ru'), R.always(RU)],
        [R.T, R.always(DEFAULT_LOCALE)]
    ]),
    global.location.pathname
);

export const convertLocaleToOtpuskLang = (locale) => R.propOr(
    R.prop(DEFAULT_LOCALE, LOCALE_TO_LANG_MAP),
    locale,
    LOCALE_TO_LANG_MAP
);

export const getLoaderByLocale = (locale) => R.has(locale, LOCALE_LOADERS_MAP)
    ? R.prop(locale, LOCALE_LOADERS_MAP)
    : R.prop(DEFAULT_LOCALE, LOCALE_LOADERS_MAP);

export const convertLangToLocale = (lang) => R.propOr(DEFAULT_LOCALE, lang, LANG_TO_LOCALE_MAP);
