import { createActions } from 'redux-actions';

export const bookingActions = createActions({
    GET_OPERATOR_CONTRACT:         (type, id) => ({ type, id }),
    GET_OPERATOR_CONTRACT_SUCCESS: (type, id, contract, link) => ({ type, id, contract, link }),
    GET_OPERATOR_CONTRACT_FAIL:    (error) => error,

    PAY: (base, client, payment, offerID, formik) => ({ base, client, payment, offerID, formik }),

    ORDER: (client, offerID, formik) => ({ client, offerID, formik }),
}, { prefix: 'APL_BOOKING' });
