import { parseCountry, parseCity, parsePrice } from '@otpusk/json-api/dist/normalize/parsers';


export const normalizeHotOffer = (tour) => {
    const {
        hotelId,
        dateString,
        food,
        length,
        promo,
        transport,
        cityFromId,
        operatorId,
        hotelName,
        hotelStars,
        imgSrc,
        offerId,
        currency,
        location,
        cityFrom,
        deptFrom,
        cityPortName,
        deptTo,
    } = tour;

    return {
        offerId: String(offerId),
        hotelId: String(hotelId),
        tour:    {
            name:    hotelName,
            stars:   Number(String(hotelStars).replace(/\D/gi, '')),
            country: parseCountry(tour),
            city:    parseCity(tour),
            photos:  [imgSrc.replace(/^.*\/\d+x\d+\//, '')],
            location,
            offer:   {
                id:        String(offerId),
                date:      dateString,
                departure: cityFromId,
                food,
                days:      length,
                nights:    length - 1,
                promo,
                price:     parsePrice(tour),
                operator:  operatorId,
                transport,
                currency,
                outbound:  cityFrom && deptFrom
                    ? {
                        city: cityFrom,
                        date: deptFrom,
                    } : null,
                inbound: cityPortName && deptTo
                    ? {
                        city: cityPortName,
                        date: deptTo,
                    }
                    : null,
            },
        },
    };
};
