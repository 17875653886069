import React, { useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import T from 'prop-types';
import classnames from 'classnames';

import { isMobile } from 'components/DeviceDetector/store/selectors';

import { generateAdditionalInformation } from './helpers';

import styles from './styles';

const AdditionalInformation = ({ cityID, offer, showText }) => {
    const mobile = useSelector(isMobile);

    const additionalInformation = useMemo(
        () => generateAdditionalInformation(offer, cityID),
        [offer, cityID]
    );

    return (
        <span
            className = { classnames(
                styles.icon,
                styles[additionalInformation.icon],
                { [styles.onlyCashback]: showText }
            )
            }>
            { mobile && !showText && (
                <span className = { styles.iconPlus }>+</span>
            ) }
            { showText && (
                <span className = { styles.iconTitle }>
                    { additionalInformation.text }
                </span>
            ) }
        </span>
    );
};

AdditionalInformation.propTypes = {
    cityID:   T.number.isRequired,
    offer:    T.object.isRequired,
    showText: T.bool.isRequired,
};

export default memo(AdditionalInformation);
