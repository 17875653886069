import React, { createContext, memo, useCallback, useContext, useEffect } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { useSwipeable } from 'react-swipeable';

import { useSlider } from 'components/ui/Slider/useSlider';
import { singleCarouselSliderMiddleware } from 'components/ui/Slider/middlewares';
import { useKeyboardArrows } from 'components/hotel/PhotoGallery/useKeyboard';

import { bootstrapDataForCarousel } from './store/actions';
import { isFetchedCarouselData } from './store/selectors';

let ctx = null;

const ContextComponent = ctx = createContext();

const Provider = ({ children, countSlides, initialActive, hotelID, keyboardActivated, prefetchPhotos }) => {
    const sliderAPI = useSlider({
        countSlides,
        initialActive,
        middleware: singleCarouselSliderMiddleware,
    });

    const isFetchedData = useSelector((state) => isFetchedCarouselData(state, { hotelID }));

    // methods
    const dispatch = useDispatch();
    const bootstrapData = useCallback(R.compose(
        dispatch,
        bootstrapDataForCarousel
    ), [dispatch]);
    const handleOnNext = useCallback(
        () => isFetchedData ? sliderAPI.onNext() : bootstrapData(hotelID, () => sliderAPI.setActiveSlide(1)),
        [isFetchedData, sliderAPI, bootstrapData]
    );

    const handlers = useSwipeable({
        onSwipedLeft:                 handleOnNext,
        onSwipedRight:                sliderAPI.onPrev,
        preventDefaultTouchmoveEvent: true,
        trackMouse:                   false,
    });

    const {
        leftCallbackRegistrator,
        rightCallbackRegistartor,
        resetCallbacks,
    } = useKeyboardArrows({
        activated: keyboardActivated,
        onLeft:    sliderAPI.onPrev,
        onRight:   handleOnNext,
    });

    const shared = {
        sliderAPI: {
            ...sliderAPI,
            onNext: handleOnNext,
        },
        DOMHandlers:                            handlers,
        leftKeyboardButtonCallbackRegistrator:  leftCallbackRegistrator,
        rightKeyboardButtonCallbackRegistartor: rightCallbackRegistartor,
        resetKeyboardButtonsCallbacks:          resetCallbacks,
    };

    useEffect(() => {
        if (prefetchPhotos) {
            bootstrapData(hotelID);
        }
    }, [prefetchPhotos]);

    return (
        <ContextComponent.Provider value = { shared }>
            { children }
        </ContextComponent.Provider>
    );
};

Provider.displayName = 'SliderResultProvider';
Provider.propTypes = {
    children:          T.any.isRequired,
    countSlides:       T.number.isRequired,
    hotelID:           T.string.isRequired,
    keyboardActivated: T.bool,
    prefetchPhotos:    T.bool,
};
Provider.defaultProps = {
    keyboardActivated: false,
};

export const SliderResultProvider = memo(Provider);
export const SliderResultConsumer = ContextComponent.Consumer;
export const useSliderCtx = () => useContext(ctx);
