import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { geoActions } from '@otpusk/apisearch-toolbox/dist/geo';
import { Map } from 'immutable';

export const useDepartureCity = ({ countryId, departureCityId }) => {
    const dispatch = useDispatch();
    const departureCities = useSelector(({ geo }) => geo.getIn(['departures', countryId]));
    const selectDepartureCity = useMemo(() => Map(departureCities && departureCities.find(({ id }) => Number(id) === Number(departureCityId))), [departureCities, departureCityId]);

    useEffect(() => {
        if (!departureCities && countryId) {
            dispatch(geoActions.getDepartureCities(countryId));
        }
    }, [countryId, departureCities]);

    return selectDepartureCity;
};


export const useDepartureCities = ({ countryId }) => {
    const dispatch = useDispatch();
    const departureCities = useSelector(({ geo }) => geo.getIn(['departures', countryId]));

    useEffect(() => {
        if (!departureCities && countryId) {
            dispatch(geoActions.getDepartureCities(countryId));
        }
    }, [countryId, departureCities]);

    return departureCities;
};
