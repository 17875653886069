import React, { useCallback, useState, memo } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import MapModal from 'components/hotel/MapModal';

import styles from './modal.scss';

const GeoLocation = ({ cityName, countryName, hotelName, hotelID }) => {
    const [showMap, setShowMap] = useState(false);
    const { t } = useTranslation('PRICE_MODAL_GALLERY');

    const handleClickByMapButton = useCallback(() => setShowMap(true), []);
    const handleCloseModal = useCallback(() => setShowMap(false), []);

    return (
        <>
            <div className = { styles.mapButton } onClick = { handleClickByMapButton }>
                <span className = { styles.pointer }>{ cityName }, { countryName }</span>
                <span className = { styles.pointer }>На карті</span>
            </div>
            { showMap && (
                <MapModal
                    hotelID = { hotelID }
                    title = { t('MAP_MODAL_TITLE', { hotelName }) }
                    onClose = { handleCloseModal }
                />
            ) }
        </>
    );
};

GeoLocation.propTypes = {
    cityName:    T.string.isRequired,
    countryName: T.string.isRequired,
    hotelID:     T.string.isRequired,
    hotelName:   T.string.isRequired,
};

export default memo(GeoLocation);
