import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getUI } from 'bus/ui/selectors';
import { getHotBlocksStore, getDefaultCountry, getCountry } from 'bus/hot/selectors';

import { UI_FLAG_HOT_TOURS } from './constants';

const DEFAULT_UI = {};

const getBlockId = (_, { blockID }) => blockID;

export const getUIHotTours = createSelector(
    (state) => getUI(state, { key: UI_FLAG_HOT_TOURS }),
    getBlockId,
    (ui, blockID) => ui.get(blockID, DEFAULT_UI)
);

const getHotToursByID = createSelector(
    getHotBlocksStore,
    getBlockId,
    (store, blockID) => store.get(blockID, null)
);

const getDefaultHotTours = createSelector(
    getHotBlocksStore,
    getDefaultCountry,
    (store, { hotBlockID: blockID }) => store.get(blockID, null)
);

export const getHotTours = createSelector(
    getHotToursByID,
    getDefaultHotTours,
    (hotBlock, defaultHotBlock) => hotBlock || defaultHotBlock
);

export const getCountryName = () => createSelector(
    getCountry(),
    getDefaultCountry,
    getHotToursByID,
    (country, defaultCountry, hotBlock) => R.prop(
        'name',
        hotBlock ? country : defaultCountry
    )
);
