import { takeEvery } from 'redux-saga/effects';

import getHoliday from './worker/getHoliday';
import getDictionary from './worker/getDictionary';

import actions from '../actions';

export default Object.freeze({
    * getHolidayWatcher () {
        yield takeEvery(actions.getHoliday, getHoliday);
    },
    * getDictionaryWatcher () {
        yield takeEvery(actions.getDictionary, getDictionary);
    },
});
