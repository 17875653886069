import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// Instruments
import history from './history';

// reducers
import { geoReducer as geo } from '@otpusk/apisearch-toolbox/dist/geo';
import { queriesReducer as queries } from '@otpusk/apisearch-toolbox/dist/queries';
import { servicesReducer as services } from '@otpusk/apisearch-toolbox/dist/services';
import { searchReducer as search } from '@otpusk/apisearch-toolbox/dist/search';
import { hotelsReducer as hotels } from '@otpusk/apisearch-toolbox/dist/hotels';
import { offersReducer as offers } from '@otpusk/apisearch-toolbox/dist/offers';
import { operatorsReducer as operators } from '@otpusk/apisearch-toolbox/dist/operators';
import currency from 'bus/currency/reducer';
import { reviewsReducer as reviews } from 'bus/reviews';
import { graphReducer as graph } from 'bus/graph';
import { hotReducer as hot } from 'bus/hot';
import { uiReducer as ui } from 'bus/ui';
import { bookingReducer as booking } from 'bus/booking';
import { weatherReducer as weather } from 'bus/weather';
import { commonReducer as common } from 'bus/common';
import auth from 'bus/auth/reducer';

import collections from 'components/controls/HotelsControl/components/Collection/store/reducer';
import geoIP from 'components/GeoIP/store/reducer';
import deviceDetector from 'components/DeviceDetector/store/reducer';
import bootstrap from 'components/bootstrap/store/reducer';
import hotelCarousel from 'components/results/Hotel/Carousel/store/reducer';
import prefetchNextPage from 'providers/PrefetchNextPage/store/reducer';

export const rootReducer = combineReducers({
    auth,
    router: connectRouter(history),
    geo,
    queries,
    services,
    search,
    hotels,
    offers,
    currency,
    reviews,
    graph,
    hot,
    ui,
    booking,
    weather,
    common,
    operators,
    collections,
    geoIP,
    deviceDetector,
    bootstrap,
    hotelCarousel,
    prefetchNextPage,
    // add other reducer
});
