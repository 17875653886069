import React, { memo, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';

import book from 'routes/book';
import history from 'init/history';

import Spinner from 'components/ui/Spinner';
import { OfferAndHotelProvider } from 'providers/OfferAndHotel';

export default memo(() => {
    const getParams = useSelector(({ router }) => router.location.search, shallowEqual);
    const [offerID, hotelID] = useMemo(() => {
        const query = new URLSearchParams(getParams);

        return [query.get('vid'), query.get('otp_ni')];
    }, [getParams]);

    return offerID && hotelID && (
        <>
            <Spinner />
            <OfferAndHotelProvider hotelId = { hotelID } offerId = { offerID }>
                { (offer, hotel) => {
                    if (offer && hotel) {
                        const link = history.createHref({
                            pathname: generatePath(book.tour, {
                                'countryName': hotel.country.code.toLowerCase(),
                                'hotelName':   hotel.code.toLowerCase(),
                                hotelID,
                                offerID,
                            }),
                        });
                        const glueLink = `${link}${getParams}`;

                        return <Redirect to = { glueLink } />;
                    }

                    return null;
                } }
            </OfferAndHotelProvider>
        </>
    );
});
