// Core
import React, { memo } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

// Instruments
import Styles from './styles.scss';

import { DIVIDERS_LIST } from 'widgets/hot/constants';

const PeopleSwitcher = ({ value, onChange }) => {
    const { t } = useTranslation('PEOPLE');

    return (
        <div className = { Styles.root }>
            { DIVIDERS_LIST.map((control) => (
                <label key = { control.divider }>
                    <input
                        checked = { R.equals(control.divider, value) }
                        name = 'people-switcher'
                        type = 'radio'
                        onChange = { R.compose(onChange, R.always(control.divider)) }
                    />
                    <span>{ t(control.text, { returnObjects: true })[control.adults - 1] }</span>
                </label>
            )) }
        </div>
    );
};

PeopleSwitcher.propTypes = {
    value:    T.number,
    onChange: T.func,
};

export default memo(PeopleSwitcher);
