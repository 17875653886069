// Core
import React, { memo, useEffect } from 'react';
import classnames from 'classnames';
import { useSwipeable } from 'react-swipeable';
import { useTranslation } from 'react-i18next';

import { useSlider } from 'components/ui/Slider/useSlider';
import { singleCarouselSliderMiddleware } from 'components/ui/Slider/middlewares';
import useSingleTransformCarouselStyles from 'components/ui/Slider/useSingleTransformCarouselStyles';

import Item from './Item';

import styles from './styles.scss';

const AUTO_PLAY_TIME_MS = 6000;


const ReviewsSlider = ({ compact, reviews }) => {
    const { t } = useTranslation('REVIEWS_CAROUSEL');
    const { activeSlide, onNext, onPrev } = useSlider({
        countSlides: reviews.length,
        middleware:  singleCarouselSliderMiddleware,
    });

    const handlers = useSwipeable({
        onSwipedLeft:                 onNext,
        onSwipedRight:                onPrev,
        preventDefaultTouchmoveEvent: true,
        trackMouse:                   false,
    });

    const transformStyles = useSingleTransformCarouselStyles(activeSlide);

    useEffect(() => {
        const timerID = setInterval(
            onNext,
            AUTO_PLAY_TIME_MS
        );

        return () => clearInterval(timerID);
    }, [onNext]);

    return (
        <div className = { classnames(styles.root, { [styles.mobileVersion]: compact }) }>
            <div className = { styles.reviewTitle }>{ t('TITLE') }</div>
            <div className = { styles.controls }>
                <div className = { classnames(styles.control, styles.prev) } onClick = { onPrev } />
                <div className = { classnames(styles.control, styles.next) } onClick = { onNext } />
            </div>
            <div className = { styles.sliderContainer } { ...handlers }>
                <div className = { styles.slider }>
                    <div className = { styles.slides } style = { transformStyles }>
                        { reviews.map((review, number) => (
                            <Item
                                active = { number === activeSlide }
                                key = { number }
                                { ...review }
                            />
                        )) }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(ReviewsSlider);
