import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getUI } from '../ui/selectors';
import { COMMON_REVIEWS_UI_FLAG, EMPTY_OBJ } from './constants';
import { createHotelUIKey, createTripadvisorUIKey } from './helpers';

const domain = (_) => _.reviews;
const getHotelID = (_, { hotelID }) => hotelID;

export const getCommonReviews = createSelector(
    domain, (reviews) => reviews.common
);

export const getReviews = createSelector(
    domain,
    getHotelID,
    ({ byHotelsTurpravda }, hotelID) => byHotelsTurpravda[hotelID]
);

const getTripadvisorEntity = createSelector(
    domain,
    getHotelID,
    ({ byHotelsTripadvisor }, hotelID) => R.propOr(EMPTY_OBJ, hotelID, byHotelsTripadvisor)
);

export const isSetTripadvisorEntity = createSelector(
    getTripadvisorEntity,
    (entity) => !R.isEmpty(entity)
);

export const getTripadvisorEntityDescription = createSelector(
    getTripadvisorEntity,
    R.prop('description')
);

export const getTripadvisorEntityReviews = createSelector(
    getTripadvisorEntity,
    R.prop('reviews')
);

export const getUICommonReviews = R.partialRight(
    getUI, [{ key: COMMON_REVIEWS_UI_FLAG }]
);

export const getUIReviews = (state, { hotelID }) => getUI(state, { key: createHotelUIKey(hotelID) });

export const getUITripadvisorReviews = (state, { hotelID }) => getUI(state, { key: createTripadvisorUIKey(hotelID) });
