import React, { memo } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import styles from './styles.scss';

const Food = ({ code, resultVersion }) => {
    const { t } = useTranslation(['PRICES_LIST', 'FOODS']);

    return (
        <span className = { classnames(styles.foodWrapper, { [styles.resultVersion]: resultVersion }) }>
            <span className = { styles.food }>
                <span className = { styles.title }>
                    { t('FOOD_LABEL') }
                </span>
                <span className = { styles.mobile }>
                    <span className = { styles.foodCode }>{ code }</span>
                    {' '}
                    -
                </span>
                {' '}
                { t(code.toUpperCase()) }
            </span>
        </span>
    );
};

Food.propTypes = {
    code:          T.string.isRequired,
    resultVersion: T.bool,
};

export default memo(Food);
