export default Object.freeze({
    main:           '/',
    tourByCountry:  '/categorytour/:country',
    tourByOtpusk:   '/tour',
    hot:            '/palayuchi-putivki',
    marketingPages: {
        bestTours:   '/besttours',
        niceHotels:  '/nicehotels',
        newyear:     '/newyear',
        blackfriday: '/blackfriday',
        stnicholas:  '/stnicholas',
        exotics:     '/exotics',
    },
    get tour () {
        return `/tour/:countryName/:hotelName/:hotelID/:offerID`;
    },
    get hotTour () {
        return `${this.hot}/hot/tour/:countryName/:hotelName/:hotelID/:offerID`;
    },
    about: '/pro-nas/',
});
