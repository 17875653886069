import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getResultVisible, getActiveSearchPage } from 'bus/ui/selectors';
import { uiActions } from 'bus/ui/actions';

const { setUiState, removeUiState } = uiActions;

const STATIC_VISIBLE_COUNT = 6;

export const useResultsVisible = (queryID) => {
    const isVisible = useSelector((state) => getResultVisible(state, { key: queryID }));
    const activePage = useSelector((state) => getActiveSearchPage(state, { key: queryID }));

    const dispatch = useDispatch();

    const setShow = useCallback(
        () => dispatch(setUiState(['resultsVisible', queryID], true)),
        [dispatch, queryID]
    );
    const removeShow = useCallback(
        () => dispatch(removeUiState(['resultsVisible', queryID])),
        [dispatch, queryID]
    );

    const setActivePage = useCallback(
        (next) => dispatch(setUiState(['activeSearchPage', queryID], next)),
        [dispatch, queryID]
    );

    return {
        isVisible,
        setShow,
        removeShow,
        STATIC_VISIBLE_COUNT,

        activePage,
        setActivePage,
    };
};
