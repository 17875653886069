import * as R from 'ramda';

export const createHotelUIKey = (hotelID) => `REVIEWS_${hotelID}`;
export const createTripadvisorUIKey = (hotelID) => `REVIEWS_TRIPADVISOR_${hotelID}`;

export const normalizeDescription = (string) => R.call(
    R.pipe(
        R.replace(/\r?\n|\r/g, " "),
        R.trim
    ),
    string
);
