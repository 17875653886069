import { call, put } from 'redux-saga/effects';
import * as R from 'ramda';
import { v4 as uuid } from 'uuid';

import { uiActions } from 'bus/ui';

import { reviewsActions as actions } from '../../actions';
import { COMMON_REVIEWS_UI_FLAG } from '../../constants';

const createLoadingAction = (overrides) => uiActions.setUiState(
    COMMON_REVIEWS_UI_FLAG,
    { loading: false, error: false, completed: false, message: null, ...overrides }
);

const reviewMapper = ({ name, photo, ...review }) => R.mergeAll([
    { author: name, img: photo, id: uuid() }, review
]);

const normalizeData = (data) => R.call(
    R.map(reviewMapper),
    data
);

export default function* () {
    yield put(createLoadingAction({ loading: true }));

    try {
        const response = yield call(fetch, '/wp-json/rest_api/v1/reviews');

        if (response.status !== 200) {
            const error = yield call([response, 'json']);

            throw new Error(error.message || 'Помилка сервера');
        }

        const data = yield call([response, 'json']);

        yield put(actions.getCommonReviewsSuccess(normalizeData(data)));
        yield put(createLoadingAction({ loading: false, completed: true }));
    } catch (error) {
        yield put(actions.getCommonReviewsFail(error));
        yield put(createLoadingAction({ loading: false, completed: true, error: true, message: error.message }));
    }
}
