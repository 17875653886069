import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIn, List } from 'immutable';
import * as R from 'ramda';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';

import { ShowMorePure } from 'components/results/ShowMore';
import { SwitcherComplexity } from 'components/ui/Switch';
import { useDepartureCities } from 'containers/molecules/City/SelectDepartureCity';
import { useSelectServices } from 'containers/molecules/Services/SelectServices';
import { OfferAndHotelProviderHot } from 'providers/OfferAndHotelHot';
import { withDiscount } from 'providers/Discount';
import { OperatorUploader } from 'providers/Operators';

import { HotTour as HotTourPure } from './HotTour';

import { STATIC_SHORT_SHOW } from './constants';

import { getUIHotTours, getHotTours } from './store/selectors';
import { bootstrapData } from './store/actions';

import styles from './styles';

const HotTour = withDiscount(HotTourPure);

const isRenderShowMoreButton = (showMore) => R.complement(R.includes)(
    showMore,
    [null, true]
);

const HotList = (props) => {
    const {
        data_hot_tour_block_id: blockID,
        divider,
        showMore: initialShowMore,
    } = props;

    const hotTours = useSelector((state) => getHotTours(state, { blockID }));
    const ui = useSelector((state) => getUIHotTours(state, { blockID }));
    const dispatch = useDispatch();
    const [showMore, setShowMore] = useState(initialShowMore);

    const { t } = useTranslation('PRICES_LIST');

    useDepartureCities({ countryId: '0' });
    const { services, labels } = useSelectServices();
    const badgeList = useMemo(() => {
        if (!services.isEmpty() && services.get('recommend')) {
            return services.get('recommend').map((service) => ({ service, label: labels.get(service, null) }));
        }

        return null;
    }, [services.get('recommend'), labels]);
    const tours2Render = useMemo(() => {
        if (hotTours) {
            return hotTours
                .toList()
                .sort((tourA, tourB) => getIn(tourA, ['tour', 'offer', 'price', 'uah'], 0) - getIn(tourB, ['tour', 'offer', 'price', 'uah'], 0))
                .take(isRenderShowMoreButton(showMore) ? STATIC_SHORT_SHOW : Infinity)
                .map((tour) => R.pipe(
                    R.over(
                        R.lensPath(['tour', 'offer', 'price']),
                        R.pipe(
                            R.toPairs,
                            R.map(([currency, price]) => [currency, price / divider.divider]),
                            R.fromPairs
                        )
                    ),
                    R.set(
                        R.lensPath(['tour', 'offer', 'divider']),
                        divider.divider
                    )
                )(tour));
        }

        return List();
    }, [showMore, hotTours, divider]);

    const countriesIDsByTours = useMemo(
        () => R.call(
            R.when(
                Boolean,
                R.pipe(
                    (map) => map.toList().toArray(),
                    R.map(({ tour }) => tour.country.id),
                    R.uniq,
                    R.map(Number)
                )
            ),
            hotTours
        ),
        [hotTours]
    );

    useEffect(() => {
        dispatch(bootstrapData(blockID));
    }, [blockID]);

    return (
        <>
            <div className = { cx(styles.root, ui.loading && styles.loading) }>
                { !tours2Render.isEmpty() && (
                    <>
                        <div className = { styles.switcher }>
                            <SwitcherComplexity />
                        </div>
                        <div className = { styles.container }>
                            { tours2Render.map((tour) => (
                                <OfferAndHotelProviderHot item = { tour } key = { tour.hotelId } >
                                    { (offer, hotel) => offer && hotel && ui.completed && (
                                        <HotTour
                                            badgeList = { badgeList }
                                            hotel = { hotel }
                                            offer = { offer }
                                        />
                                    ) }
                                </OfferAndHotelProviderHot>
                            )) }
                        </div>
                        { isRenderShowMoreButton(showMore) && (
                            <ShowMorePure onClick = { () => setShowMore(true) }>
                                {t('SHOW_MORE_COLLAPSE_BUTTON')}
                            </ShowMorePure>
                        ) }
                    </>
                ) }
            </div>
            { countriesIDsByTours && R.map(
                (id) => <OperatorUploader countryID = { id } key = { id } />,
                countriesIDsByTours
            ) }
        </>
    );
};

HotList.propTypes = {
    'data_hot_tour_block_id': T.string.isRequired,
    divider:                  T.shape({ divider: T.number.isRequired }),
    showMore:                 T.bool,
};
HotList.defaultProps = {
    showMore: null,
};

export default memo(HotList);
