import { all, call, race, take, put, select } from 'redux-saga/effects';
import * as R from 'ramda';
import { geoActions } from '@otpusk/apisearch-toolbox/dist/geo';
import { cacheValidate } from '@otpusk/json-api';
import moment from 'moment';

import { getAPIToken } from 'bus/auth/selectors';
import { setLang } from 'bus/auth/actions';
import { hotActions } from 'bus/hot/actions';
import detectDevice from 'components/DeviceDetector/store/saga/detectDevice';
import { EMPTY_COUNTRY_ID } from 'bus/hot/constants';

import getUserLocation from 'components/GeoIP/store/saga';

import { bootstrap } from 'localization';
import { computeLocale, convertLocaleToOtpuskLang } from 'localization/helpers';

import commonActions from 'bus/common/actions';
import { completedInitializeApp, initializeApp } from '../actions';

const createSetLangPayload = (locale) => R.call(
    R.pipe(convertLocaleToOtpuskLang, setLang),
    locale
);

export default function* () {
    yield take(initializeApp);
    const token = yield select(getAPIToken);
    const locale = computeLocale();

    try {
        moment.locale(locale);

        yield all([
            call(cacheValidate, token),
            call(bootstrap, locale),
            call(detectDevice),
            __PROD__ && call(getUserLocation)
        ]);

        yield put(createSetLangPayload(locale));
        yield put(commonActions.getDictionary());
        yield put(geoActions.getCountries({ with: 'price' }));
        yield put(hotActions.getHotCountries(EMPTY_COUNTRY_ID));

        yield all([
            race([
                take(geoActions.getCountriesSuccess),
                take(geoActions.getCountriesFail)
            ]),
            race([
                take(commonActions.setDictionary),
                take(commonActions.failFetchDictionary)
            ]),
            race([
                take(hotActions.setHotCountries),
                take(hotActions.failFetchHotCountries)
            ])
        ]);

        yield put(completedInitializeApp());
    } catch (error) {
        console.log(error);
    }
}
