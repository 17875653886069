import React, { memo } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Rating from './Rating';

import styles from '../styles/header.scss';

const printRate = (rate) => rate.value.toFixed(1).replace('.', ',');

const Header = ({ name, rate }) => {
    const { t } = useTranslation('TRIPADVISOR_REVIEWS');

    return (
        <div className = { styles.header }>
            <div className = { styles.headerBody }>
                <div className = { classnames(styles.headerRow, styles.headerTitle) }>
                    { t('TITLE_REVIEWS') } <div className = { styles.headerLogo } />
                </div>
                <div className = { styles.headerTitle }>
                    { name }
                </div>
                <div className = { styles.headerRating }>
                    <div className = { styles.headerRatingValue }>
                        { printRate(rate) }
                    </div>
                    <div className = { styles.headerRatingInfo }>
                        <div className = { styles.headerRatingDesc }>
                            { rate.label }
                        </div>
                        { <Rating value = { rate.value } /> }
                    </div>
                </div>
            </div>
        </div>
    );
};

Header.propTypes = {
    name: T.string.isRequired,
    rate: T.shape({
        value: T.number.isRequired,
        label: T.string.isRequired,
    }).isRequired,
};

export default memo(Header);
