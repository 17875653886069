import * as R from 'ramda';
import { useMemo } from 'react';

import { DEFAULT_CURRENCY } from 'bus/currency/constants';

import useDiscountState from 'components/controls/DiscountControl/useDiscountState';
import useActiveCurrency from 'providers/Currency/useActiveCurrency';

import { getPrice, getDiscountPrice, getFallbackPrice, getDiscountPriceForJoinUp } from './helpers';

export default (offer) => {
    const isAllowDiscount = useDiscountState();
    const activeCurrency = useActiveCurrency();

    const price = useMemo(
        () => R.call(
            R.pipe(
                R.find(Boolean),
                (value) => value[activeCurrency] || value[offer.currency] || value[DEFAULT_CURRENCY],
                Math.ceil
            ),
            [
                isAllowDiscount && getDiscountPrice(offer),
                isAllowDiscount && getDiscountPriceForJoinUp(offer),
                getPrice(offer),
                getFallbackPrice(offer)
            ]
        ),
        [activeCurrency, isAllowDiscount, offer]
    );

    return price;
};
