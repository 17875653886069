// Core
import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import actions from './actions';

const initialStore = {
    holiday:    undefined,
    dictionary: {},
};

export default handleActions({
    [actions.getHolidaySuccess]: (state, { payload: holiday }) => R.mergeAll([
        state, { holiday }
    ]),
    [actions.setDictionary]: (state, { payload: dictionary }) => R.mergeAll([
        state, { dictionary }
    ]),
}, initialStore);
