// Core
import { call, put } from 'redux-saga/effects';

import commonActions from '../../actions';

export default function* () {
    try {
        const response = yield call(fetch, '/wp-json/rest_api/v1/holiday');
        const holiday = yield call([response, 'text']);

        yield put(commonActions.getHolidaySuccess(holiday));
    } catch (error) {
        yield put(commonActions.getHolidayFail(error));
    }
}
