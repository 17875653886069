import { handleActions } from 'redux-actions';
import { Map } from 'immutable';

// actions
import { bookingActions as actions } from './actions';

const inititalState = Map({
    contracts: {},
});

export const bookingReducer = handleActions({
    [actions.getOperatorContractSuccess]: (state, { payload }) => {
        const { type, id, contract, link } = payload;

        return state.setIn(['contracts', type, id], { contract, link });
    },
}, inititalState);
