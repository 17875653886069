import { call, put, select } from 'redux-saga/effects';
import * as R from 'ramda';

import { getLang } from 'bus/auth/selectors';

import { setCollections } from '../../actions';

const STATIC_HEADERS = {
    "accept":    "application/json",
    "X-API-KEY": " 4fcf7be10dfb0c1ce6f55ca2054e6e5c6397ff52",
    "X-USER-ID": "antoniv",
};

const COLLECTION_SERVICE_API_HOST = 'https://collections.otpusk.com/api';

const sortableCollections = (collections) => R.call(
    R.sort(R.ascend(R.prop('order_index'))),
    collections
);

export default function* ({ payload: countryID }) {
    const lang = yield select(getLang);

    try {
        const responce = yield call(
            fetch,
            `${COLLECTION_SERVICE_API_HOST}/collections?country=${countryID}&locale=${lang}`,
            {
                headers: STATIC_HEADERS,
            }
        );
        const collections = yield call([responce, 'json']);

        yield put(setCollections(countryID, sortableCollections(collections)));

    } catch (error) {
        console.log(error);
    }
}
