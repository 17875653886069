import { createSelector } from 'reselect';
import * as R from 'ramda';


const domain = (_) => _.common;

export const getHoliday = createSelector(
    domain,
    R.prop('holiday')
);

const getDictionary = createSelector(
    domain, R.prop('dictionary')
);

export const getRooms = createSelector(
    getDictionary, R.prop('rooms')
);
