import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { Map } from 'immutable';
import * as R from 'ramda';

import { getCountryByBlockID } from 'bus/hot/selectors';

import HotList from './HotList';
import HotListTitle from './HotListTitle';

import book from '../../routes/book';

import { DIVIDERS, getDivider } from './constants';

const DESCRIPTION_WIDGET = {
    'WIDGET_ID': 'data-antoniv-widget-hot',
    attributes:  {
        required: ['data-hot-tour-block-id'],
        options:  [],
    },
};

const HotWidgets = () => {
    const { current: DOMElements } = useRef(Array.from(document.querySelectorAll(`[${DESCRIPTION_WIDGET.WIDGET_ID}]`)));

    const isTourByCountry = useRouteMatch(book.tourByCountry, { exact: true });
    const showMore = isTourByCountry ? false : void 0;

    return Boolean(DOMElements.length) && DOMElements.map(($el, idx) => {
        const [priceDivider, setPriceDivider] = useState(DIVIDERS.ONE.divider);

        const attrList = Array.from($el.attributes)
            .filter((attr) => DESCRIPTION_WIDGET.attributes.required.includes(attr.name) || DESCRIPTION_WIDGET.attributes.required.includes(attr.name))
            .map((attr) => ({ name: attr.name, value: attr.value }));

        const props = attrList.length && Map({ ...attrList }).mapEntries(([, attr]) => [attr.name.replace(/-/g, '_'), attr.value]).toObject();
        const blockID = props.data_hot_tour_block_id;

        const getCountrySelector = useMemo(
            () => R.partialRight(getCountryByBlockID(), [{ blockID }]),
            [blockID]
        );
        const country = useSelector(getCountrySelector);

        return createPortal(
            <>
                { country && (
                    <HotListTitle
                        blockID = { blockID }
                        countryID = { country.otpuskID }
                        priceDivider = { priceDivider }
                        onChangePriceDivider = { setPriceDivider }
                    />
                ) }
                <HotList
                    divider = { getDivider(priceDivider) }
                    key = { idx }
                    showMore = { showMore } { ...props }
                />
            </>,
            $el
        );
    });
};

export default HotWidgets;
