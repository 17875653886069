import React, { memo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './styles.scss';

const Copy = ({ text, onClick }) => {
    const { t } = useTranslation('PRICES_LIST');

    return (
        <>
            <span
                className = { styles.copyWrapper }
                title = { t('COPY_LABEL') }
                onClick = { onClick }>
                <CopyToClipboard text = { text }>
                    <span className = { styles.copyTitle } />
                </CopyToClipboard>
            </span>
            <i />
        </>
    );
};

Copy.propTypes = {
    text:    T.string.isRequired,
    onClick: T.func,
};

export default memo(Copy);
