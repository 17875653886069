import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getPrice } from 'providers/Price/helpers';
import translit from 'helpers/transliteration';

import { isMobile } from 'components/DeviceDetector/store/selectors';
import HotelToursContainer from 'containers/results/HotelTours';
import { MediaQuery } from 'containers/media';
import LocaleState from 'containers/LocalState';

import { Badge } from 'components/ui/Badge';
import HotelTours from 'components/results/HotelTours';
import HotelCarouselPhotos from './Carousel';
import Rating from './Rating';
import Name from './Name';
import Geo from './Geo';
import Food from './Food';
import Room from './Room';
import Ensurance from './Ensurance';
import Operator from './Operator';
import { FlightOffer, FlightHotOffer } from './Flight';
import Price from './Price';
import AdditionalInformation from './AdditionalInformation';

import useDiscountState from 'components/controls/DiscountControl/useDiscountState';
import { usePriceByOffer } from 'providers/Price';
import LocalState from 'containers/LocalState';

import { SMALL_PHOTO, MEDIUM_PHOTO } from './Carousel/constants';
import Styles from './styles.scss';
import ratingStyles from './Rating/styles.scss';
import { createQQPayload } from './helpers';
import { ANIMATION } from './Carousel/constants';

const isFortuna = (hotelCode) => hotelCode.includes('Fortuna');

const preventClick = (e) => e.preventDefault();

export const Hotel = memo((props) => {
    const { t } = useTranslation(['PRICES_LIST', 'CURRENCIES', 'PEOPLE']);

    const mobile = useSelector(isMobile);

    const { discount, maxPrice, activeCurrencyCode, badges, number, hotel, offer, isSingleResult, isVisiblePricesTable, toggleHotelToursVisible, queryID } = props;
    const { discountPrice } = offer;
    const departureCountry = Map(props.departureCountry);
    const people = offer.children + offer.adults;
    const isAllowDiscount = useDiscountState();
    const price = usePriceByOffer(offer);
    const currencyLabel = t(activeCurrencyCode.toUpperCase());
    const QQ_PAYLOAD = useMemo(() => createQQPayload(offer, hotel, activeCurrencyCode, props.linkToTour, departureCountry.get('name'), price), [props]);

    const isUahPrice = activeCurrencyCode === 'uah';
    const enabledSingleSlider = (isSingleResult && isVisiblePricesTable || isVisiblePricesTable);

    return (
        <div
            className = { cx(Styles.root, {
                [Styles.opened]:      Boolean(isVisiblePricesTable),
                [Styles.toursOpened]: Boolean(isVisiblePricesTable),
            }) }
            data-number = { number }
            style = { { order: number%2 ? number - (isVisiblePricesTable ? 2 : 0) : number } }>
            <div className = 'qq-btn-place' data-value = { QQ_PAYLOAD } />

            <LocaleState initialState = { false }>
                { (openedGalleryModal, setOpenGalleryModal) => (
                    <div className = { cx(Styles.hotel, { [Styles.fortuna]: isFortuna(hotel.code), [Styles.hasRating]: hotel.rating >= 7.2 }) }>
                        <span className = { Styles.badgesContainer } data-num = { badges && badges.size }>
                            { badges && badges.map(({ service, label }, idx) => <Badge key = { idx } label = { label } service = { service } />) }
                        </span>
                        <span className = { Styles.sliderRating }>
                            <HotelCarouselPhotos
                                controls
                                enabledModal
                                enabledThumbs
                                isHideControl
                                animation = { ANIMATION.SLIDE }
                                hotelID = { hotel.id }
                                items = { hotel.photos }
                                modal = { {
                                    hotel,
                                    linkToTour:         props.linkToTour,
                                    linkToTourWithRate: props.linkToTourWithRate,
                                } }
                                openedGalleryModal = { openedGalleryModal }
                                photoSize = { SMALL_PHOTO }
                                onChangeOpenGalleryModal = { setOpenGalleryModal }
                            />
                            <div className = { Styles.nameWrapperBg } />
                            <div className = { Styles.nameWrapper }>
                                <div className = { ratingStyles.servicesRating }>
                                    { Boolean(hotel.rating) && (
                                        <Rating
                                            rating = { hotel.rating }
                                            onClick = { () => window.open(props.linkToTourWithRate, '_blank') }
                                        />
                                    ) }
                                </div>
                                <Name
                                    name = { isFortuna(hotel.code) ? translit(hotel.name) : hotel.name }
                                    stars = { hotel.stars }>
                                    <Operator
                                        resultVersion
                                        countryID = { Number(hotel.country.id) }
                                        id = { offer.operator }
                                    />
                                </Name>
                            </div>
                        </span>
                        <a
                            className = { Styles.body } href = { props.linkToTour }
                            rel = 'noopener noreferrer' target = '_blank'>
                            <Geo
                                resultVersion
                                city = { hotel.city }
                                country = { hotel.country.name }
                                countryCode = { props.country.code }
                                duration = { offer.nights }
                                hotelID = { hotel.id }
                                hotelLocation = { hotel.location }
                                hotelName = { hotel.name }
                            />
                            {
                                props.hot
                                    ? offer.flights.inbound && offer.flights.outbound && (
                                        <FlightHotOffer
                                            resultVersion
                                            inbound = { offer.flights.inbound }
                                            outbound = { offer.flights.outbound }
                                            transport = { offer.transport }
                                        />
                                    )
                                    : <FlightOffer
                                        resultVersion
                                        offer = { offer }
                                    />
                            }
                            { offer.room && (
                                <Room
                                    resultVersion
                                    name = { offer.room.name }
                                />
                            ) }
                            <Food
                                resultVersion
                                code = { offer.food }
                            />
                            <Ensurance resultVersion />
                            <AdditionalInformation
                                cityID = { hotel.city.id }
                                offer = { offer }
                                showText = {
                                    !mobile || (
                                        props.hot
                                            ? false
                                            : (!(discountPrice && isAllowDiscount))
                                    )
                                }
                            />
                            <span className = { Styles.buttons }>
                                <span className = { cx(Styles.buttonWrapper, Styles.aboutButton) } onClick = { preventClick }>
                                    <span className = { Styles.button } onClick = { () => setOpenGalleryModal(true) }>
                                        { t('ABOUT_HOTEL_TAB') }
                                    </span>
                                </span>
                                <span className = { cx(Styles.buttonWrapper, Styles.pricesButton) } onClick = { toggleHotelToursVisible && preventClick }>
                                    <span className = { Styles.button } onClick = { toggleHotelToursVisible }>
                                        { t('OTHER_HOTEL_PRICES_TAB') }
                                        <span className = { Styles.peopleDesc }>
                                            { t('BY_COUNT', { returnObjects: true })[people-1] }
                                        </span>
                                    </span>
                                </span>
                                <span className = { Styles.buttonWrapper }>
                                    <Price
                                        activeCurrencyCode = { activeCurrencyCode }
                                        currencyLabel = { currencyLabel }
                                        discount = { discount }
                                        discountPrice = { discountPrice }
                                        isAllowDiscount = { isAllowDiscount }
                                        isUahPrice = { isUahPrice }
                                        maxPrice = { maxPrice }
                                        offer = { offer }
                                        originalPrice = { getPrice(offer) }
                                        price = { price }
                                    />
                                </span>
                            </span>
                        </a>
                    </div>
                ) }
            </LocaleState>

            <MediaQuery mediaQueryString = '(min-width: 319px)'>
                { ({ visible }) => visible && (
                    <div className = { Styles.extraContainer }>
                        { toggleHotelToursVisible && (
                            <HotelToursContainer hotelID = { hotel.id } queryID = { queryID }>
                                { (data) => isVisiblePricesTable && (
                                    <HotelTours
                                        items = { data }
                                        searchCurrency = { activeCurrencyCode }
                                    />
                                ) }
                            </HotelToursContainer>
                        ) }
                        { enabledSingleSlider && (
                            <LocalState initialState = { false }>
                                { (openedGalleryModal, setOpenGalleryModal) => (
                                    <div className = { Styles.soloImage }>
                                        <HotelCarouselPhotos
                                            enabledModal
                                            enabledThumbs
                                            keyboardActivated
                                            prefetchPhotos
                                            showYouTubeSlide
                                            animation = { ANIMATION.FADE }
                                            controls = { !isFortuna(hotel.code) }
                                            hotelID = { hotel.id }
                                            initialActive = { hotel.photos.length > 1 ? 1 : 0 }
                                            items = { hotel.photos }
                                            modal = { { hotel, linkToTour: props.linkToTour } }
                                            openedGalleryModal = { openedGalleryModal }
                                            photoSize = { MEDIUM_PHOTO }
                                            onChangeOpenGalleryModal = { setOpenGalleryModal }
                                        />
                                    </div>
                                ) }
                            </LocalState>
                        ) }
                    </div>
                ) }
            </MediaQuery>
        </div>
    );
});

Hotel.displayName = 'Hotel';
