import * as R from 'ramda';

export const showAllHiddenElements = () => R.call(
    R.when(
        Boolean,
        R.pipe(Array.from, R.forEach((node) => node.classList.remove('hidden')))
    ),
    document.querySelectorAll('.hidden')
);

export const hideLoaderShapes = () => R.call(
    R.when(
        Boolean,
        R.pipe(Array.from, R.forEach((node) => node.classList.add('hidden')))
    ),
    document.querySelectorAll('.loader-shape')
);
