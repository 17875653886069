import { useMemo } from 'react';
import * as R from 'ramda';

const computeInlineStylesForCarousel = (activeSlide) => -activeSlide * 100;

const createTransformCarouselStyles = (percent) => ({
    transform: `translateX(${percent}%)`,
});

const useSingleTransformCarouselStyles = (activeSlide) => {
    const transformStyles = useMemo(
        () => R.call(
            R.pipe(computeInlineStylesForCarousel, createTransformCarouselStyles),
            activeSlide
        ),
        [activeSlide]
    );

    return transformStyles;
};

export default useSingleTransformCarouselStyles;
