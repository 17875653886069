export const DIVIDERS = {
    'ONE': {
        text:    'BY_COUNT',
        divider: 2,
        adults:  1,
    },
    'TWO': {
        text:    'BY_COUNT',
        divider: 1,
        adults:  2,
    },
};

export const DIVIDERS_LIST = Object.values(DIVIDERS);

export const getDivider = (divider) => DIVIDERS_LIST.find((item) => item.divider === divider);

export const STATIC_SHORT_SHOW = 4;

export const HOT_BLOCKS_DEFAULT = {
    'besttours':         '20491',
    'palayuchi-putivki': '8311',
    'nicehotels':        '21300',
    'newyear':           '21302',
    'blackfriday':       '21303',
    'stnicholas':        '21305',
    'exotics':           '21332',
};
