import { Map } from 'immutable';

import { UK } from 'localization/constants';

export const FOOD = Object.freeze({
    ob:  'Без Харчування',
    bb:  'Сніданок',
    hb:  'Сніданок і Вечеря',
    fb:  'Триразове Харчування',
    ai:  'Все Включено',
    uai: 'Ультра Все Включено',
});

export const PEOPLE_COUNT = Object.freeze({
    1:  'за одного',
    2:  'за двох',
    3:  'за трьох',
    4:  'за чотирьох',
    5:  'за п\'ятьох',
    6:  'за шістьох',
    7:  'за сімох',
    8:  'за вісьмох',
    9:  'за дев\'ятьох',
    10: 'за десятьох',
    11: 'за одинадцять',
});

export const GEO_CONTROL_TYPE_VISIBLE = Object.freeze({
    DEFAULT: 'default',
    SUGGEST: 'suggest',
});

export const MAP_TYPE_SUGGESTS = Object.freeze({
    country: 'Країни',
    city:    'Курорти',
    hotel:   'Готелі',
});

export const DEFAULT_DEPARTURE_LVOV = '1397';
export const DEPATURE_KIEV_ID = '1544';

export const SEX = {
    'MALE':   { value: 'male', humanizeShort: 'ч', humanizeLong: 'чоловічий' },
    'FEMALE': { value: 'female', humanizeShort: 'ж', humanizeLong: 'жіночий' },
};

export const PAYMENT_TYPES = {
    PARTIAL:        { value: 'partial', text: 'PARTIAL_PAY', percent: 15, minPrice: 3000 },
    PARTIAL_40:     { value: 'partial_40', text: 'PARTIAL_PAY', percent: 40, minPrice: 3000 },
    PARTIAL_CUSTOM: { value: 'partial_custom', text: 'PARTIAL_CUSTOM_PAY', minPrice: 300 },
    FULL:           { value: 'full', text: 'FULL_PAY' },
    CASH:           { value: 'cash', text: 'CASH_PAY' },
    SKIP:           { value: 'skip', text: 'SKIP_PAY' },
};

export const AIR_NAMES = {
    'azur air ukraine new':                 'AzurAir',
    'azur air ukraine':                     'AzurAir',
    'azurair ukraine':                      'AzurAir',
    'azurair-ukraine':                      'AzurAir',
    'azur air':                             'AzurAir',
    'aerosur paraguay':                     'aerosur paraguay',
    'bravo airways':                        'Браво',
    'bukovyna airlines':                    'Буковина',
    'emirates':                             'Емірейтс',
    'flydubai':                             'FlyDubai',
    'fly dubai':                            'FlyDubai',
    'jonika llc':                           'Джоніка',
    'pegasus airlines':                     'Пегасус',
    'wizz':                                 'ВізЕйр',
    'wizzair':                              'ВізЕйр',
    'wizz air':                             'ВізЕйр',
    'ryanair':                              'РайанЕйр',
    'windrose':                             'WindRose',
    'wind rose':                            'WindRose',
    'windrose airlines':                    'WindRose',
    'роза ветров':                          'WindRose',
    'qatar':                                'QatarAirways',
    'qatar airways':                        'QatarAirways',
    'yanair':                               'ЯнЕйр',
    'lot':                                  'ЛОТ',
    'lot-polish airlines':                  'ЛОТ',
    'mau':                                  'Мау',
    'may':                                  'Мау',
    'maу':                                  'Мау',
    'мау':                                  'Мау',
    'ukraine internationa':                 'Мау',
    'ukraine international airlines':       'Мау',
    'ukraine intl airlines':                'Мау',
    'мау - ukraine international airlines': 'Мау',
    'международные авиалинии украины':      'Мау',
    'bees airline':                         'Bees',
    'skyup':                                'SkyUp',
    'skyup airlines':                       'SkyUp',
    'turkish airlines':                     'TurkishAirlines',
    'air arabia pjsc sharjah':              'AirArabia',
};

export const PORT_NAMES = {
    'Шарм-ель-Шейх':             'Шарм',
    'Умм Аль-Кувейн':            'Умм Аль-К',
    'Рас Аль-Хайма':             'Рас Аль-Х',
    'Пуерто Плата':              'Пуерто П',
    'Санто-Домінго':             'Санто-Д',
    'Арі Атол':                  'Арі',
    'Північний Мале Атол':       'Півн. Мале',
    'Лхавіяні Атол':             'Лхавіяні',
    'Баа Атол':                  'Баа',
    'Мулаку (Міму) Атол':        'Мулаку',
    'Даалу Атол':                'Даалу',
    'Адду Атол':                 'Адду',
    'Хаа Аліфу Атол':            'Хаа Аліфу',
    'Фаафі Атол':                'Фаафі',
    'Раа Атол':                  'Раа',
    'Південний Мале Атол':       'Півд. Мале',
    'Нуну Атол':                 'Нуну',
    'Шавіані Атол':              'Шавіані',
    'Лааму Атол':                'Лааму',
    'Мале':                      'Мале',
    'Гаафу Аліфу Атол':          'Гаафу Аліфу',
    'Вааву Атол':                'Вааву',
    'Гаафу Даалу Атол':          'Гаафу Даалу',
    'Тхаа Атол':                 'Тхаа',
    'Плайя дель Кармен':         'Плайя дель К',
    'Пуерто Валлар':             'Пуерто В',
    'Джимбаран (о. Балі)':       'Джимбаран',
    'Кута (о. Балі)':            'Кута',
    'Нуса-Дуа (о. Балі)':        'Нуса-Дуа',
    'Убуд (о. Балі)':            'Убуд',
    'Чандідаса (о. Балі)':       'Чандідаса',
    'Санур (о. Балі)':           'Санур',
    'Денпасар (о. Балі)':        'Денпасар',
    'Танджунг Беноа (о. Балі)':  'Танджунг',
    'Семіньяк (о. Балі)':        'Семіньяк',
    'Ловіна (о. Балі)':          'Ловіна',
    'Порт Ель Кантауї':          'Порт Ель К',
    'Сонячний берег':            'Сон. берег',
    'Сонячний день':             'Сон. день',
    'Св. Константин і Єлена':    'Св. Кон.',
    'о. Крит - Айос-Ніколаос':   'Айос-Н.',
    'о. Крит - Елунда':          'Елунда',
    'о. Крит - Ханья':           'Ханья',
    'о. Крит - Іракліон':        'Іракліон',
    'Халкідікі-Неа Каллікратія': 'Халкідікі',
    'о. Крит - Ретимно':         'Ретимно',
    'Пелопоннес':                'Пелопон.',
    'о. Крит - Ієрапетра':       'Ієрапетра',
    'Халкідіки - Кассандра':     'Кассандра',
    'Халкідіки - Ситонія':       'Ситонія',
    'Коста Бланка':              'Коста Блан',
    'Коста-Брава':               'Коста-Брав',
    'Коста Дель Маресме':        'Коста Д М',
    'Коста Дель Соль':           'Коста Д С',
    'Коста Дорада':              'Коста Дор',
    'о. Тенеріфе (Канари)':      'о. Тенеріфе',
    'Ескальдес-Енгордань':       'Ескальдес',
    'Ордіно-Аркаліс':            'Ордіно-А',
    'Пал-Арінсаль':              'Пал-А',
    'Па де ля Каса':             'Па де ля К',
    'Сольдеу - Ель Тартер':      'Сольдеу',
    'Андорра-ла-Велья':          'Андорра-ла-В',
};

export const OPERATOR_NAMES = {
    'Anextour': 'Anex',
    'JoinUP!':  'JoinUp',
    'TEZ':      'TezTour',
};

export const getAirName = (name) => AIR_NAMES[name.toLowerCase()] || name;

export const getPortName = (name) => PORT_NAMES[name] || name;

export const getOperatorName = (name) => OPERATOR_NAMES[name] || name;

export const SERVICES_LABELS_BY_LOCALE = {
    [UK]: Map({
        'beach_line': 'Лінія пляжу',
        'location':   'Розташування',
        'beach':      'Пляж',
        'main':       'Головне',
        'beach_type': 'Тип пляжу',
        'child':      'Для дітей',
        'hotel':      'Послуги в готелі',
        'room':       'Зручності в номерах',
        'sport':      'Розваги і спорт',
        'recommend':  'Готелі з рекомендацією',
    }),
};

export const DEFAULT_COUNTRY_ID = 43;
