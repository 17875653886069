// Core
import * as R from 'ramda';

// Instruments
import {
    ANIMATION,
    THUMB_STEP_MODAL,
    GEO_MAP_ELEMENT_INDEX_POS,
    GEO_MAP_ELEMENT_INDEX_POS_WITH_CUSTOM_PHOTOS,
    CUSTOM_PHOTOS_INDEX_POS,
    CUSTOM_VIDEO_ELEMENT_INDEX_POS,
    CUSTOM_TOURIST_VIDEO_ELEMENT_INDEX_POS,
    GEO_MAP_ELEMENT,
    CUSTOM_HOTEL_VIDEO_ELEMENT,
    CUSTOM_TOURIST_HOTEL_VIDEO_ELEMENT
} from './constants';

const normalizePercent = (percent) => `${percent}%`;

const calcPercent = (activeIndex) => 100 * activeIndex * -1;

const generateTransformCssProps = (percent, animationType) => R.call(
    (value, type) => R.cond([
        [
            R.equals(ANIMATION.SLIDE),
            () => R.pipe(
                R.set(R.lensProp('WebkitTransform'), value),
                R.set(R.lensProp('MozTransform'), value),
                R.set(R.lensProp('msTransform'), value),
                R.set(R.lensProp('OTransform'), value),
                R.set(R.lensProp('transform'), value)
            )({})
        ],
        [R.T, R.always({})]
    ])(type),
    `translateX(${percent})`,
    animationType
);

export const createCssSingleTransformRules = (active, animationType) =>
    R.compose(
        R.partialRight(generateTransformCssProps, [animationType]),
        normalizePercent,
        calcPercent
    )(active);
export const createCssThumbsTransformRules = (active, amount, animationType, thumbStep) =>
    R.compose(
        R.partialRight(generateTransformCssProps, [animationType]),
        normalizePercent,
        (activeIndex, counts) => thumbStep * 100 / counts * Math.floor(activeIndex / thumbStep) * -1
    )(active, amount);

const injectGeoMap = (photos, indexPosition) => R.call(
    R.pipe(
        R.insert(
            Math.min(photos.length, indexPosition),
            GEO_MAP_ELEMENT
        ),
        R.when(
            () => photos.length > THUMB_STEP_MODAL,
            R.append(GEO_MAP_ELEMENT)
        )
    ),
    photos
);

export const injectGeoMapToPhotos = (photos) => injectGeoMap(photos, GEO_MAP_ELEMENT_INDEX_POS);

export const injectGeoMapToCustomPhotos = (photos) => injectGeoMap(photos, GEO_MAP_ELEMENT_INDEX_POS_WITH_CUSTOM_PHOTOS);

export const createInjectorCustomPhotosToPhotos = (insertPhotos) => (photos) =>
    R.insertAll(
        Math.min(photos.length, CUSTOM_PHOTOS_INDEX_POS),
        insertPhotos,
        photos
    );

export const injectCustomVideoToPhotos = (photos) =>
    R.insert(
        Math.min(photos.length, CUSTOM_VIDEO_ELEMENT_INDEX_POS),
        CUSTOM_HOTEL_VIDEO_ELEMENT,
        photos
    );

export const injectCustomTouristVideoToPhotos = (photos) =>
    R.insert(
        Math.min(photos.length, CUSTOM_TOURIST_VIDEO_ELEMENT_INDEX_POS),
        CUSTOM_TOURIST_HOTEL_VIDEO_ELEMENT,
        photos
    );

export const injectCustomDataToPhotos = (
    isSetCustomGallery,
    customHotelYouTube,
    touristHotelYouTube,
    customGallery,
    hasLocation,
    photos
) => R.call(
    R.pipe(
        R.when(R.always(isSetCustomGallery), createInjectorCustomPhotosToPhotos(customGallery)),
        R.when(R.always(customHotelYouTube), injectCustomVideoToPhotos),
        R.when(R.always(hasLocation), customHotelYouTube ? injectGeoMapToCustomPhotos : injectGeoMapToPhotos),
        R.when(R.always(touristHotelYouTube), injectCustomTouristVideoToPhotos)
    ),
    photos
);

export const generatePhotosLinks = (size, photos) => R.map(R.concat(`https://newimg.otpusk.com/3_anto/${size}/`), photos);
