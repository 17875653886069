import { createSelector } from 'reselect';

import { convertLangToLocale } from 'localization/helpers';

const domain = (_) => _.auth;

export const getAPIToken = createSelector(
    domain, (auth) => auth.getIn(['otpusk', 'token'])
);

export const getGoogleMapToken = createSelector(
    domain, (auth) => auth.getIn(['otpusk', 'googleToken'])
);

export const getLang = createSelector(
    domain, (auth) => auth.getIn(['otpusk', 'lang'])
);

export const getLocale = createSelector(
    getLang, convertLangToLocale
);
