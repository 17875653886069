import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { commonActions } from 'bus/common';

const hoc = (Component) => {
    const WithHoliday = (props) => {
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(commonActions.getHoliday());
        }, []);

        return <Component { ...props } />;
    };

    return WithHoliday;
};

export default hoc;
