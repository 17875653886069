import * as R from 'ramda';

const updateOffersIDs = (offersByHotel, hotelID, nextIDs) => R.over(
    R.lensProp(hotelID),
    (prevIDs) => prevIDs ? R.concat(prevIDs, nextIDs) : nextIDs,
    offersByHotel
);

export const extractOffersByHotelID = (hotelPagesList, hotelID) => R.call(
    R.pipe(
        R.map((hotelsMap) => hotelsMap.toObject()),
        R.map(R.toPairs),
        R.reduce(
            (results, hotelsList) => R.reduce(
                (innerResults, [localHotelID, idS]) => updateOffersIDs(
                    innerResults,
                    localHotelID,
                    idS
                ),
                results,
                hotelsList
            ),
            {}
        ),
        R.propOr([], hotelID)
    ),
    hotelPagesList
);

export const getPriceFromOffer = (offer) => R.call(
    R.ifElse(
        R.prop('discountPrice'),
        R.prop('discountPrice'),
        R.prop('price')
    ),
    offer
);
