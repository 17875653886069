import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import T from 'prop-types';
import * as R from 'ramda';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import PeopleSwitcher from 'components/ui/PeopleSwitcher';

import { getUIHotTours, getCountryName } from './store/selectors';
import { HOT_BLOCKS_DEFAULT } from './constants';
import styles from './styles.scss';

const HotListTitle = ({ initialTitle, countryID, blockID, priceDivider, onChangePriceDivider, withDividerController }) => {
    const { i18n, t } = useTranslation(['HOT_TOURS', 'TIME']);
    const [timer, setTimer] = useState(0);

    const getCountryNameSelector = useMemo(
        () => R.partialRight(getCountryName(), [{ id: countryID, blockID }]),
        [countryID, blockID]
    );

    const countryName = useSelector(getCountryNameSelector);
    const ui = useSelector((state) => getUIHotTours(state, { blockID }));

    const timerStringify = useMemo(() => {
        if (timer) {
            const momentTime = moment.duration(timer, 'seconds');

            return `${momentTime.hours()} ${t('HOUR_SHORT')} ${momentTime.minutes()} ${t('MINUTE_SHORT')} ${momentTime.seconds()} ${t('SECOND_SHORT')}`; /* 01 год:23 хв:30 сек */
        }

        return null;
    }, [timer]);
    const title = useMemo(
        () => R.call(
            R.cond([
                [R.always(ui.loading), () => t("DEFAULT_TITLE")],
                [
                    (id) => R.includes(id, Object.keys(HOT_BLOCKS_DEFAULT)),
                    () => i18n.exists(initialTitle)
                        ? t(initialTitle)
                        : initialTitle
                ],
                [
                    R.T,
                    () => countryName
                        ? R.concat(countryName, ` - ${t("DEFAULT_TITLE")}`)
                        : t(initialTitle)
                ]
            ]),
            countryID
        ),
        [countryID, countryName, initialTitle, t, i18n, ui.loading]
    );

    useEffect(() => {
        const now = moment();
        const limitTimerDuration = moment().hour(12).minute(0).second(0);
        const startLimitTimerDuration = moment().hour(10).minutes(0).seconds(0);
        const difference = now.diff(startLimitTimerDuration, 'seconds') > 0 ? limitTimerDuration.diff(now, 'seconds') : 0;

        if (difference > 0) {
            setTimer(difference);

            const intervalID = setInterval(
                () => setTimer(R.pipe(
                    R.partialRight(R.subtract, [1]),
                    R.ifElse(
                        (value) => value >= 0,
                        (value) => value,
                        () => clearInterval(intervalID)
                    )
                )),
                1000
            );

            return () => clearInterval(intervalID);
        }
    }, []);

    return (
        <div className = { styles.hotHeading } data-element = 'hot-heading'>
            { timerStringify
                ? (
                    <div
                        className = { styles.hotTitle }
                        dangerouslySetInnerHTML = { { __html: t('TITLE_WITH_TIMER', { time: timerStringify }) } }
                    />
                ) : (
                    <div className = { styles.hotTitle }>
                        {title}
                    </div>
                ) }
            { withDividerController && (
                <PeopleSwitcher
                    value = { priceDivider }
                    onChange = { onChangePriceDivider }
                />
            ) }
        </div>
    );
};

HotListTitle.propTypes = {
    blockID:   T.string.isRequired,
    countryID: T.oneOfType([
        T.number,
        T.oneOf(Object.keys(HOT_BLOCKS_DEFAULT))
    ]).isRequired,
    priceDivider:          T.number.isRequired,
    onChangePriceDivider:  T.func.isRequired,
    initialTitle:          T.string,
    withDividerController: T.bool,
};
HotListTitle.defaultProps = {
    initialTitle:          'INITIAL_TITLE',
    withDividerController: false,
};

export default HotListTitle;
