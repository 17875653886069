import React, { memo } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './styles.scss';

const Rating = ({ rating, onClick }) => {
    const { t } = useTranslation('PRICES_LIST');

    return rating >= 7.2 && (
        <span className = { styles.rating } title = { t('RATING_LABEL') } onClick = { onClick }>
            { rating === 10 ? rating : rating.toFixed(1) }
        </span>
    );
};

Rating.propTypes = {
    rating:  T.number.isRequired,
    onClick: T.func,
};

export default memo(Rating);
