import { useState, useCallback, useEffect } from 'react';

export const useSlider = ({
    countSlides,
    initialActive = 0,
    calcPercent = () => void 0,
    middleware = () => void 0,
}) => {
    const [activeSlide, setActiveSlide] = useState(initialActive);

    // methods
    const onNext = useCallback(() => {
        const nextSlide = activeSlide + 1;

        if (nextSlide + 1 <= countSlides) {
            setActiveSlide(nextSlide);
        }

        middleware(nextSlide, countSlides, setActiveSlide);
    }, [activeSlide, countSlides]);

    const onPrev = useCallback(() => {
        const nextSlide = activeSlide - 1;

        if (nextSlide >= 0) {
            setActiveSlide(nextSlide);
        }

        middleware(nextSlide, countSlides, setActiveSlide);
    }, [activeSlide, countSlides]);

    useEffect(() => {
        setActiveSlide(initialActive);
    }, [initialActive]);

    return {
        activeSlide,
        percentMove: calcPercent(activeSlide, countSlides),
        onNext,
        onPrev,
        setActiveSlide,
    };
};
