import { call, put, select } from 'redux-saga/effects';
import i18n from 'i18next';
import * as R from 'ramda';

import { uiActions } from 'bus/ui';
import { reviewsActions as actions } from 'bus/reviews';
import { getAPIToken } from 'bus/auth/selectors';

import { createTripadvisorUIKey, normalizeDescription } from '../../helpers';

const getRateLabel = (rate) => R.prop(
    parseInt(rate, 10),
    [
        'None',
        i18n.t('TRIPADVISOR_REVIEWS:TERRIBLE'),
        i18n.t('TRIPADVISOR_REVIEWS:BAD'),
        i18n.t('TRIPADVISOR_REVIEWS:NOT_BAD'),
        i18n.t('TRIPADVISOR_REVIEWS:VERY_GOOD'),
        i18n.t('TRIPADVISOR_REVIEWS:EXCELLENT')
    ]
);

const normalizeHotelReviews = (hotelReview) => {
    const { number: count, posts, rate: rateHotel, ...restHotelDesc } = hotelReview;

    return {
        description: {
            count,
            rate: { value: rateHotel, label: getRateLabel(rateHotel) },
            ...restHotelDesc,
        },
        reviews: R.map(
            ({ author, authorGeo, date, datePublished, rate, textBrief, textFull, avatar, ...restReview }) => {
                const short = normalizeDescription(textBrief);
                const long = normalizeDescription(textFull);

                return {
                    author:      { name: author, geo: authorGeo, avatar },
                    dateCreated: date,
                    datePublished,
                    rate:        { value: rate, label: getRateLabel(rate) },
                    description: { short, long: short !== long ? long : undefined },
                    ...restReview,
                };
            },
            posts
        ),
    };
};

function* getTripadvisorReviewsWorker ({ payload: { hotelID, page }}) {
    const uiKey = createTripadvisorUIKey(hotelID);

    yield put(uiActions.setUiState(
        uiKey,
        { loading: true, error: false, completed: false, message: null }
    ));

    const token = yield select(getAPIToken);

    try {
        const response = yield call(
            fetch,
            R.concat(
                'https://control.antonivtours.com/api/review?',
                new URLSearchParams({ hotelId: hotelID, 'access_token': token.access_token, page }).toString()
            )
        );

        if (response.status !== 200) {
            const { message } = yield call([response, 'json']);

            throw new Error(message);
        }

        const { data } = yield call([response, 'json']);

        const { reviews, description } = normalizeHotelReviews(data);

        yield put(actions.setTripadvisorReviews(hotelID, reviews, description));
    } catch (error) {
        yield put(actions.getTripadvisorReviewsFail(error));

        yield put(uiActions.setUiState([uiKey, 'error'], true));
        yield put(uiActions.setUiState([uiKey, 'message'], error));
    } finally {
        yield put(uiActions.setUiState([uiKey, 'loading'], false));
        yield put(uiActions.setUiState([uiKey, 'completed'], true));
    }
}

export default getTripadvisorReviewsWorker;
