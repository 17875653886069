import { call, put } from 'redux-saga/effects';
import * as R from 'ramda';

import createIndicatorController from 'helpers/indicatorsController';

import { hotActions } from '../../actions';
import { generateIndicatorsKey } from '../../helpers';
import { EMPTY_COUNTRY_ID } from '../../constants';

const { isSetIndicator, addIndicator } = createIndicatorController();

const normalizeCountry = (country) => R.call(
    ({ name, image: img, ['image-mobile']: imageMobile, order: size, link: targetLink, otpuskID, comment, ['hot-block-id']: hotBlockID }) => ({
        comment,
        name,
        img: {
            desktop: img,
            mobile:  imageMobile,
        },
        targetLink,
        otpuskID: Number(otpuskID),
        size:     Number(size),
        hotBlockID,
    }),
    country
);

const sortBySize = R.ascend(R.prop('size'));

const normalizeCountries = (map) => R.call(
    R.pipe(
        R.toPairs,
        R.map(([otpuskID, country]) => R.set(R.lensProp('otpuskID'), otpuskID, country)),
        R.map(normalizeCountry),
        R.sort(sortBySize)
    ),
    map
);


const createYoutubeLink = (value) => R.concat(
    `https://www.youtube.com/embed/${value}`,
    !R.includes('?', value) ? '?' : ''
);

const normalizeVideos = (videos) => R.map(
    R.pipe(
        R.pick(['description', 'youtube', 'prev']),
        ({ prev: placeholder, ...rest }) => R.mergeAll([{ placeholder }, rest]),
        R.over(R.lensProp('youtube'), createYoutubeLink)
    ),
    videos
);

const normalizeRootCountry = (country) => R.call(
    R.pipe(
        R.over(R.lensProp('youtube'), createYoutubeLink),
        R.over(R.lensProp('gallery'), (gallery) => Array.isArray(gallery) ? gallery : []),
        R.over(R.lensProp('gallery'), R.map(([url, description]) => ({ url, description }))),
        ({ video, ['video-review']: reviews, ...rest }) => R.mergeAll([
            rest,
            { videos: Array.isArray(video) ? video : []},
            { reviews: Array.isArray(reviews) ? reviews : []}
        ]),
        R.over(R.lensProp('videos'), normalizeVideos),
        R.over(R.lensProp('reviews'), normalizeVideos),
        R.over(R.lensProp('instructions'), (data) => ({
            name:        country.name,
            youtube:     createYoutubeLink(data['instruction-youtube']),
            description: data['instruction-description'],
            placeholder: data['instruction-prev'],
        })),
        R.over(R.lensProp('excursions'), R.map(({ image, 'image-mobile': imageMobile, ...excursion }) =>
            ({ ...excursion, images: R.values(image), mobileImages: R.values(imageMobile) })
        ))
    ),
    country
);

const getInstructions = (countriesMap) => R.call(
    R.pipe(
        R.toPairs,
        R.map(([otpuskID, country]) => ({
            otpuskID:    Number(otpuskID),
            name:        country.name,
            youtube:     createYoutubeLink(country['instruction-youtube']),
            description: country['instruction-description'],
            placeholder: country['instruction-prev'],
        })),
        R.filter(R.prop('youtube'))
    ),
    countriesMap
);

export default function* ({ payload: countryID }) {
    if (isSetIndicator(countryID)) {
        return;
    }
    addIndicator(countryID);
    const uiPath = generateIndicatorsKey(countryID);

    yield put(hotActions.setUi(uiPath, { loading: true }));

    try {
        const proccess = yield call(
            fetch,
            R.concat(
                '/wp-json/rest_api/v1/countries_list_data',
                countryID === EMPTY_COUNTRY_ID ? '' : `/${countryID}`
            )
        );
        const response = yield call([proccess, 'json']);

        if (response) {
            const [countries, country] = countryID ? response : [response];

            yield put(hotActions.setHotCountries(countryID, normalizeCountries(countries)));
            yield put(hotActions.setInstructions(countryID, getInstructions(countries)));
            country && (yield put(hotActions.setHotCountry(countryID, normalizeRootCountry(country))));
        }

    } catch (error) {
        const uiPayload = { error: true, message: error.message };

        console.log(error);

        yield put(hotActions.setUi(uiPath, uiPayload));
        yield put(hotActions.failFetchHotCountries(error));
    } finally {
        const uiPayload = { loading: false, completed: true };

        yield put(hotActions.setUi(uiPath, uiPayload));
    }

}
