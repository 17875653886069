// Core
import { call, put } from 'redux-saga/effects';
import * as R from 'ramda';

// Actions
import { uiActions } from 'bus/ui';
import { weatherActions } from 'bus/weather';

const transformParamsToString = R.pipe(
    (q) => new URLSearchParams(q).toString(),
    R.ifElse(
        Boolean,
        R.concat('?'),
        () => ''
    )
);

const normalizeData = R.pipe(
    (data) => R.set(
        R.lensProp('icon'),
        `http://openweathermap.org/img/wn/${R.prop('icon', data)}.png`,
        data
    )
);

export function* getWeatherWorker ({ payload }) {
    const { entityType, entityID, queryParams = {}, displayMachine } = payload;
    const queryString = transformParamsToString(queryParams);

    yield put(uiActions.setUiState(
        ['weather.fetch', displayMachine],
        { loading: true, error: false, completed: false, message: null }
    ));

    try {
        const response = yield call(fetch, `/wp-json/rest_api/v1/otpusk_${entityType}/${entityID}${queryString}`, {
            method:  'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        const data = yield call([response, 'json']);

        if (response.status !== 200) {
            throw new Error(data.message);
        }

        yield put(weatherActions.getWeatherSuccess(displayMachine, normalizeData(data)));
    } catch (error) {
        yield put(uiActions.updateUiState(
            ['weather.fetch', displayMachine],
            { error: true, message: error.message }
        ));
        yield put(weatherActions.getWeatherFail(error));
    } finally {
        yield put(uiActions.updateUiState(
            ['weather.fetch', displayMachine],
            { loading: false, completed: true }
        ));
    }

}
