// Core
import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader';
import { servicesActions } from '@otpusk/apisearch-toolbox/dist/services';

// Install Rheostat and React Dates
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import cssInterface from 'react-with-styles-interface-css';
import RheostatDefaultTheme from 'rheostat/lib/themes/DefaultTheme';
import ReactDatesDefaultTheme from 'react-dates/lib/theme/DefaultTheme';

import { ErrorBoundary } from 'providers/ErrorBoundary';
import DeviceDetector from 'components/DeviceDetector';

import { Router } from 'routes';

import history from 'init/history';
import { store } from 'init/store';
import { computeLocale } from 'localization/helpers';
import { RU } from 'localization/constants';
import { SERVICES_LABELS_BY_LOCALE } from 'helpers/staticData';

import HotWidgets from 'widgets/hot';
import ConsultButton from 'components/ui/Consult';
import AppBootstrap from 'components/bootstrap/AppBootstrap';

ThemedStyleSheet.registerInterface(cssInterface);
ThemedStyleSheet.registerTheme({
    ...RheostatDefaultTheme,
    ...ReactDatesDefaultTheme,
});

const locale = computeLocale();

if (SERVICES_LABELS_BY_LOCALE[locale]) {
    store.dispatch(servicesActions.setLabels(SERVICES_LABELS_BY_LOCALE[locale]));
} else if (locale !== RU) {
    console.warn(`Set services lables for this locale [${locale}]!!!`);
}

const App = () => (
    <Provider store = { store }>
        <ConnectedRouter history = { history }>
            <ErrorBoundary>
                <AppBootstrap>
                    <DeviceDetector>
                        <Router />
                        <HotWidgets />
                        <ConsultButton />
                    </DeviceDetector>
                </AppBootstrap>
            </ErrorBoundary>
        </ConnectedRouter>
    </Provider>
);

export default hot(module)(App);
