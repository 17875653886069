import { createBrowserHistory } from 'history';

import { computeLocale } from 'localization/helpers';
import { LOCALE_TO_BASE_PATH_MAP } from 'localization/constants';

const history = createBrowserHistory({
    basename: LOCALE_TO_BASE_PATH_MAP[computeLocale()],
});

export default history;
