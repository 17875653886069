import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { isSetParam } from './helpers';

export default () => {
    const { search } = useLocation();
    const activated = useMemo(
        () => isSetParam(search),
        [search]
    );

    return activated;
};
