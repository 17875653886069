import { handleActions } from 'redux-actions';
import { Map } from 'immutable';

// instruments
import { graphActions as actions } from './actions';

const initialState = Map({
    ui: {},
});

export const graphReducer = handleActions({
    [actions.setUi]: (state, { payload: { value, path }}) => state.setIn(['ui', ...path], value),
}, initialState);
