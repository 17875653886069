import React, { memo } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import T from 'prop-types';

import styles from './styles';

const modalContainer = global.document.createElement('div');

global.document.querySelector('body').append(modalContainer);

const Modal = ({ children, header, onClose, onClick, className }) => createPortal(
    <div className = { styles.modalOverlay } onClick = { onClick }>
        <div className = { classnames(styles.modalBody, className) }>
            <div className = { styles.modalBodyInner }>
                <div className = { styles.modalHeader }>
                    <span>{ header }</span>
                    { onClose && (
                        <div className = { styles.modalClose } onClick = { onClose }>
                            &times;
                        </div>
                    ) }
                </div>
                <div className = { styles.modalContent }>
                    { children }
                </div>
            </div>
        </div>
    </div>,
    modalContainer
);

Modal.propTypes = {
    children:  T.node,
    className: T.string,
    header:    T.string,
    onClick:   T.func,
    onClose:   T.func,
};

export default memo(Modal);
