import { handleActions } from 'redux-actions';
import { Map, OrderedMap } from 'immutable';

// instruments
import { hotActions as actions } from './actions';

const initialState = Map({
    store: OrderedMap(),
    ui:    {
        hotTours:  {},
        countries: {},
    },
    instructions: {},
    countries:    {},
    country:      {},
});

export const hotReducer = handleActions({
    [actions.addHotTours]:     (state, { payload: { blockId, tours }}) => state.mergeIn(['store', blockId], tours),
    [actions.setUi]:           (state, { payload: { value, path }}) => state.setIn(['ui', ...path], value),
    [actions.setHotCountries]: (state, { payload: { countryID, data }}) => state.setIn(
        ['countries', countryID],
        data
    ),
    [actions.setHotCountry]:   (state, { payload }) => state.mergeIn(['country'], payload),
    [actions.setInstructions]: (state, { payload }) => state.mergeIn(['instructions'], payload),
}, initialState);
