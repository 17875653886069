// Core
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import T from 'prop-types';

import { useMediaQuery } from 'containers/media';
import { MOBILE_BREAK_POINT } from 'containers/media/constants';

import {
    getSlidesCount,
    isSetCustomHotelGallery,
    getCustomHotelYouTubeVideo,
    isSetCustomReplacementPhotos,
    getCustomHotelTouristYouTubeVideo,
    getCustomReplacementPhotosBySize,
    getCustomHotelGalleryBySize,
    getCustomReplacementPhotos,
    getCustomGalleryPhotos,
    hasHotelLocation,
    getUIModal
} from './store/selectors';
import { SliderResultProvider, SliderResultConsumer } from './context';
import { THUMB_STEP, THUMB_STEP_MODAL } from './constants';
import SingleCarousel from './Single';
import Thumbs from './Thumbs';
import ModalGallery from './Modal';
import {
    injectCustomDataToPhotos,
    generatePhotosLinks
} from './helpers';
import {
    PHOTO_SIZE_MAP,
    SMALL_PHOTO
} from './constants';

const Carousel = ({
    animation,
    openedGalleryModal,
    items,
    modal,
    enabledModal,
    photoSize,
    enabledThumbs,
    isHideControl,
    prefetchPhotos,
    onChangeOpenGalleryModal,
    showYouTubeSlide,
    hotelID,
    ...props
}) => {
    const [thumbsVisible, setThumbsVisible] = useState(false);

    const mediaMobile = useMediaQuery({ mediaQueryString: MOBILE_BREAK_POINT });

    const slidesCount = useSelector((state) => getSlidesCount(
        state,
        { hotelID, toString: () => hotelID }
    ));
    const hasLocation = useSelector((state) => openedGalleryModal && hasHotelLocation(state, hotelID));
    const uiModal = useSelector((state) => getUIModal(state, { hotelID }));

    const isSetReplacementPhotos = useSelector((state) => isSetCustomReplacementPhotos(state, { hotelID }));
    const replacementPhotos = useSelector((state) => getCustomReplacementPhotosBySize(state, { hotelID, photoSize }));
    const replacementThumbPhotos = useSelector((state) => enabledThumbs && getCustomReplacementPhotosBySize(
        state,
        { hotelID, photoSize: SMALL_PHOTO }
    ));
    const replacementModalPhotos = useSelector((state) => openedGalleryModal && getCustomReplacementPhotos(state, { hotelID }));

    const isSetCustomGallery = useSelector((state) => isSetCustomHotelGallery(state, { hotelID }));
    const customGallery = useSelector((state) => getCustomHotelGalleryBySize(state, { hotelID, photoSize }));
    const customGalleryThumb = useSelector((state) => enabledThumbs && getCustomHotelGalleryBySize(
        state,
        { hotelID, photoSize: SMALL_PHOTO }
    ));
    const customGalleryModal = useSelector((state) => enabledModal && getCustomGalleryPhotos(state, { hotelID }));

    const isShowYouTubeSlide = showYouTubeSlide || openedGalleryModal;

    const customHotelYouTube = useSelector((state) => isShowYouTubeSlide && getCustomHotelYouTubeVideo(state, { hotelID }));
    const touristHotelYouTube = useSelector((state) => isShowYouTubeSlide && getCustomHotelTouristYouTubeVideo(state, { hotelID }));

    // methods
    const openGalleryModal = useCallback(() => onChangeOpenGalleryModal(true), []);
    const closeGalleryModal = useCallback(() => onChangeOpenGalleryModal(false), []);
    const showThumbs = useCallback(() => setThumbsVisible(true), []);
    const hideThumbs = useCallback(() => setThumbsVisible(false), []);

    const photos = useMemo(
        () => injectCustomDataToPhotos(
            isSetCustomGallery,
            customHotelYouTube,
            touristHotelYouTube,
            customGallery,
            hasLocation,
            isSetReplacementPhotos
                ? replacementPhotos
                : generatePhotosLinks(PHOTO_SIZE_MAP[photoSize], items)
        ),
        [
            items,
            photoSize,
            replacementPhotos,
            isSetReplacementPhotos,
            isSetCustomGallery,
            customHotelYouTube,
            customGallery,
            hasLocation,
            touristHotelYouTube
        ]
    );
    const thumbsPhotos = useMemo(
        () => enabledThumbs && injectCustomDataToPhotos(
            isSetCustomGallery,
            customHotelYouTube,
            touristHotelYouTube,
            customGalleryThumb,
            hasLocation,
            isSetReplacementPhotos
                ? replacementThumbPhotos
                : generatePhotosLinks(PHOTO_SIZE_MAP.small, items)
        ),
        [
            items,
            enabledThumbs,
            replacementThumbPhotos,
            isSetReplacementPhotos,
            customGalleryThumb,
            isSetCustomGallery,
            customHotelYouTube,
            hasLocation,
            touristHotelYouTube
        ]
    );
    const modalPhotos = useMemo(
        () => openedGalleryModal
            ? injectCustomDataToPhotos(
                isSetCustomGallery,
                customHotelYouTube,
                touristHotelYouTube,
                customGalleryModal,
                hasLocation,
                isSetReplacementPhotos
                    ? replacementModalPhotos
                    : generatePhotosLinks(mediaMobile.visible ? PHOTO_SIZE_MAP.medium : PHOTO_SIZE_MAP.large, items)
            )
            : [],
        [
            openedGalleryModal,
            items,
            replacementModalPhotos,
            isSetReplacementPhotos,
            customGalleryModal,
            isSetCustomGallery,
            customHotelYouTube,
            hasLocation,
            touristHotelYouTube
        ]
    );

    const isPrefetchPhotos = prefetchPhotos || openedGalleryModal;

    return (
        <div onMouseEnter = { showThumbs } onMouseLeave = { hideThumbs }>
            <SliderResultProvider
                { ...props }
                countSlides = { photos.length }
                hotelID = { hotelID }
                prefetchPhotos = { isPrefetchPhotos }>
                <SliderResultConsumer>
                    { ({ DOMHandlers, sliderAPI }) => (
                        <>
                            <SingleCarousel
                                activeSlide = { sliderAPI.activeSlide }
                                animation = { animation }
                                hotelID = { hotelID }
                                isHideControl = { isHideControl }
                                items = { photos }
                                onActiveItemClick = { enabledModal ? openGalleryModal : void 0 }
                                onNext = { sliderAPI.onNext }
                                onPrev = { sliderAPI.onPrev }
                                { ...DOMHandlers }
                            />
                            { enabledThumbs && !openedGalleryModal && (
                                <Thumbs
                                    activeSlide = { sliderAPI.activeSlide }
                                    photos = { thumbsPhotos }
                                    thumbStep = { THUMB_STEP }
                                    visible = { thumbsVisible }
                                    onChange = { sliderAPI.setActiveSlide }
                                />
                            ) }
                        </>
                    ) }
                </SliderResultConsumer>
            </SliderResultProvider>
            { enabledModal && (
                <SliderResultProvider
                    { ...props }
                    countSlides = { slidesCount }
                    hotelID = { hotelID }
                    keyboardActivated = { openedGalleryModal }>
                    <SliderResultConsumer>
                        { ({ sliderAPI, DOMHandlers }) => (
                            <>
                                { openedGalleryModal && !R.isEmpty(modalPhotos) && (
                                    <ModalGallery
                                        { ...modal }
                                        activePhotoIndex = { sliderAPI.activeSlide }
                                        photos = { modalPhotos }
                                        touchHandlers = { DOMHandlers }
                                        onClose = { closeGalleryModal }
                                        onNext = { sliderAPI.onNext }
                                        onPrev = { sliderAPI.onPrev }
                                    />
                                ) }
                                { enabledThumbs && openedGalleryModal && uiModal.completed && (
                                    <Thumbs
                                        activeSlide = { sliderAPI.activeSlide }
                                        photos = { thumbsPhotos }
                                        thumbStep = { THUMB_STEP_MODAL }
                                        visible = { thumbsVisible }
                                        onChange = { sliderAPI.setActiveSlide }
                                    />
                                ) }
                            </>
                        ) }
                    </SliderResultConsumer>
                </SliderResultProvider>
            ) }
        </div>
    );
};

Carousel.propTypes = {
    hotelID:                  T.string.isRequired,
    items:                    T.arrayOf(T.string).isRequired,
    openedGalleryModal:       T.bool.isRequired,
    photoSize:                T.string.isRequired,
    enabledModal:             T.bool,
    enabledThumbs:            T.bool,
    initialActive:            T.number,
    isHideControl:            T.bool,
    modal:                    T.object,
    showYouTubeSlide:         T.bool,
    onChangeOpenGalleryModal: T.func,
};
Carousel.defaultProps = {
    enabledModal:             false,
    enabledThumbs:            false,
    keyboardActivated:        false,
    initialActive:            0,
    openedGalleryModal:       false,
    modal:                    {},
    onChangeOpenGalleryModal: () => {},
    showYouTubeSlide:         false,
};

export default memo(Carousel);
