import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import T from 'prop-types';
import * as R from 'ramda';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { geoSelectors } from '@otpusk/apisearch-toolbox/dist/geo';
import { useTranslation } from 'react-i18next';

import styles from '../../styles';
import { getPortName } from 'helpers/staticData';

const timeToMoment = (string) => moment(string, 'YYYY-MM-DD HH:mm:ss');

const createPlaceEntity = (place, t) => R.call(
    R.pipe(
        Number,
        R.cond([
            [
                (value) => R.gt(value, 10),
                (count) => ({ color: styles.green, text: t('MORE_FLIGHT_PLACES', { count }) })
            ],
            [
                R.equals(0),
                R.always({ color: styles.red, text: t('EMPTY_FLIGHT_PLACES') })
            ],
            [
                R.T,
                (count) => ({ color: styles.yellow, text: t('FEW_FLIGHT_PLACES', { count }) })
            ]
        ])
    ),
    place
);

const mappingPortName = (port) => R.prop('name', port);

const printTimeDeparture = (time) => R.call(
    R.pipe(timeToMoment, (v) => v.format('HH:mm')),
    time
);

const Flight = ({ children, begin, place, portFr }) => {
    const { t } = useTranslation('OTHER_PRICES_TABLE');

    const getPortFrom = useMemo(
        () => R.partialRight(geoSelectors.getFlightPort(), [{ iata: portFr }]),
        [portFr]
    );

    const portFrom = useSelector(getPortFrom);

    const placeEntity = useMemo(
        () => createPlaceEntity(place, t),
        [place, t]
    );

    const portName = useMemo(
        () => mappingPortName(portFrom),
        [portFrom]
    );
    const timeDeparture = useMemo(
        () => printTimeDeparture(begin),
        [begin]
    );


    return (
        <nobr>
            { placeEntity && (
                <div
                    className = { classnames(styles.status, placeEntity.color) }
                    title = { placeEntity.text }
                />
            ) }
            <span className = { styles.time }>{ timeDeparture }</span>
            {' '}
            <span style = { { color: 'inherit', fontSize: '11px' } } title = { portName }>{ portName && getPortName(portName).substring(0, 11) }</span>
            {' '}
            {children && (
                <>
                    {' '}
                    {children}
                </>
            )}
        </nobr>
    );
};

Flight.propTypes = {
    begin: T.string.isRequired,
    end:   T.string.isRequired,
    place: T.oneOfType([
        T.number,
        T.string
    ]).isRequired,
    children: T.node,
};

export default memo(Flight);
