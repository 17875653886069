import { createSelector } from 'reselect';
import { QUERY_PARAMS } from '@otpusk/apisearch-toolbox/dist/queries/fn';
import * as R from 'ramda';

import { getUI } from 'bus/ui/selectors';

import { getUIKey } from './helpers';

const domain = (_) => _.prefetchNextPage;

const getQuery = () => createSelector(
    (_) => _.queries,
    (_, { queryID }) => queryID,
    (map, id) => map.get(id)
);

export const getActivePage = () => createSelector(
    getQuery(),
    R.when(Boolean, (query) => query.get(QUERY_PARAMS.PAGE))
);

export const isPrefetchNextPage = (queryID) => createSelector(
    R.partialRight(
        getUI, [{ key: getUIKey(queryID) }]
    ),
    R.prop('loading')
);

export const shouldPrefetchRepeat = createSelector(
    domain,
    R.prop('shouldPrefetchRepeat')
);
