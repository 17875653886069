// Core
import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import SpinnerBootstrap from 'components/bootstrap/SpinnerBootstrap';

// Containers
import { withHoliday } from 'providers/Holiday';

// Pages
import OtpuskTour from 'pages/OtpuskTour';

const Main = lazy(() => import('pages/Main'));
const Hotel = lazy(() => import('pages/Hotel'));
const TourByCountry = lazy(() => import('../pages/TourByCountry'));
const HotTour = lazy(() => import('pages/Hot/children/HotTour'));
const Marketing = lazy(() => import('pages/Marketing'));
const VideoAndImageWidget = lazy(() => import('components/ui/VideoAndImages'));

// Instruments
import book from './book';

export const Router = () => (
    <Suspense fallback = { <SpinnerBootstrap /> } >
        <Switch>
            <Route
                exact
                component = { withHoliday(Main) }
                path = { book.main }
            />
            <Route
                exact
                component = { withHoliday(TourByCountry) }
                path = { book.tourByCountry }
            />
            <Route
                exact
                path = { book.tour }
                render = { () => <Hotel /> }
            />
            <Route
                exact
                path = { book.hotTour }
                render = { () => <HotTour /> }
            />
            <Route
                exact
                path = { book.tourByOtpusk }
                render = { () => <OtpuskTour /> }
            />
            <Route
                exact
                component = { Marketing }
                path = { [
                    book.hot,
                    book.marketingPages.bestTours,
                    book.marketingPages.niceHotels,
                    book.marketingPages.newyear,
                    book.marketingPages.blackfriday,
                    book.marketingPages.stnicholas,
                    book.marketingPages.exotics
                ] }
            />
            <Route
                exact
                path = { book.about }
                render = { () => (
                    <VideoAndImageWidget countryID = { 43 } />
                ) }
            />
        </Switch>
    </Suspense>
);
