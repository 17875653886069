import { getIn } from 'immutable';
import moment from 'moment';
import * as R from 'ramda';

export const createQQPayload = (offer, hotel, activeCurrencyCode, linkToTour, departureCityName, price) => {
    const { country, city } = hotel;

    return JSON.stringify({
        checkinDt:   moment(offer.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        nights:      String(offer.nights),
        hotelName:   `${hotel.name} ${hotel.stars}*`,
        roomType:    `${getIn(offer, ['room', 'type'], '').toUpperCase()} ${getIn(offer, ['room', 'name'], '')}`,
        boardType:   getIn(offer, ['food'], '').toUpperCase(),
        price,
        currency:    activeCurrencyCode.toUpperCase(),
        'city_from': departureCityName,
        occupancy:   {
            adultsCount:   offer.adults,
            childrenCount: offer.children,
            childAges:     getIn(offer, ['childrenAge'], '').replace('-', ','),
        },
        region:    city.name,
        country:   country.name,
        href:      linkToTour,
        thumbnail: `https://newimg.otpusk.com/3/400x300/${getIn(hotel, ['photos', 0], '')}`,
    });
};

export const createFlightsDates = (offer, extended) => {
    const { flights } = offer;

    if (flights) {
        const selector = (object, key) => getIn(object, [key, 0, 'begin']);

        return extended
            ?
            [selector(flights, 'outbound'), selector(flights, 'inbound')].map((date) => date ? moment(date).format('DD.MM-HH:mm') : null)
            :
            [selector(flights, 'outbound'), selector(flights, 'inbound')].map((date) => date ? moment(date).format('DD MMMM HH:mm') : null);
    }

    return [null, null];
};

export const createGiftText = (offer, holiday) => R.cond([
    [R.equals(1416), R.always(holiday)],
    [R.equals(2835), R.always('Страховка від COVID')],
    [R.T, R.always('техніка')]
])(R.prop('operator', offer));
