// Core
import React, { memo, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { geoActions } from '@otpusk/apisearch-toolbox/dist/geo';
import t from 'prop-types';

// Instruments
import * as helpers from './helpers';

const emptyArray = [];

const startedProcesses = [];

const setStarted = (id) => startedProcesses.push(id);
const isStarted = (id) => startedProcesses.includes(id);

const withOperators = (operatorsTransformer = (o) => o) => (Component) => {
    const C = ({ countryID, ...props }) => {
        const operators = useSelector(({ geo }) => geo.getIn(['operators', countryID]));

        // methods
        const dispatch = useDispatch();

        // shared
        const shared = useMemo(() => ({
            operators: operatorsTransformer(operators ? operators.toArray() : emptyArray),
        }), [operators]);

        useEffect(() => {
            if (countryID && !operators) {
                !isStarted(countryID) && (
                    dispatch(geoActions.getOperators(countryID)),
                    setStarted(countryID)
                );
            }
        }, [countryID]);

        return (
            <Component { ...shared } { ...props } />
        );
    };

    C.displayName = 'withOperatorsProvider';
    C.propTypes = {
        countryID: t.number,
    };

    return memo(C);
};

const Provider = ({ children }) => children;

Provider.defaultProps = {
    children: null,
};

const OperatorUploader = withOperators()(Provider);

export { withOperators, helpers, OperatorUploader };
