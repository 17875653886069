import { call, put } from 'redux-saga/effects';
import { getTurpravdaHotelInformer } from '@otpusk/json-api';
import i18n from 'i18next';

import { uiActions } from 'bus/ui';

import { reviewsActions as actions } from 'bus/reviews';

import { createHotelUIKey } from '../../helpers';

const createLoadingAction = (overrides, hotelID) => uiActions.setUiState(
    createHotelUIKey(hotelID),
    { loading: false, error: false, completed: false, message: null, ...overrides }
);

export function* getReviewsWorker ({ payload: hotelID }) {
    yield put(createLoadingAction({ loading: true }, hotelID));

    try {
        const reviews = yield call(getTurpravdaHotelInformer, hotelID);

        yield put(actions.getReviewsSuccess(hotelID, reviews));
        yield put(createLoadingAction({ loading: false, completed: true }, hotelID));
    } catch (error) {
        yield put(actions.getReviewsFail(error));
        yield put(createLoadingAction(
            { error: true, message: i18n.t('PRICE_MODAL_GALLERY:FAIL_MESSAGE'), loading: false, completed: true },
            hotelID
        ));
    }
}
