import { createSelector } from "reselect";

const domain = (_) => _.queries;

const getQueryID = (_, { queryID }) => queryID;
const getParam = (_, { param }) => param;

export const getQuery = createSelector(
    [domain, getQueryID],
    (queries, queryID) => queries.get(queryID)
);

export const getQueryParam = createSelector(
    [getQuery, getParam],
    (query, param) => query.get(param)
);
