import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Spinner from 'components/ui/Spinner';

import { pageLoaded } from './store/actions';

export default () => {
    const dispatch = useDispatch();

    useEffect(() => () => dispatch(pageLoaded()), []);

    return <Spinner />;
};
