export const getNounForNumeral = (number, titles) => {
    const cases = [2, 0, 1, 1, 1, 2];

    return titles[number%100>4 && number%100<20? 2 : cases[number%10<5?number%10:5]];
};


export const dispatchGAEvent = (command, trackerName, pluginName, method) => {
    if (window.ga) {
        return window.ga(command, trackerName, pluginName, method);
    }
};

export const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
