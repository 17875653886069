import { takeEvery } from 'redux-saga/effects';

// actions
import { weatherActions as actions } from 'bus/weather';

// workers
import { getWeatherWorker } from './workers/getWeather';

export const weatherWatchers = Object.freeze({
    * getWeatherWatcher () {
        yield takeEvery(actions.getWeather, getWeatherWorker);
    },
});
