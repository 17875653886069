import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DESKTOP_BREAK_POINT, MOBILE_BREAK_POINT, SMALL_TABLET_BREAK_POINT } from "containers/media/constants";

import { DESKTOP, MOBILE, TABLET } from "./store/constants";
import { setDeviceType } from "./store/actions";
import { getDeviceType } from "./store/selectors";

const DeviceDetector = ({ children }) => {
    const dispatch = useDispatch();
    const device = useSelector(getDeviceType);

    useEffect(() => {
        const onChange = (deviceType) => ({ matches }) => matches && dispatch(setDeviceType(deviceType));

        const mobileOnChange = onChange(MOBILE);
        const tabletOnChange = onChange(TABLET);
        const desktopOnChange = onChange(DESKTOP);

        const mobileMedia = matchMedia(MOBILE_BREAK_POINT);
        const tabletMedia = matchMedia(SMALL_TABLET_BREAK_POINT);
        const desktopMedia = matchMedia(DESKTOP_BREAK_POINT);

        mobileMedia.addListener(mobileOnChange);
        tabletMedia.addListener(tabletOnChange);
        desktopMedia.addListener(desktopOnChange);

        return () => {
            mobileMedia.removeListener(mobileOnChange);
            tabletMedia.removeListener(tabletOnChange);
            tabletMedia.removeListener(desktopOnChange);
        };
    }, []);

    return device && children;
};

export default DeviceDetector;
