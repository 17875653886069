import React, { memo } from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';

import { reviewsActions as actions } from 'bus/reviews/actions';
import { getUITripadvisorReviews, getTripadvisorEntityDescription, getTripadvisorEntityReviews } from 'bus/reviews/selectors';
import Spinner from 'components/ui/Spinner';

import Header from './components/Header';
import ReviewItem from './components/ReviewItem';

import styles from './styles/index.scss';


const TripadvisorReviews = ({ hotelID }) => {
    const ui = useSelector((state) => getUITripadvisorReviews(state, { hotelID }));
    const description = useSelector((state) => getTripadvisorEntityDescription(state, { hotelID }));
    const reviews = useSelector((state) => getTripadvisorEntityReviews(state, { hotelID }));

    const { t } = useTranslation('TRIPADVISOR_REVIEWS');

    const dispatch = useDispatch();

    const getNextReviewsPage = () => dispatch(actions.getTripadvisorReviews(hotelID, description.page + 1));


    return (
        <div className = { styles.root }>
            <Header name = { description.name } rate = { description.rate } />
            <div className = { styles.list }>
                {R.map(
                    ({ author, datePublished, rate, title, description: desc, dateStay, id }) => (
                        <ReviewItem
                            author = { author }
                            datePublished = { datePublished }
                            dateStay = { dateStay }
                            description = { desc }
                            key = { id }
                            rate = { rate }
                            title = { title }
                        />
                    ),
                    reviews
                )}
            </div>
            <div className = { styles.more }>
                { ui.loading && <Spinner /> }
                { reviews.length < description.count && !ui.error && !ui.loading
                    ? <button type = 'button' onClick = { getNextReviewsPage } >{ t('MORE_REVIEWS_BTN') }</button>
                    : null
                }
            </div>
        </div>
    );
};

TripadvisorReviews.propTypes = {
    hotelID: T.string.isRequired,
};

export default memo(TripadvisorReviews);
