import React, { memo, useMemo } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { geoSelectors } from '@otpusk/apisearch-toolbox/dist/geo';

const timeToMomentWithTimezone = (timezone) => (string) => moment.tz(string, timezone);

const toUTC = (time) => time.utc();

const createBinaryNumber = (number) => R.call(
    R.when(
        (v) => R.lt(v, 10),
        (v) => `0${v}`
    ),
    number
);

const createFlightDuration = (flight, beginPort, endPort) => R.call(
    R.pipe(
        R.over(R.lensProp('begin'), R.compose(toUTC, timeToMomentWithTimezone(R.prop('timezone', beginPort)))),
        R.over(R.lensProp('end'), R.compose(toUTC, timeToMomentWithTimezone(R.prop('timezone', endPort)))),
        ({ begin, end }) => Math.abs(end.diff(begin, 'seconds')),
        (seconds) => moment.duration(seconds, 'seconds'),
        (duration) => [duration.hours(), duration.minutes()],
        R.map(createBinaryNumber),
        R.join(':')
    ),
    flight
);

const Duration = ({ begin, end, portFr, portTo }) => {
    const [getPortFrom, getPortTo] = useMemo(
        () => [portFr, portTo].map((iata) => R.partialRight(geoSelectors.getFlightPort(), [{ iata }])),
        [portFr, portTo]
    );

    const portFrom = useSelector(getPortFrom);
    const port2 = useSelector(getPortTo);

    const flightDuration = useMemo(
        () => R.and(portFrom, port2) && createFlightDuration({ begin, end }, portFrom, port2),
        [begin, end, portFrom, port2]
    );

    return (
        <nobr>
            { flightDuration }
        </nobr>
    );
};

Duration.propTypes = {
    begin:  T.string.isRequired,
    end:    T.string.isRequired,
    portFr: T.string.isRequired,
    portTo: T.string.isRequired,
};

export default memo(Duration);
