import React, { memo } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import styles from './styles';

const Item = ({ author, img, review, active }) => {
    const { t } = useTranslation('REVIEWS_CAROUSEL');

    return (
        <div className = { classnames(styles.slideWrapper, { [styles.activeSlide]: active }) }>
            <div className = { styles.review }>
                <div className = { styles.reviewCol1 }>
                    <div className = { styles.reviewPerson }>
                        <div
                            className = { styles.reviewName }
                            dangerouslySetInnerHTML = { { __html: author } }
                        />
                        <div className = { styles.reviewPhoto } style = { { backgroundImage: `url("${img}")` } } />
                    </div>
                </div>
                <div className = { styles.reviewCol2 }>
                    <div
                        className = { styles.reviewText }
                        dangerouslySetInnerHTML = { { __html: review } }
                    />
                    <div className = { styles.reviewButtonWrapper }>
                        <div className = { styles.reviewButtonSlogan }>{ t('BEAUTIFUL_MESSAGE') }</div>
                        <div className = { styles.reviewButton }>{ t('ORDER_BUTTON') }</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Item.propTypes = {
    author: T.string.isRequired,
    img:    T.string.isRequired,
    review: T.string.isRequired,
};

export default memo(Item);
