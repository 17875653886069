import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { QUERY_PARAMS } from '@otpusk/apisearch-toolbox/dist/queries/fn';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

import { prefetchNextPage, setShouldPrefetchRepeat } from 'providers/PrefetchNextPage/store/actions';
import { isPrefetchNextPage as isPrefetchNextPageSelector } from 'providers/PrefetchNextPage/store/selectors';
import { useResultsVisible } from 'providers/ResultsVisible';

const isShowButton = ({ countHotels, countOffers }) => Boolean(countHotels) && Boolean(countOffers) && countHotels > 1;

const withShowMoreProps = (Component) => {
    const WithShowMoreProps = ({ queryId }) => {
        const dispatch = useDispatch();
        const counts = useSelector(({ search }) => {
            const hotels =  search.getIn(['results', queryId, 'hotels']);

            if (!hotels) {
                return { countOffers: 0, countHotels: 0 };
            }

            return hotels.toList().reduce((results, hotelList) => {
                results.countHotels += hotelList.size;
                results.countOffers += hotelList.toList().reduce((countOffers, offerList) => {
                    return countOffers + offerList.length;
                }, 0);

                return results;
            }, { countHotels: 0, countOffers: 0 });
        });
        const queryActivePage = useSelector((state) => state.queries.getIn([queryId, QUERY_PARAMS.PAGE]));
        const isPrefetchNextPage = useSelector(isPrefetchNextPageSelector(queryId));

        const { t } = useTranslation('PRICES_LIST');

        const { activePage, isVisible, setShow, setActivePage } = useResultsVisible(queryId);

        const showNextPage = useCallback(
            () => (
                setActivePage(activePage + 1),
                isPrefetchNextPage
                    ? dispatch(setShouldPrefetchRepeat(true))
                    : dispatch(prefetchNextPage(queryId))
            ),
            [activePage, dispatch, isPrefetchNextPage, setActivePage, queryId]
        );

        const shoMoreProps = useMemo(
            () => ({
                children: t(
                    isVisible ? 'SHOW_MORE_SEACH_BUTTON' : 'SHOW_MORE_COLLAPSE_BUTTON'
                ),
                onClick: isVisible
                    ? showNextPage
                    : setShow,
            }),
            [isVisible, showNextPage, setShow, t]
        );

        const isShow = useMemo(
            () => R.and(
                isShowButton(counts),
                activePage < queryActivePage
            ),
            [activePage, counts, queryActivePage]
        );

        return isShow && <Component { ...shoMoreProps } />;
    };

    return WithShowMoreProps;
};

export default withShowMoreProps;
