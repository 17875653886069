import { createSelector } from 'reselect';
import * as R from 'ramda';
import { DESKTOP, MOBILE, TABLET } from './constants';


const domain = (_) => _.deviceDetector;

export const getDeviceType = createSelector(
    domain,
    R.prop('type')
);

export const isMobile = createSelector(
    getDeviceType,
    R.equals(MOBILE)
);

export const isDesktop = createSelector(
    getDeviceType,
    R.equals(DESKTOP)
);


export const isTablet = createSelector(
    getDeviceType,
    R.equals(TABLET)
);
