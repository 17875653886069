import { createSelector } from 'reselect';
import * as R from 'ramda';

import { DEFAULT_COUNTRY_ID } from 'helpers/staticData';

import { EMPTY_COUNTRY_ID } from './constants';

const EMPTY_OBJ = {};

const domain = (_) => _.hot;
const getId = (_, { id }) => id;
const getHotelID = (_, { hotelID }) => hotelID;
const getBlockID = (_, { blockID }) => blockID;

const getHotCountries = createSelector(
    domain, (hot) => hot.get('countries')
);

const getHotCountryList = createSelector(
    domain, (hot) => hot.get('country')
);

const getUi = createSelector(
    domain, (hot) => hot.get('ui')
);

export const getHotCountriesByCountry = () => createSelector(
    getHotCountries,
    getId,
    (map, id) => map[id]
);

export const getHotCountriesByEmptyCountry = createSelector(
    getHotCountries,
    (map) => map[EMPTY_COUNTRY_ID]
);

export const getCountryByBlockID = () => createSelector(
    getHotCountriesByEmptyCountry,
    getBlockID,
    (countries, id) => R.find(
        R.propEq('hotBlockID', id),
        countries
    )
);

export const getCountry = () => createSelector(
    getHotCountriesByEmptyCountry,
    getId,
    (countries, id) => R.find(
        R.propEq('otpuskID', id),
        countries
    )
);

export const getDefaultCountry = createSelector(
    getHotCountriesByEmptyCountry,
    (countries) => R.call(
        R.pipe(
            R.sort((a, b) => a.size - b.size),
            R.head
        ),
        countries
    )
);

export const getHotCountriesUIByCountry = () => createSelector(
    getUi,
    getId,
    (map, id) => R.path(['countries', id], map)
);

export const getHotToursUIByBlock = () => createSelector(
    getUi,
    getBlockID,
    (map, blockID) => R.pathOr(EMPTY_OBJ, ['hotTours', blockID], map)
);

export const getHotCountry = () => createSelector(
    getHotCountryList, getId,
    (hotCountries, id) => R.prop(id, hotCountries)
);

export const getDefaultHotCountry = createSelector(
    getHotCountryList,
    (hotCountries) => R.prop(DEFAULT_COUNTRY_ID, hotCountries)
);

export const getHotCountryExcursions = createSelector(
    getHotCountryList, getId,
    (hotCountries, id) => R.path([id, 'excursions'], hotCountries)
);

export const getDefaultHotCountryExcursions = createSelector(
    getHotCountryList,
    (hotCountries) => R.path([DEFAULT_COUNTRY_ID, 'excursions'], hotCountries)
);

const getInstructions = createSelector(
    domain, (hot) => hot.get('instructions')
);

export const getHotCountriesInstructions = () => createSelector(
    getInstructions,
    getId,
    (instructionsHub, countryID) => {
        const { [countryID]: instructions } = instructionsHub;

        return instructions
            ? R.call(
                R.pipe(
                    R.filter(
                        ({ description, youtube, placeholder }) => description && youtube && placeholder
                    ),
                    R.sort((a) => a.otpuskID === countryID ? -1 : 1)
                ),
                instructions
            )
            : [];
    }
);

export const getHotBlocksStore = createSelector(
    domain,
    (hot) => hot.get('store')
);

const getHotelsFromHotBlocks = createSelector(
    getHotBlocksStore,
    (store) => R.call(
        R.pipe(
            R.values,
            R.map(R.values),
            R.flatten,
            R.map(({ hotelId, tour }) => R.mergeAll([
                { id: hotelId }, tour
            ]))
        ),
        store.toJS()
    )
);

export const getHotelByID = () => createSelector(
    getHotelsFromHotBlocks,
    getHotelID,
    (hotels, hotelID) => R.find(
        ({ id }) => id === hotelID,
        hotels
    )
);

const getHotBlockByID = () => createSelector(
    getHotBlocksStore,
    getBlockID,
    (store, blockID) => R.propOr(EMPTY_OBJ, blockID, store.toJS())
);

export const getHotelFromHotBlock = () => createSelector(
    getHotBlockByID(),
    getHotelID,
    (block, hotelID) => block[hotelID]
);
