import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Styles from './styles.scss';

const Spinner = () => {
    const { t } = useTranslation('SPINNER');

    return <div className = { Styles.loader }>{ t('LOADING_TITLE') }</div>;
};

export default memo(Spinner);
