// Core
import React, { memo } from 'react';
import T from 'prop-types';
import cx from 'classnames';

// instruments
import Styles from './styles.scss';

const UahPrice = ({ children, className }) => (
    <div
        className = { cx(Styles.root, className) }
        dangerouslySetInnerHTML = { { __html: children.toString().replace(/(\d)(.{3})$/, '$1<span>$2</span>') } }
    />
);

UahPrice.propTypes = {
    children:  T.node.isRequired,
    className: T.string,
};

export default memo(UahPrice);
