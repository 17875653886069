import { handleActions } from 'redux-actions';

import { setShouldPrefetchRepeat } from './actions';

const initialState = {
    shouldPrefetchRepeat: false,
};

export default handleActions({
    [setShouldPrefetchRepeat]: (state, { payload: should }) => ({
        ...state, shouldPrefetchRepeat: should,
    }),
}, initialState);
