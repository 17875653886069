// Core
import React from 'react';
import { render } from 'react-dom';
import { cacheStorage } from '@otpusk/json-api/dist/cache';
import * as R from 'ramda';

// App
import App from './App';

cacheStorage
    .findAll()
    .then(R.pipe(
        R.toPairs,
        R.map(([cacheKey, { value }]) => R.set(
            R.lensProp('cacheKey'),
            cacheKey,
            value
        )),
        R.filter(R.prop('countries')),
        R.map(({ cacheKey }) => cacheStorage.remove(cacheKey)),
        (promises) => Promise.all(promises),
        (promise) => promise.finally(() => render(
            <App />,
            document.getElementById('root')
        ))
    ));
