import React, { memo } from 'react';
import T from 'prop-types';
import classnames from "classnames";
import classnamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import UahPrice from 'components/ui/UahPrice';

import styles from './styles.scss';

const CASHBACK_PERCENT = 20;

const computeCashback = (price) => Math.ceil(price * CASHBACK_PERCENT / 100);

const PriceItem = ({ className, isUahPrice, title, price }) => (
    <span className = { classnames(styles.priceValue, className) }>
        { title }
        { isUahPrice ? (
            <UahPrice className = { styles.uahPrice }>
                { price }
            </UahPrice>
        ) : (
            <span>{ price }</span>
        ) }
    </span>
);

const MaxPrice = ({ cashback, currencyLabel, isUahPrice, price, discount }) => {
    const { t } = useTranslation('PRICES_LIST');

    return (
        <div className = { classnames(styles.priceSale, { [styles.hasFireBadge]: discount > 40 }) }>
            <PriceItem
                className = { classnames(styles.cashback, { [styles.cashbackUa]: isUahPrice }) }
                isUahPrice = { isUahPrice }
                price = { cashback }
                title = { (
                    <span
                        className = { styles.cashbackTitle }
                        dangerouslySetInnerHTML = { { __html: t('CASHBACK_GIFT') } }
                    />
                ) }
            />
            <PriceItem
                isUahPrice = { isUahPrice }
                price = { price }
                title = { (
                    <span className = { isUahPrice ? styles.currencySupUah : styles.currencySup }>
                        { currencyLabel }
                    </span>
                ) }
            />
            { Boolean(discount) && (
                <span className = { classnames(styles.saleBadge, { [styles.fireBadge]: discount > 40 }) }>
                    <span className = { styles.saleBadgeValue }>
                        -
                        { discount }%
                    </span>
                </span>
            ) }
        </div>
    );
};

const priceRootClasses = classnamesBind.bind({
    discount: styles.hasDiscount,
    maxPrice: styles.hasMaxPrice,
});

const Price = ({
    activeCurrencyCode,
    originalPrice,
    isUahPrice,
    maxPrice,
    currencyLabel,
    price,
    isAllowDiscount,
    discountPrice,
    discount,
}) => {
    const { t } = useTranslation(['PRICES_LIST']);

    return (
        <span className = { classnames(styles.priceContainer, styles.hasCashback, priceRootClasses({ discount, maxPrice })) }>
            { Boolean(maxPrice) && (
                <MaxPrice
                    cashback = { computeCashback(price) }
                    currencyLabel = { currencyLabel }
                    discount = { discount }
                    isUahPrice = { isUahPrice }
                    price = { maxPrice }
                />
            ) }
            { isAllowDiscount && discountPrice
                ? (
                    <MaxPrice
                        cashback = { computeCashback(price) }
                        currencyLabel = { currencyLabel }
                        discount = { discount }
                        isUahPrice = { isUahPrice }
                        price = { Math.ceil(originalPrice[activeCurrencyCode]) }
                    />
                )
                : (
                    <div className = { classnames(styles.priceSale, styles.onlyCashback) }>
                        <PriceItem
                            className = { classnames(styles.cashback, { [styles.cashbackUa]: isUahPrice }) }
                            isUahPrice = { isUahPrice }
                            price = { computeCashback(price) }
                            title = { (
                                <div
                                    className = { styles.cashbackTitle }
                                    dangerouslySetInnerHTML = { { __html: t('CASHBACK_GIFT') } }
                                />
                            ) }
                        />
                    </div>
                ) }
            <span className = { styles.price }>
                <span className = { styles.priceValue }>
                    <span className = { classnames({ [styles.currencySup]: !isUahPrice, [styles.currencySupUah]: isUahPrice }) }>
                        { currencyLabel }
                    </span>
                    { isUahPrice ? (
                        <UahPrice className = { styles.uahPrice }>{ price }</UahPrice>
                    ) : price }
                </span>
                <span className = { styles.priceDesc }>
                    { t('BUY') }
                </span>
            </span>
        </span>
    );
};

Price.propTypes = {
    discount: T.number,
};

export default memo(Price);
