import { takeEvery } from 'redux-saga/effects';

// actions
import { hotActions as actions } from 'bus/hot';

// workers
import { getHotToursWorker } from './workers/getHotTours';
import getHotCountriesWorker from './workers/getHotCountries';

export const hotWatchers = Object.freeze({
    * getHotTours () {
        yield takeEvery(actions.getHotTours, getHotToursWorker);
    },
    * getHotCountriesWatcher () {
        yield takeEvery(actions.getHotCountries, getHotCountriesWorker);
    },
});
