import React, { memo } from 'react';
import * as R from 'ramda';
import T from 'prop-types';

import withShowMoreProps from './withProps';
import styles from './styles.scss';

const ShowMore = ({ children, onClick }) => (
    <div className = { styles.root }>
        <button className = { styles.button } type = 'button' onClick = { onClick }>
            { children }
        </button>
    </div>
);

ShowMore.propTypes = {
    children: T.node,
    onClick:  T.func,
};

const enhancer = R.compose(withShowMoreProps, memo);

export default enhancer(ShowMore);
export { ShowMore as ShowMorePure };
