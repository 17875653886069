import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { hotelsSelectors } from '@otpusk/apisearch-toolbox/dist/hotels';
import * as R from 'ramda';

import translit from 'helpers/transliteration';

const { getHotel } = hotelsSelectors;

const isFortuna = (hotelName) => hotelName.includes('Фортуна');

export const OfferAndHotelProviderHot = memo(({ children, item }) => {
    const hotelSelector = useMemo(
        () => R.partialRight(getHotel(), [item.hotelId]),
        [item.hotelId]
    );
    const rootHotel = useSelector(hotelSelector);

    const hotel = useMemo(() => {
        const { tour, hotelId } = item;

        if (tour) {
            return {
                'country':  tour.country,
                'city':     tour.city,
                'code':     isFortuna(tour.name) ? translit(tour.name) : tour.name,
                'name':     isFortuna(tour.name) ? translit(tour.name) : tour.name,
                'stars':    tour.stars,
                'photos':   rootHotel ? rootHotel.photos : tour.photos,
                'id':       hotelId,
                'location': tour.location,
                'services': null,
            };
        }

        return null;
    }, [item, rootHotel]);
    const offer = useMemo(() => {
        const { tour, offerId } = item;

        if (tour) {
            return {
                'children':    0,
                'adults':      tour.offer.adults || 2,
                'price':       tour.offer.price,
                'transport':   tour.offer.transport,
                'date':        tour.offer.date,
                'nights':      tour.offer.nights,
                'food':        tour.offer.food,
                'operator':    tour.offer.operator,
                'childrenAge': '',
                'departure':   tour.offer.departure,
                'days':        tour.offer.days,
                'id':          offerId,
                'divider':     tour.offer.divider,
                'flights':     { inbound: tour.offer.inbound, outbound: tour.offer.outbound },
                'currency':    tour.offer.currency,
            };
        }

        return null;
    }, [item]);

    return children(offer, hotel);
});
