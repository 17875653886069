import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import * as R from 'ramda';

import useActiveCurrency from 'providers/Currency/useActiveCurrency';

import { generateDiscountKey } from './helpers';

const getChart = () => createSelector(
    (_) => _.search.get('charts'),
    (_, { key }) => key, (map, key) => map.get(key)
);

const getPriceByAltCurrency = (price) => R.call(
    R.pipe(R.toPairs, R.find(([currency]) => currency !== 'uah'), ([, number]) => number),
    price
);

export default (hotel, offer) => {
    const { divider } = offer;

    const discountKey = useMemo(
        () => generateDiscountKey(hotel, offer),
        [hotel, offer]
    );
    const activeCurrency = useActiveCurrency();

    const graphSelector = useMemo(
        () => R.partialRight(getChart(), [{ key: discountKey }]),
        [discountKey]
    );
    const graph = useSelector(graphSelector);

    const maxPrice = useMemo(
        () => R.call(
            R.when(Boolean, R.pipe(
                R.filter(R.prop('price')),
                R.map(({ price }) => price[activeCurrency] || getPriceByAltCurrency(price)),
                (prices) => Math.max(...prices),
                (price) => divider ? price / divider : price,
                Math.ceil
            )),
            graph
        ),
        [graph, activeCurrency, divider]
    );

    const offerPrice = useMemo(
        () => R.call(
            (price, currency) => price[currency] || getPriceByAltCurrency(price),
            offer.price, activeCurrency
        ),
        [offer, activeCurrency]
    );

    const discount = useMemo(
        () => R.call(
            R.ifElse(
                (a, b) => a && b,
                (max, defaultPrice) => Math.ceil((max - defaultPrice) * 100 / max),
                R.always(null)
            ),
            maxPrice, offerPrice
        ),
        [maxPrice, offerPrice]
    );

    return { maxPrice: maxPrice > offerPrice ? maxPrice : undefined, discount: discount > 0 ? discount : undefined };
};
