import { select, put, race, take } from 'redux-saga/effects';
import { QUERY_PARAMS } from '@otpusk/apisearch-toolbox/dist/queries/fn';
import { queriesActions } from '@otpusk/apisearch-toolbox/dist/queries';
import { searchActions } from '@otpusk/apisearch-toolbox/dist/search';

// instruments
import { graphActions } from 'bus/graph';
import { getQuery, getQueryParam } from 'bus/queries/selectors';

export function* getGraphWorker ({ payload }) {
    const { queryID, baseQueryID, params, days }= payload;
    const query = yield select((state) => getQuery(state, { queryID }));
    const baseQuery = yield select((state) => getQuery(state, { queryID: baseQueryID }));

    if (!query) {
        yield put(queriesActions.createQuery(queryID, baseQuery.merge(params).toObject()));
    } else {
        yield put(queriesActions.changeQueryParams(queryID, baseQuery.toObject()));
    }

    if (params) {
        yield put(queriesActions.changeQueryParams(queryID, params));
    }

    const autostart = yield select((state) => getQueryParam(state, { queryID, param: QUERY_PARAMS.AUTOSTART }));


    if (autostart) {
        yield put(graphActions.setUi(
            { loading: true, error: false, message: null, completed: false },
            [queryID]
        ));
        yield put(searchActions.getPriceChart(queryID, days));

        const [success, fail] = yield race([
            take(searchActions.getPriceChartSuccess),
            take(searchActions.getPriceChartFail)
        ]);

        if (fail) {
            yield put(graphActions.setUi(true, [queryID, 'error']));
            yield put(graphActions.setUi('Помилка при завантаженні', [queryID, 'message']));
        }

        if (success) {
            // if you want =)
        }

        yield put(graphActions.setUi(false, [queryID, 'loading']));
        yield put(graphActions.setUi(true, [queryID, 'completed']));
    }

}
