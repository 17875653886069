import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getHotel } from '@otpusk/apisearch-toolbox/dist/hotels/selectors';
import * as R from 'ramda';
import T from 'prop-types';

import { getHotelByID } from 'bus/hot/selectors';

import Modal from 'components/ui/Modal';
import Map from 'components/ui/Map';

import styles from './styles.scss';

const MapModal = ({ hotelID, title, onClose, onClick }) => {
    const hotelSelector = useMemo(
        () => R.partialRight(getHotel(), [hotelID]),
        [hotelID]
    );
    const hotHotelSelector = useMemo(
        () => R.partialRight(getHotelByID(), [{ hotelID }]),
        [hotelID]
    );
    const hotel = useSelector(hotelSelector);
    const hotHotel = useSelector(hotHotelSelector);

    const { location, name: hotelName } = hotel || hotHotel;

    return (
        <>
            { location
                ? (
                    <Modal header = { title } onClick = { onClick } onClose = { onClose }>
                        <div className = { styles.modalMap } >
                            <div className = { styles.modalBody }>
                                <div className = { styles.mapBody }>
                                    <Map
                                        hotelName = { hotelName }
                                        position = { { lat: location.lat, lng: location.lng } }
                                        zoom = { location.zoom }
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                )
                : 'Map is not available'
            }
        </>
    );
};

MapModal.propTypes = {
    hotelID: T.string.isRequired,
    title:   T.string,
    onClick: T.func,
    onClose: T.func,
};

MapModal.defaultProps = {
    title:   'Готель на карті',
    onClose: () => {},
    onClick: () => {},
};

export default memo(MapModal);
