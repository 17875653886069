import { put, select, race, take, all } from 'redux-saga/effects';
import { queriesActions } from '@otpusk/apisearch-toolbox/dist/queries';
import { QUERY_PARAMS } from '@otpusk/apisearch-toolbox/dist/queries/fn';
import { searchActions } from '@otpusk/apisearch-toolbox/dist/search';
import moment from 'moment';
import { Map } from 'immutable';

// instruments
import { uiActions } from 'bus/ui';
import { hotelPricesActions } from 'bus/hotelPrices';

const BASE_UI_PATH = ['hotelPricesUI', 'fetch'];

const compileDatesParams = (offer) => {
    const DURATION = 10;
    const CENTER_DURATION_POSITION = 6;

    const { date } = offer;
    const momentNow = moment().startOf('day');
    const momentDate = moment(date, 'YYYY-MM-DD').startOf('day');

    const diff = momentDate.diff(momentNow, 'days');
    const from = momentDate.clone().subtract(diff < CENTER_DURATION_POSITION ? diff : CENTER_DURATION_POSITION - 1, 'days');

    return Map({ from, to: from.clone().add(DURATION - 1, 'days') });
};

export function* getHotelPricesWithGraphWorker ({ payload }) {
    const { entityID, hotelID, offerID } = payload;

    yield put(uiActions.setUiState([...BASE_UI_PATH, String(entityID)], {
        prices: { loading: true, error: false, completed: false, message: null },
        graph:  { loading: true, error: false, completed: false, message: null },
    }));

    const offer = yield select(({ offers }) => offers.getIn(['store', String(offerID)]));
    const hotel = yield select(({ hotels }) => hotels.getIn(['store', String(hotelID)]));

    const owerrides = {
        [QUERY_PARAMS.DATES]:    compileDatesParams(offer),
        [QUERY_PARAMS.DURATION]: Map({
            from: 3,
            to:   11,
        }),
    };

    const pricesEntityID = `${entityID}--prices`;
    const graphEntityID = `${entityID}--graph`;

    yield put(queriesActions.createQueryFromOffer(pricesEntityID, hotel, offer, owerrides));
    yield put(queriesActions.createQueryFromOffer(graphEntityID, hotel, offer));
    yield put(searchActions.getPriceChart(graphEntityID, 90));
    yield put(searchActions.runSearch(pricesEntityID));

    const [priceChartFetching, searchFetching] = yield all([
        race([take(searchActions.getPriceChartSuccess), take(searchActions.getPriceChartFail)]),
        race([take(searchActions.finishSearch), take(searchActions.failSearch)])
    ]);

    if (priceChartFetching) {
        const [success, fail] = priceChartFetching;

        if (success) {
            yield put(hotelPricesActions.getHotelPricesSuccess());
        }

        if (fail) {
            yield put(hotelPricesActions.getHotelPricesFail(fail.payload));
            yield put(uiActions.setUiState(
                [...BASE_UI_PATH, String(entityID), 'graph', 'error'],
                true
            ));
            yield put(uiActions.setUiState(
                [...BASE_UI_PATH, String(entityID), 'graph', 'message'],
                'Помилка при завантаженні цін'
            ));
        }
    }

    if (searchFetching) {
        const [success, fail] = searchFetching;

        if (success) {
            yield put(hotelPricesActions.getHotelGraphSuccess());
        }

        if (fail) {
            yield put(hotelPricesActions.getHotelGraphFail(fail.payload));
            yield put(uiActions.setUiState(
                [...BASE_UI_PATH, String(entityID), 'prices', 'error'],
                true
            ));
            yield put(uiActions.setUiState(
                [...BASE_UI_PATH, String(entityID), 'prices', 'message'],
                'Помилка при завантаженні цін'
            ));
        }
    }

    yield put(uiActions.setUiState(
        [...BASE_UI_PATH, String(entityID), 'graph', 'loading'],
        false
    ));
    yield put(uiActions.setUiState(
        [...BASE_UI_PATH, String(entityID), 'graph', 'completed'],
        true
    ));
    yield put(uiActions.setUiState(
        [...BASE_UI_PATH, String(entityID), 'prices', 'loading'],
        false
    ));
    yield put(uiActions.setUiState(
        [...BASE_UI_PATH, String(entityID), 'prices', 'completed'],
        true
    ));
}
