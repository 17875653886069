import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { queriesActions } from '@otpusk/apisearch-toolbox/dist/queries';
import { searchActions } from '@otpusk/apisearch-toolbox/dist/search';
import T from 'prop-types';
import * as R from 'ramda';

import useDiscountData from './useDiscountData';
import { generateDiscountKey, modifyOffer, getGraphDuration } from './helpers';

const { createQueryFromOffer } = queriesActions;
const { getPriceChart } = searchActions;

const isSetQuery = () => createSelector(
    (_) => _.queries, (_, { queryID }) => queryID,
    (map, id) => Boolean(map.get(id))
);

export default (Component) => {
    const WithDiscountComponent = (props) => {
        const { offer, hotel } = props;

        const dispatch = useDispatch();

        const discountKey = useMemo(
            () => generateDiscountKey(hotel, offer),
            [hotel, offer]
        );

        const isSetQuerySelector = useMemo(
            () => R.partialRight(isSetQuery(), [{ queryID: discountKey }]),
            [discountKey]
        );
        const isSet = useSelector(isSetQuerySelector);

        const discount = useDiscountData(hotel, offer);

        useEffect(() => {
            if (!isSet) {
                dispatch(createQueryFromOffer(discountKey, hotel, modifyOffer(offer)));
                dispatch(getPriceChart(discountKey, getGraphDuration()));
            }
        }, [dispatch, isSet]);

        return (
            <Component { ...props } { ...discount } />
        );
    };

    WithDiscountComponent.propTypes = {
        hotel: T.object.isRequired,
        offer: T.object.isRequired,
    };

    return WithDiscountComponent;
};
