import * as R from 'ramda';
import i18n from 'i18next';

import { getNounForNumeral } from 'helpers/fn';

import {
    DISCOUNT_PRICE_LIMIT_UAH,
    CITIES_WITHOUT_RU_PEOPLE
} from './constants';

const isSetPlaceNumber = (place) => Boolean(place) && isFinite(
    Number(place)
);

export const generateAdditionalInformation = (offer, cityID) => {
    const outbound = R.pathOr({}, ['flights', 'outbound', 0], offer);

    switch (true) {
        case R.path(['seats', 'value'], outbound) === 'few' && isSetPlaceNumber(R.prop('place', outbound)): {
            const places = Math.ceil(R.prop('place', outbound) / 2);

            return {
                icon: 'fire',
                text: places !== 0
                    ? `${i18n.t(places === 1 ? 'PRICES_LIST:REMAINS_ONE_TOUR' : 'PRICES_LIST:REMAINS_ONLY', { places })} ${getNounForNumeral(places, i18n.t('PLURAL_WORDS:TOURS', { returnObjects: true }))}`
                    : i18n.t('PRICES_LIST:REMAINS_ONE_PLACE'),
            };
        }
        case CITIES_WITHOUT_RU_PEOPLE.includes(cityID): return {
            icon: 'gift',
            text: i18n.t('PRICES_LIST:WITHOUT_RUSSIANS'),
        };
        case R.path(['discountPrice', 'uah'], offer) >= DISCOUNT_PRICE_LIMIT_UAH: return {
            icon: 'gift',
            text: i18n.t('PRICES_LIST:HOUSEHOLD_APPLIANCES_GIFT'),
        };
        default: return {
            icon: 'gift',
            text: i18n.t('PRICES_LIST:TOURIST_ORGANIZER'),
        };
    }
};
