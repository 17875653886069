import React, { memo, useEffect, useRef } from 'react';
import T from 'prop-types';

import styles from './styles.scss';

const BookingLink = ({ children, href, onClick }) => {
    const linkRef = useRef(null);
    const isTriggerProggramClick = useRef(false);

    useEffect(() => {
        if (href && !isTriggerProggramClick.current) {
            linkRef.current.click();
            isTriggerProggramClick.current = true;
        }
    }, [href]);

    return (
        <a
            className = { styles.bookingUrl }
            href = { href }
            ref = { linkRef }
            rel = 'noopener noreferrer'
            target = '_blank'
            onClick = { onClick }>
            { children }
        </a>
    );
};

BookingLink.propTypes = {
    href:     T.string.isRequired,
    children: T.any,
    onClick:  T.func,
};

export default memo(BookingLink);
