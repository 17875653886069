import React, { useMemo, memo } from 'react';
import T from 'prop-types';
import * as R from 'ramda';

import { useSelectServices } from 'containers/molecules/Services/SelectServices';

import { Badge } from 'components/ui/Badge';

import styles from './modal.scss';

const PhotoSlide = ({ services }) => {
    const { services: servicesGroups, labels } = useSelectServices();

    const recommendedGroup = useMemo(
        () => R.call(
            R.ifElse(
                (a, b) => a && b,
                R.pipe((list) => list.toArray(), R.filter((code) => R.includes(code, services))),
                R.always(null)
            ),
            servicesGroups.get('recommend', null), services
        ),
        [servicesGroups, services]
    );

    return (
        <div className = { styles.imageBadges }>
            { recommendedGroup && recommendedGroup.map((code) => (
                <Badge
                    large
                    key = { code }
                    label = { labels.get(code) }
                    service = { code }
                />
            )) }
        </div>
    );
};

PhotoSlide.propTypes = {
    services: T.arrayOf(T.string),
};

export default memo(PhotoSlide);
