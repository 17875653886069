// Core
import { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from 'immutable';
import { offersActions } from '@otpusk/apisearch-toolbox/dist/offers';

const C = ({ children, offerID }) => {
    const [offer, status] = useSelector(({ offers }) => [
        offers.getIn(['store', String(offerID)], null),
        offers.getIn(['status', String(offerID)], null)
    ]);

    // state
    const [ui, setUI] = useState(Map({ loading: false, completed: false, success: false, error: false }));

    // computed
    const isDownloadFullOfferSuccess = offer?.bookingUrl;

    // methods
    const dispatch = useDispatch();
    const getOffer = useCallback(() => {
        setUI((uiMap) => uiMap.set('loading', true));
        dispatch(offersActions.getOffer(offerID));
    }, [dispatch, offerID]);
    const emptyFunc = useCallback(() => void 0, []);

    useEffect(() => {
        if (isDownloadFullOfferSuccess) {
            setUI((uiMap) => {
                return uiMap
                    .set('loading', false)
                    .set('completed', true)
                    .set('success', true);
            });
        }
    }, [isDownloadFullOfferSuccess]);

    useEffect(() => {
        if (status === 'expired') {
            setUI((uiMap) => {
                return uiMap
                    .set('loading', false)
                    .set('completed', true)
                    .set('error', true);
            });
        }
    }, [status]);

    // shared
    const shared = useMemo(() => ({
        ui:       ui.toObject(),
        getOffer: ui.get('loading') || ui.get('error') ? emptyFunc : getOffer,
        offer,
    }), [ui, getOffer, emptyFunc, offer]);

    return children(shared);
};

C.displayName = 'OfferProvider';

export const OfferProvider = memo(C);
