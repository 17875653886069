import { createActions } from 'redux-actions';

export const reviewsActions = createActions({
    GET_REVIEWS:         (hotelID) => hotelID,
    GET_REVIEWS_SUCCESS: (hotelID, reviews) => ({ hotelID, reviews }),
    GET_REVIEWS_FAIL:    (error) => error,

    GET_COMMON_REVIEWS:         () => void 0,
    GET_COMMON_REVIEWS_SUCCESS: (reviews) => reviews,
    GET_COMMON_REVIEWS_FAIL:    (error) => error,

    GET_TRIPADVISOR_REVIEWS:      (hotelID, page) => ({ hotelID, page }),
    SET_TRIPADVISOR_REVIEWS:      (hotelID, reviews, description) => ({ hotelID, reviews, description }),
    GET_TRIPADVISOR_REVIEWS_FAIL: (error) => error,
}, { prefix: 'ANTONIV_REVIEWS' });
