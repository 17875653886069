export const setOperatorsDirectionByOperatorControl = (operators) => {
    const direction = [
        "717",
        "2835",
        "269",
        "3021",
        "1416",
        "2700",
        "2",
        "3331",
        "759"
    ];

    return [
        ...operators
    ].sort((operatorA, operatorB) => {
        const indexA = direction.indexOf(operatorA.id);
        const indexB = direction.indexOf(operatorB.id);

        return (indexA > -1 ? indexA : Infinity) - (indexB > -1 ? indexB : Infinity);
    });
};
