import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { servicesActions } from '@otpusk/apisearch-toolbox/dist/services';
import { Map } from 'immutable';

export const useSelectServices = ({ countryId = '0' } = {}) => {
    const dispatch = useDispatch();
    const { services, labels } = useSelector((state) => ({
        services: state.services.getIn(['countries', countryId], Map()),
        labels:   state.services.get('labels', Map()),
    }));

    useEffect(() => {
        if (countryId && services.isEmpty()) {
            dispatch(servicesActions.getServices(countryId));
        }
    }, [countryId, services]);

    return { services, labels };
};
