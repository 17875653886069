import { call, put } from 'redux-saga/effects';

import { getRandomNumber } from 'helpers/fn';

import { getUserLocationFail, setUserLocation } from './actions';
import { GEO_IP_URL, GEO_TOKENS } from './constants';
import { getGeoIPFromCache, saveGeoIPToCache } from './helpers';

function* getUserLocation () {
    const savedLocation = getGeoIPFromCache();

    if (savedLocation) {
        const { city, country, ip } = JSON.parse(savedLocation);

        yield put(setUserLocation(city, country, ip));
    } else {
        try {
            const token = GEO_TOKENS[getRandomNumber(0, GEO_TOKENS.length - 1)];
            const response = yield call(fetch, `${GEO_IP_URL}?token=${token}`);

            if (!response.ok) {
                throw new Error(`HTTP ERROR: ${response.status}`);
            }
            const { city, country, ip } = yield call([response, 'json']);
            const normalizedCountry = country.toLowerCase();

            saveGeoIPToCache(city, normalizedCountry, ip);
            yield put(setUserLocation(city, normalizedCountry, ip));
        } catch (error) {
            yield put(getUserLocationFail(error));
        }
    }
}

export default getUserLocation;
