import { all } from 'redux-saga/effects';
import { geoWatchers } from '@otpusk/apisearch-toolbox/dist/geo';
import { servicesWatchers } from '@otpusk/apisearch-toolbox/dist/services';
import { searchWatchers } from '@otpusk/apisearch-toolbox/dist/search';
import { queriesWatchers } from '@otpusk/apisearch-toolbox/dist/queries';
import { hotelsWatchers } from '@otpusk/apisearch-toolbox/dist/hotels';
import { offersWatchers } from '@otpusk/apisearch-toolbox/dist/offers';
import { operatorsWatchers } from '@otpusk/apisearch-toolbox/dist/operators';

// instruments
import { reviewsWathcers } from 'bus/reviews';
import { graphWatchers } from 'bus/graph';
import { hotWatchers } from 'bus/hot';
import { bookingWatchers } from 'bus/booking';
import { weatherWatchers } from 'bus/weather';
import { commonWatcher } from 'bus/common';
import { hotelPricesWatchers } from 'bus/hotelPrices';

import prefetchNextPageWatcher from 'providers/PrefetchNextPage/store/saga';
import collectionsWatchers from 'components/controls/HotelsControl/components/Collection/store/saga/watchers';
import bootstrapDataForModalSaga from 'components/results/Hotel/Carousel/store/saga';
import bootstrapDataForHotToursSaga from 'widgets/hot/store/saga';
import initializeApp from 'components/bootstrap/store/saga/initializeApp';

const sagaRunner = (wathcers) => Object.keys(wathcers).map((wathcerKey) => wathcers[wathcerKey]());

export function* rootSaga () {
    yield all([
        ...sagaRunner(geoWatchers),
        ...sagaRunner(servicesWatchers),
        ...sagaRunner(searchWatchers),
        ...sagaRunner(queriesWatchers),
        ...sagaRunner(hotelsWatchers),
        ...sagaRunner(offersWatchers),
        ...sagaRunner(reviewsWathcers),
        ...sagaRunner(graphWatchers),
        ...sagaRunner(hotWatchers),
        ...sagaRunner(bookingWatchers),
        ...sagaRunner(weatherWatchers),
        ...sagaRunner(commonWatcher),
        ...sagaRunner(operatorsWatchers),
        ...sagaRunner(collectionsWatchers),
        ...sagaRunner(hotelPricesWatchers),
        bootstrapDataForModalSaga(),
        bootstrapDataForHotToursSaga(),
        initializeApp(),
        prefetchNextPageWatcher()
    ]);
}
