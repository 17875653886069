import { createActions } from 'redux-actions';

export const hotActions = createActions({
    GET_HOT_TOURS:         (blockId) => blockId,
    GET_HOT_TOURS_SUCCESS: () => void 0,
    GET_HOT_TOURS_FAIL:    (error) => error,

    ADD_HOT_TOURS: (blockId, tours) => ({ blockId, tours }),

    SET_UI: (path, value) => ({ value, path }),

    GET_HOT_COUNTRIES:        (countryID) => countryID,
    FAIL_FETCH_HOT_COUNTRIES: (error) => error,
    SET_HOT_COUNTRIES:        (countryID, data) => ({ countryID, data }),
    SET_HOT_COUNTRY:          (countryID, data) => ({ [countryID]: data }),

    SET_INSTRUCTIONS: (countryID, data) => ({ [countryID]: data }),
});
