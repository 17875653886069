import { handleActions } from 'redux-actions';
import { Map } from 'immutable';

import { getRandomNumber } from 'helpers/fn';

import { setLang } from './actions';

const GOOGLE_MAP_TOKENS = [
    'AIzaSyAD-7xPRSwcNOKXxGYfOtcvjJmWOHTgR5k',
    'AIzaSyA4jFUmkfrI9tqE1zeoAUKMZtVmSZJGYWc',
    'AIzaSyBYSF1rCJZiTkAYp0eLNUW26AcyYwthW1o',
    'AIzaSyBhr4_lavnApWB46FB2RxDHA22bpYXT4w8'
];

const initial = Map({
    otpusk: {
        token:       { 'access_token': '298e9-774cd-1118a-5ccb7-fc8c6' },
        googleToken: GOOGLE_MAP_TOKENS[getRandomNumber(0, GOOGLE_MAP_TOKENS.length - 1)],
        lang:        null,
    },
});

export default handleActions({
    [setLang]: (state, { payload: locale }) => state.setIn(
        ['otpusk', 'lang'],
        locale
    ),
}, initial);
