import React, { memo } from 'react';
import T from 'prop-types';
import * as R from 'ramda';

import styles from '../styles/rating';

const RATING_RANGE = R.range(1, 6);

const Rating = ({ value }) => {
    return (
        <div className = { styles.ratingCircles } data-value = { value }>
            { R.map(
                (key) => <div className = { styles.ratingCircle } key = { key } />,
                RATING_RANGE
            ) }
        </div>
    );
};

Rating.propTypes = {
    value: T.number.isRequired,
};

export default memo(Rating);
