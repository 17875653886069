import { createActions } from 'redux-actions';

export const hotelPricesActions = createActions({
    GET_HOTEL_PRICES_WITH_GRAPH:         (entityID, hotelID, offerID) => ({ entityID, hotelID, offerID }),
    GET_HOTEL_PRICES_WITH_GRAPH_SUCCESS: () => void 0,
    GET_HOTEL_PRICES_WITH_GRAPH_FAIL:    (error) => error,

    GET_HOTEL_PRICES_SUCCESS: () => void 0,
    GET_HOTEL_PRICES_FAIL:    (error) => error,

    GET_HOTEL_GRAPH_SUCCESS: () => void 0,
    GET_HOTEL_GRAPH_FAIL:    (error) => error,
});
