// Core
import { createSelector } from 'reselect';

const domain = (_) => _.weather;
const getKey = (_, key) => key;

export const getWeatherByKey = () => createSelector(
    domain,
    getKey,
    (map, key) => map.get(key)
);
