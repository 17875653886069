import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { QUERY_PARAMS, compileQuery, createQuery } from '@otpusk/apisearch-toolbox/dist/queries/fn';
import { getHotel } from '@otpusk/apisearch-toolbox/dist/hotels/selectors';
import { generatePath } from 'react-router-dom';
import moment from 'moment';
import { Map, Set, Range } from 'immutable';
import * as R from 'ramda';

import { Hotel } from 'components/results/Hotel';

import { getActiveCurrency } from 'bus/currency/selectors';

import book from 'routes/book';
import history from 'init/history';

export const HotTour = memo(({ hotel, offer, badgeList, ...props }) => {
    const { country, departure, resortCity } = useSelector(({ geo }) => {
        const countryId = Number(hotel.country.id);

        return {
            country:    geo.getIn(['countries'], []).find(({ id }) => Number(id) === Number(countryId)),
            departure:  geo.getIn(['departures', '0'], []).find(({ id }) => Number(id) === Number(offer.departure)),
            resortCity: geo.getIn(['cities', countryId], []).find(({ name }) => name === hotel.city.name),
        };
    });
    const activeCurrencyCode = useSelector(getActiveCurrency);

    const getHotelSelector = useMemo(
        () => R.partialRight(getHotel(), [hotel.id]),
        [hotel.id]
    );

    const hotelFull = useSelector(getHotelSelector);

    const combinedHotel = useMemo(
        () => R.mergeAll([hotelFull, hotel]),
        [hotelFull, hotel]
    );

    const updateOffer = useMemo(
        () => R.set(
            R.lensProp('adults'),
            offer.divider ? offer.adults / offer.divider : offer.adults,
            offer
        ),
        [offer]
    );

    const currency = activeCurrencyCode || offer.currency || 'uah';

    const linkToTour = useMemo(() => {
        if (!country || !hotel || !offer) {
            return null;
        }

        const defaultQuery = createQuery();
        const query = defaultQuery.merge({
            [QUERY_PARAMS.AUTOSTART]: false,
            [QUERY_PARAMS.COUNTRY]:   hotel.country.id,
            [QUERY_PARAMS.DEPARTURE]: String(offer.departure),
            [QUERY_PARAMS.DURATION]:  Map({
                from: offer.days,
                to:   offer.days,
            }),
            [QUERY_PARAMS.DATES]: Map({
                from: moment(offer.date),
                to:   moment(offer.date),
            }),
            [QUERY_PARAMS.CATEGORY]: defaultQuery.get(QUERY_PARAMS.CATEGORY).map(() => false).merge({ [hotel.stars]: true }),
            [QUERY_PARAMS.ADULTS]:   offer.adults,
            [QUERY_PARAMS.CHILDREN]: Range(0, offer.children)
                .map(() => offer.childrenAge.replace(/^.*\D(\d+)\D*$/, '$1'))
                .map(Number).toList(),
            [QUERY_PARAMS.CITIES]:    Set([hotel.city.id]),
            [QUERY_PARAMS.HOTELS]:    Set([hotel.id]),
            [QUERY_PARAMS.TRANSPORT]: defaultQuery.get(QUERY_PARAMS.TRANSPORT).map(() => false).merge({ [offer.transport]: true }),
            [QUERY_PARAMS.FOOD]:      defaultQuery.get(QUERY_PARAMS.FOOD).map(() => false).merge({ [offer.food]: true }),
            [QUERY_PARAMS.CURRENCY]:  currency,
        });

        return history.createHref({
            pathname: generatePath(book.hotTour, {
                countryName: country && country.code.toLowerCase(),
                hotelName:   hotel && hotel.code.replace(/ /g, '_').toLowerCase(),
                hotelID:     hotel && hotel.id,
                offerID:     offer && offer.id,
            }),
            hash: `#${compileQuery(query)}`,
        });
    }, [country, currency, hotel, offer]);
    const badges = useMemo(() => {
        if (badgeList && hotel) {
            return badgeList.filter((badge) => hotel.services && hotel.services.includes(badge.service));
        }

        return null;
    }, [badgeList, hotel]);

    return (
        <Hotel
            hot
            activeCurrencyCode = { currency }
            badges = { badges }
            country = { country }
            departure = { departure }
            hotel = { combinedHotel }
            linkToTour = { linkToTour }
            offer = { updateOffer }
            resortCity = { resortCity }
            { ...props }
        />
    );
});
