import * as R from 'ramda';

const createUploader = () => {
    let imgStore = {};

    const updateStore = (next) => imgStore = R.mergeAll([imgStore, next]);
    const isUploadImg =  (url) => R.has(url, imgStore);
    const getImgFromStore = (url) => R.prop(url, imgStore);

    return {
        upload: (url) => !isUploadImg(url)
            ? new Promise((resolve, reject) => {
                const img = global.document.createElement('img');

                img.onload = () => (updateStore({ [url]: img }), resolve(img));
                img.onerror = reject;
                img.src = url;
            })
            : Promise.resolve(getImgFromStore(url)),
    };
};

export default createUploader;
