import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import * as actions from './actions';

const initialState = {
    type: '',
};

export default handleActions({
    [actions.setDeviceType]: (state, { payload: type }) => R.set(R.lensProp('type'), type, state),
}, initialState);
