import { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { offersActions } from '@otpusk/apisearch-toolbox/dist/offers';
import { hotelsActions } from '@otpusk/apisearch-toolbox/dist/hotels';

export const OfferAndHotelProvider = memo(({ children, offerId, hotelId }) => {
    const offer = useSelector(({ offers }) => offers.getIn(['store', String(offerId)], null));
    const hotel = useSelector(({ hotels }) => hotels.getIn(['store', String(hotelId)], null));

    const dispatch = useDispatch();

    useEffect(() => {
        if (!offer) {
            dispatch(offersActions.getOffer(offerId));
        }
        if (!hotel) {
            dispatch(hotelsActions.getHotel(hotelId));
        }
    }, []);

    return children(offer, hotel);
});
