import React, { memo, useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import t from 'prop-types';
import { useSelector } from 'react-redux';

import { getLocale } from 'bus/auth/selectors';

const propTypes = {
    value:    t.bool.isRequired,
    onChange: t.func.isRequired,
};

const C = ({ onChange, value }) => {
    const refCaptcha = useRef(null);
    const currentLocale = useSelector(getLocale);

    useEffect(() => {
        const IS_CAPTCHA_READY = refCaptcha.current.props.grecaptcha;

        if (IS_CAPTCHA_READY && !value) {
            refCaptcha.current.props.grecaptcha.reset();
        }
    }, [value, refCaptcha]);

    return (
        <ReCAPTCHA
            hl = { currentLocale }
            ref = { refCaptcha }
            sitekey = '6LeKGPcUAAAAAMmdc951Xgv4RSjr6xq9hql7j_ot'
            onChange = { onChange }
        />
    );
};

C.propTypes = propTypes;
C.displayName = 'Captcha';

export const Captcha = memo(C);
