import React, { useState, useRef, memo, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';

export const useMediaQuery = ({ mediaQueryString = '', onChange = () => {} }) => {
    const { current: media } = useRef(matchMedia(mediaQueryString));
    const [visible, setVisible] = useState(media.matches);

    // methods
    const showContent = useCallback(() => setVisible(true), []);
    const hideContent = useCallback(() => setVisible(false), []);
    const toggleContent = useCallback(() => setVisible(!visible), [visible]);

    useEffect(() => {
        const handler = (event) => (
            setVisible(event.matches),
            onChange(event)
        );

        media.addListener(handler);

        return () => media.removeListener(handler);
    }, []);

    return { visible, showContent, hideContent, toggleContent };
};

export const MediaQuery = memo((props) => {
    const { children, mediaQueryString, onChange } = props;
    const instruments = useMediaQuery({ mediaQueryString, onChange });

    return children(instruments);
});

export const withMediaQuery = (mediaParams = {}) => (Component) => (props) => {
    return (
        <MediaQuery { ...mediaParams }>
            { (instruments) => <Component media = { instruments } { ...props } /> }
        </MediaQuery>
    );
};

MediaQuery.propTypes = {
    children:         propTypes.func.isRequired,
    mediaQueryString: propTypes.string.isRequired,
    onChange:         propTypes.func,
};
