import { put, take, race, all, select, call, takeEvery } from 'redux-saga/effects';
import { hotelsActions } from '@otpusk/apisearch-toolbox/dist/hotels';
import * as R from 'ramda';

import { uiActions } from 'bus/ui';
import { reviewsActions } from 'bus/reviews/actions';
import { getUICommonReviews, getUIReviews, getUITripadvisorReviews } from 'bus/reviews/selectors';

import {
    bootstrapDataForModal,
    bootstrapDataForCarousel,
    setCustomHotel
} from './actions';
import { getUICarousel, isSetCustomHotel } from './selectors';
import { UI_FLAG_CAROUSEL_MODAL, UI_FLAG_CAROUSEL } from './constants';

function* getHotelSaga (hotelID) {
    yield put(hotelsActions.getHotel(hotelID));

    const result = yield race([
        take(hotelsActions.getHotelSuccess),
        take(hotelsActions.getHotelFail)
    ]);

    return result;
}

function* getReviewsSaga (hotelID) {
    const { completed: areReviewsReceived } = yield select((state) => getUIReviews(state, { hotelID }));

    if (areReviewsReceived) {
        return [true, false];
    }

    yield put(reviewsActions.getReviews(hotelID));

    const result = yield race([
        take(reviewsActions.getReviewsSuccess),
        take(reviewsActions.getReviewsFail)
    ]);

    return result;
}

function* getTripadvisorReviewsSaga (hotelID) {
    const { completed: areReviewsReceived } = yield select((state) => getUITripadvisorReviews(state, { hotelID }));

    if (areReviewsReceived) {
        return [true, false];
    }

    yield put(reviewsActions.getTripadvisorReviews(hotelID, '1'));

    const result = yield race([
        take(reviewsActions.setTripadvisorReviews),
        take(reviewsActions.getTripadvisorReviewsFail)
    ]);

    return result;
}

function* getCommonReviewsSaga () {
    const { completed: areCommonReviewsReceived } = yield select(getUICommonReviews);

    if (areCommonReviewsReceived) {
        return [true, false];
    }
    yield put(reviewsActions.getCommonReviews());

    const result = yield race([
        take(reviewsActions.getCommonReviewsSuccess),
        take(reviewsActions.getCommonReviewsFail)
    ]);

    return result;
}

function* getCustomHotelSaga (hotelID) {
    try {
        const response = yield call(fetch, `/wp-json/rest_api/v1/hotel/${hotelID}`);

        const hotel = yield call([response, 'json']);

        if (response.status !== 200) {
            throw new Error(hotel.message);
        }

        yield put(setCustomHotel(hotelID, hotel));
    } catch (error) {
        console.log(error);
    }

    const isSetHotel = yield select((state) => isSetCustomHotel(state, { hotelID }));

    return [isSetHotel, !isSetHotel];
}

function* bootstrapDataForModalSaga ({ payload: hotelID }) {
    yield put(uiActions.setUiState([UI_FLAG_CAROUSEL_MODAL, hotelID], {
        loading:   true,
        completed: false,
    }));

    const [[, failedGetTripadvisorSaga]] = yield all([
        call(getTripadvisorReviewsSaga, hotelID),
        call(getCommonReviewsSaga)
    ]);

    if (failedGetTripadvisorSaga) {
        yield call(getReviewsSaga, hotelID);
    }

    yield put(uiActions.setUiState([UI_FLAG_CAROUSEL_MODAL, hotelID], {
        loading:   false,
        completed: true,
    }));
}

function* bootstrapDataForCarouselSaga ({ payload: { hotelID, onSuccess }}) {
    const uiCarousel = yield select((state) => getUICarousel(state, { hotelID }));

    if (!R.isEmpty(uiCarousel)) {
        return;
    }

    yield put(uiActions.setUiState([UI_FLAG_CAROUSEL, hotelID], {
        loading:   true,
        completed: false,
    }));

    yield all([
        call(getHotelSaga, hotelID),
        call(getCustomHotelSaga, hotelID)
    ]);

    yield put(uiActions.setUiState([UI_FLAG_CAROUSEL, hotelID], {
        loading:   false,
        completed: true,
    }));

    onSuccess && onSuccess();
}

export default function* () {
    yield takeEvery(bootstrapDataForCarousel, bootstrapDataForCarouselSaga);
    yield takeEvery(bootstrapDataForModal, bootstrapDataForModalSaga);
}
